import { isDev } from '../../helpers/isDev';

class Image {
    private isDev: boolean;

    constructor() {
        this.isDev = isDev();
    }

    // need to release it when no longer used to free the memory (not released automatically unless page is reloaded)
    public getPreviewUrl = (file: File) => URL.createObjectURL(file);
    public releasePreviewUrl = (url: string) => URL.revokeObjectURL(url);
}

export const image = new Image();