import * as React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Publication, SubscriberPlan, User } from '../models';
import SubscriptionElementContent from './SubscriptionElementContent';

interface SubscriptionElementProps {
    authed: boolean;
    publication: Publication;
    user: User;
    onSuccess?: (token: string) => Promise<void>;
    onPlanSuccess?: (subscriberPlan: SubscriberPlan) => void;
}

export const SubscriptionElement = (props: SubscriptionElementProps) =>
    <ElementsConsumer>
        {({ elements, stripe }) => (
            <SubscriptionElementContent elements={elements} stripe={stripe} {...props} />
        )}
    </ElementsConsumer>;