import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Container, TextField, Button, Typography, Box, InputAdornment, IconButton, Grid, ButtonBase, FormControlLabel, Switch, Chip } from '@material-ui/core';
import { toJS, when } from 'mobx';
import { Post, PostStatus } from '../../models/Post';
import { auth } from '../../services/auth/Auth';
import { converter } from '../../helpers/converter';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { RouterStore } from '../../stores/RouterStore';
import { RootStore } from '../../stores/RootStore';
import AutoSave from '../../shared/AutoSave';
import { EditorStore } from '../../stores/EditorStore';
import SaveIcon from '@material-ui/icons/Save';
import Editor from './Editor'
import { UserStore } from '../../stores/UserStore';
import { PageLoader } from '../../shared/PageLoader';
import { constants } from '../../helpers/constants';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    textField: {
        margin: theme.spacing(1, 0, 0.5)
    },
    textField2: {
        margin: theme.spacing(0.5, 0)
    },
    textField3: {
        marginBottom: theme.spacing(0.5)
    },
    inputAdornment: {
        marginRight: 0
    },
    draft: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.warning.main
    },
    uploadImage: {
        objectFit: 'cover',
        width: '100%',
        height: 160
    }
});

interface NewPostProps extends WithStyles<typeof styles> {
    router: RouterStore;
    editorStore: EditorStore;
    userStore: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    editorStore: stores.editorStore,
    userStore: stores.userStore
}))
@observer
class NewPost extends React.Component<NewPostProps> {
    constructor(props: NewPostProps) {
        super(props);

        //once authenticated user resolves, check if we need to redirect
        when(() => props.userStore.meResolved, this.onMeResolved);
    }

    public render() {
        const { classes, editorStore, userStore } = this.props;

        if (!userStore.meResolved)
            return <PageLoader />;

        const publication = userStore.me.publications[0];
        if (!publication)
            return null;

        return <Container className={classes.root}>
            <Typography variant="h5" paragraph>{publication.name} publication</Typography>
            <Box>
                <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                        <ButtonBase component="span" disabled>
                            <img src={constants.defaultPostImageUrl} alt="post banner" className={classes.uploadImage} />
                        </ButtonBase>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <TextField name="title" fullWidth value={editorStore.post.title || ''} className={classes.textField3} onChange={this.handleChange}
                            InputProps={{ startAdornment: <InputAdornment disablePointerEvents position="start">Title:</InputAdornment> }}
                        />
                        <TextField name="subtitle" fullWidth value={editorStore.post.subtitle || ''} className={classes.textField} onChange={this.handleChange}
                            InputProps={{ startAdornment: <InputAdornment disablePointerEvents position="start">Subtitle:</InputAdornment> }}
                            helperText="optional"
                        />
                        <TextField name="slug" fullWidth value={editorStore.post.slug || ''} className={classes.textField2} onChange={this.handleChange}
                            InputProps={{
                                startAdornment: <InputAdornment disablePointerEvents className={classes.inputAdornment} position="start">{`https://storylect.com/${publication.slug}/`}</InputAdornment>
                            }}
                            helperText="You won't be able to change post URL once it's published"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete multiple freeSolo value={editorStore.post.tags || []} onChange={this.handleTagsChange}
                            options={constants.tags}
                            renderInput={params => <TextField {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: <>
                                        <InputAdornment disablePointerEvents position="start">Tags:</InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                }}
                                placeholder="Type relevant tag and press Enter to add" helperText="Tags help with post discoverability" />
                            }
                        />
                        {publication.plans.length > 0 &&
                            <Box display="flex" alignItems="center" mt={1}>
                                <Typography color="textSecondary">Public?</Typography>
                                <FormControlLabel className={classes.textField2}
                                    control={<Switch disabled />}
                                    label={<Typography variant="body2">visible to paying subscribers only</Typography>}
                                />
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" py={1.5}>
                <Box display="flex" alignItems="center">
                    <Chip label={PostStatus[PostStatus.Draft]} size="small" className={classes.draft} />
                    <Box fontStyle="italic" ml={1}>
                        <Typography variant="caption">
                            <AutoSave onSave={this.savePost} object={toJS(editorStore.post)} />
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <Button variant="outlined" size="small" style={{ marginRight: 8 }} disabled>Save</Button>
                    <Button variant="contained" size="small" color="primary" disabled>Publish</Button>
                </Box>
            </Box>
            <Editor onMarkdownChange={this.onMarkdownChange}/>
        </Container>;
    }

    private onMeResolved = () => {
		const { userStore, router } = this.props;

		// redirect to new publication page if user doesn't have one yet
		if (userStore.me.publications.length === 0) {
			router.replace('/new-publication');
		}
	}

    private handleTagsChange = (e: React.ChangeEvent<{}>, values: string[], reason: AutocompleteChangeReason) => {
        this.props.editorStore.post.tags = values;
    }

    private onMarkdownChange = (output: object) => {
        this.props.editorStore.post.body = output;
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        let value = e.target.value;

        if (name === 'slug') {
            value = value.toLowerCase();
        }

        (this.props.editorStore.post as any)[name] = value;

        if (name === 'title') {
            this.props.editorStore.post.slug = converter.toSlug(value);
        }
    }

    private savePost = async () => {
        // need to make sure that slug always has either letters, numbers or dashes in DB at all times
        this.props.editorStore.post.slug = converter.toSlug(this.props.editorStore.post.slug);

        try {
            const response = await auth.fetch('/api/posts', {
                method: 'POST',
                body: JSON.stringify(Object.assign({}, this.props.editorStore.post, { publicationId: this.props.userStore.me.publications[0].id }))
            });

            if (response.status === 200) {
                const newPost = await response.json() as Post;

                // make sure to save any possible changes user made to the post while it was persisting to DB
                const updatedPost = Object.assign(newPost, this.props.editorStore.post);

                this.props.editorStore.post = updatedPost;
                this.props.userStore.addPost(updatedPost);

                this.props.router.replace(`/post/${updatedPost.id}`);
            }

            return Promise.resolve(response);
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            //eturn Promise.reject(new Response());
            throw error;
        }
    }
}

export default withStyles(styles)(NewPost);
