import { observable, action } from 'mobx';
import { Post } from '../models';

export class EditorStore {
    @observable public post: Post;

    // [DN] TODO: need to store the element that the user currently has a focus on, so when NewPost goes to EditPost we want to keep the cursor where it was before to make it as smooth as possible for the user

    constructor() {
        this.post = {} as Post;
    }

    @action
    public clearPost = () => this.post = {} as Post;
}