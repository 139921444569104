import { isDev } from "./isDev";
import { Currency } from '../models';

export const constants = {
    imagePath: isDev() ? 'https://storylect-dev.s3-us-west-1.amazonaws.com/static' : 'https://storylect.s3-us-west-1.amazonaws.com/static',
    defaultPublicationAvatarUrl: isDev() ? 'https://storylect-dev.s3-us-west-1.amazonaws.com/static/publication-default.svg' : 'https://storylect.s3-us-west-1.amazonaws.com/static/publication-default.svg',
    defaultPostImageUrl: isDev() ? 'https://storylect-dev.s3-us-west-1.amazonaws.com/static/post-default.svg' : 'https://storylect.s3-us-west-1.amazonaws.com/static/post-default.svg',
    categories: ['Art', 'Comics', 'Fiction', 'Film', 'Gaming', 'Humor', 'Music', 'Nonfiction', 'Photography', 'Poetry', 'Design', 'Culture', 'Food', 'Language', 'Outdoors', 'Animals', 'Pets', 'Sports', 'Travel', 'Fashion', 'Crime', 'Equality', 'Health', 'Business', 'Economy', 'Marketing', 'Startups', 'Politics', 'Programming', 'Science', 'Lifestyle', 'Society', 'Education', 'History', 'Religion', 'World', 'Technology', 'Social Media', 'News', 'Journalism', 'Reviews', 'Biography', 'Books', 'Investing', 'Interviews'].sort(),
    tags: [] as string[],
    // more here - https://gist.github.com/Fluidbyte/2973986
    // also getting currency symbols - https://github.com/stripe/stripe-connect-rocketrides/blob/master/server/routes/pilots/pilots.js#L22
    currencies: [
        { name: 'US Dollar', code: 'USD', symbol: '$' },
        { name: 'Canadian Dollar', code: 'CAD', symbol: 'CA$' },
        { name: 'Euro', code: 'EUR', symbol: '€' },
        { name: 'British Pound', code: 'GBP', symbol: '£' }
    ] as Currency[]
};
