import * as React from 'react';
import { RouteProps, Route, Redirect } from 'react-router';
// import { user } from '../auth';
import { observer } from 'mobx-react';
import { auth } from '../services/auth/Auth';
import { Helmet } from 'react-helmet';

interface ProtectedRouteProps extends RouteProps {
    isInternal?: boolean;
}

@observer
export class ProtectedRoute extends React.Component<ProtectedRouteProps> {
    public render() {
        const { isInternal, ...rest } = this.props;

        // [TODO] this is a hack for now, should introduce proper roles/presmissions instead
        const isAdmin = auth.isLoggedIn && auth.user.email.endsWith('@storylect.com');

        return ((isInternal && isAdmin) || (!isInternal && auth.isLoggedIn)) ? 
            <>
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <Route {...rest} />
            </> :
            <Redirect to={{
                pathname: '/login',
                state: { from: this.props.location },
            }} />;
    }
}
