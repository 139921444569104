import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Box, Button, CircularProgress, Paper, TextField, Typography } from '@material-ui/core';
import { auth } from '../../services/auth/Auth';
import { validation } from '../../helpers/validation';
import { Alert } from '@material-ui/lab';

const styles = (theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2, 3, 3)
    },
    submitting: {
        marginLeft: theme.spacing(1)
    },
    alert: {
        padding: theme.spacing(0, 2)
    }
});

interface PasswordSettingsProps extends WithStyles<typeof styles> {
    isSet?: boolean;
    onSuccess?: () => void;
}

@observer
class PasswordSettings extends React.Component<PasswordSettingsProps> {
    @observable private password: string = '';
    @observable private error: string = '';
    @observable private submitting: boolean = false;

    public render() {
        const { classes, isSet } = this.props;

        return <Paper className={classes.root}>
            <Typography variant="h6" gutterBottom>My Password</Typography>
            <Typography variant="body2">This is optional, you can always log in with just your email address.</Typography>
            <TextField name="password" margin="normal" variant="outlined" label="Password" fullWidth value={this.password} onChange={this.handleChange} type="password" autoComplete="current-password"
                error={!!this.error} helperText={this.error}
            />
            <Box mt={2} display="flex" alignItems="center">
                <Box mr={2}>
                    <Button variant="contained" color="primary" onClick={this.save} disabled={this.submitting}>
                        Sav{this.submitting ? 'ing' : 'e'}
                        {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                    </Button>
                </Box>
                {!isSet && <Alert severity="info" className={classes.alert}>You haven't set your password yet</Alert>}
            </Box>
        </Paper>;
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.password = e.target.value;

        if (this.password.trim()) {
            this.error = '';
        }
    }

    private save = async () => {
        this.error = '';
        if (!validation.isValidPassword(this.password)) {
            this.error = 'Password requires at least 1 letter, 1 number and minimum 6 characters';
            return;
        }

        try {
            this.submitting = true;

            var response = await auth.fetch('/api/me/password', { method: 'PUT', body: JSON.stringify({ password: this.password }) });

            if (response.status === 200) {
                this.password = '';

                if (this.props.onSuccess)
                    this.props.onSuccess();
            }
            else {
                this.error = await response.text();
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.error = 'Error updating password. Try again later';
        }
        finally {
            this.submitting = false;
        }
    }
}

export default withStyles(styles)(PasswordSettings);