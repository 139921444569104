import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { observer, inject } from 'mobx-react';
import * as React from 'react';
import { observable } from 'mobx';
import { CircularProgress, Container, TextField } from '@material-ui/core';
import { RootStore } from '../../stores/RootStore';
import { RouterStore } from '../../stores/RouterStore';
import { auth } from '../../services/auth/Auth';
import { UserStore } from '../../stores/UserStore';
import { validation } from '../../helpers/validation';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    paper: {
        marginTop: '7vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 3, 3)
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.error.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        //marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    submitting: {
        marginLeft: theme.spacing(1)
    }
});

interface LogInProps extends WithStyles<typeof styles> {
    router: RouterStore;
    userStore: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class LogIn extends React.Component<LogInProps> {
    @observable private email: string = '';
    @observable private password: string = '';
    @observable private errors: Map<string, string> = new Map(); // key is field name, value is error message
    @observable private emailSent: boolean = false;
    @observable private loginWithEmail: boolean = true;
    @observable private submitting: boolean = false;

    constructor(props: LogInProps) {
        super(props);

        // [DN] TODO? I think this should be moved to componentDidMount
        const params = new URLSearchParams(this.props.router.location.search);
        const token = params.get('token');
        if (!!token) {
            const redirect = params.get('redirect') || '/';

            auth.verifyLogin(token).then(response => {
                if (response.status === 200) {
                    // authenticating (no need to save token as it's already saved with auth.verifyLogin)
                    props.userStore.authenticate();
                    props.router.replace(redirect);
                }
                else {
                    response.text().then(text => {
                        this.errors.set('email', text);
                    });
                }
            });
        }
    }

    public render() {
        const { classes } = this.props;

        return <Container className={classes.root} maxWidth={this.emailSent ? 'md' : 'sm'}>
            <Paper className={classes.paper}>
                {this.loginWithEmail ? this.renderLoginWithEmail() : this.renderLoginWithPassword()}
                {this.errors.has('api') && <Typography color="error" variant="body2">{this.errors.get('api')}</Typography>}
            </Paper>
        </Container>;
    }

    private renderLoginWithEmail = () => {
        const { classes } = this.props;

        return this.emailSent ?
            <>
                <Typography variant="h5" paragraph>Check your email</Typography>
                <Typography align="center">We've sent a login link to <b>{this.email}</b></Typography>
            </> :
            <>
                <Avatar className={classes.avatar}><LockOutlinedIcon /></Avatar>
                <Typography variant="h6">Log in</Typography>
                <form className={classes.form} onSubmit={this.login}>
                    <TextField name="email" margin="normal" variant="outlined" label="Email" fullWidth value={this.email} onChange={this.handleChange}
                        error={this.errors.has('email')} helperText={this.errors.get('email')}
                    />
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={this.submitting}>
                        Email{this.submitting ? 'ing' : ''} Login Link
                        {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                    </Button>
                    <Typography>or <Button size="small" color="primary" onClick={this.toggleLoginForm}>log in with password</Button></Typography>
                </form>
            </>;
    }

    private renderLoginWithPassword = () => {
        const { classes } = this.props;

        return <>
                <Avatar className={classes.avatar}><LockOutlinedIcon /></Avatar>
                <Typography variant="h6">Log in</Typography>
                <form className={classes.form} onSubmit={this.loginWithPassword}>
                    <TextField name="email" margin="normal" variant="outlined" label="Email" fullWidth value={this.email} onChange={this.handleChange} 
                        error={this.errors.has('email')} helperText={this.errors.get('email')}
                    />
                    <TextField name="password" margin="normal" variant="outlined" label="Password" fullWidth value={this.password} onChange={this.handleChange} type="password" autoComplete="current-password" 
                        error={this.errors.has('password')} helperText={this.errors.get('password')}
                    />
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={this.submitting}>
                        Log{this.submitting ? 'ging' : ''} In
                        {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                    </Button>
                    <Typography>or <Button size="small" color="primary" onClick={this.toggleLoginForm}>log in with email</Button></Typography>
                </form>
            </>;
    }

    private toggleLoginForm = () => {
        this.errors.clear();
        this.loginWithEmail = !this.loginWithEmail;
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        // as user starts typing, we probably want to remove validation error
        this.errors.delete(name);

        (this as any)[name] = value;
    }

    private login = async (e: React.FormEvent<HTMLFormElement>) => {
        const { router } = this.props;

        e.preventDefault();

        this.errors.clear();
        this.email = this.email.trim();
        if (!validation.isValidEmail(this.email)) {
            this.errors.set('email', 'Email is invalid');
            return;
        }

        this.submitting = true;
        
        try {
            const response = await auth.login(this.email, (router.location.state as any)?.from.pathname);

            if (response.status === 200) {
                this.emailSent = true;
            }
            else {
                const apiError = await response.text();
                this.errors.set('email', apiError);
            }
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.errors.set('email', 'Error logging in. Try again later');
        }
        finally {
            this.submitting = false;
        }
    }

    private loginWithPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        const { router } = this.props;

        e.preventDefault();

        this.errors.clear();
        this.email = this.email.trim();
        if (!validation.isValidEmail(this.email)) {
            this.errors.set('email', 'Email is invalid');
            return;
        }
        else if (!this.password) {
            this.errors.set('password', 'Password is required');
            return;
        }

        this.submitting = true;
        
        try {
            const response = await auth.loginWithPassword(this.email, this.password);

            if (response.status === 200) {
                const { token } = await response.json();
                this.props.userStore.authenticate(token);
                this.props.router.replace((router.location.state as any)?.from.pathname || '/');
            }
            else {
                const apiError = await response.text();
                // TODO: this is a hack, let's think of a better way to do it, maybe have API return JSON in case of an error
                if (apiError.toLowerCase().includes('password')) {
                    this.errors.set('password', apiError);
                }
                else if (apiError.toLowerCase().includes('email') || apiError.toLowerCase().includes('user')) {
                    this.errors.set('email', apiError);
                }
                else {
                    this.errors.set('api', apiError);
                }
            }
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.errors.set('api', 'Error logging in. Try again later');
        }
        finally {
            this.submitting = false;
        }
    }
}

export default withStyles(styles)(LogIn);
