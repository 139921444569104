import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, Button, CircularProgress, BaseTextFieldProps } from '@material-ui/core';
import { auth } from '../../services/auth/Auth';
import { Comment } from '../../models';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

const styles = (theme: Theme) => createStyles({
    input: {
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    inputAdornment: {
        height: 'auto',
        marginTop: theme.spacing(1.5)
    },
    cancelButton: {
        marginRight: theme.spacing(1)
    },
    submitting: {
        marginLeft: theme.spacing(1)
    }
});

interface CommentBoxProps extends WithStyles<typeof styles>, Omit<BaseTextFieldProps, 'classes'> {
    postId: number;

    editing?: boolean;
    comment?: Comment;

    onSuccess: (comment: Comment) => void;
    onCancel?: () => void;
}

@observer
class CommentBox extends React.Component<CommentBoxProps> {
    @observable private text: string = '';
    @observable private buttonDisabled: boolean = true;
    @observable private submitting: boolean = false;

    constructor(props: CommentBoxProps) {
		super(props);

        if (props.editing && props.comment) {
            this.text = props.comment.body;
        }
	}

    public render() {
        const { classes } = this.props;

        return <TextField fullWidth multiline
            size="small"
            margin="dense"
            placeholder={this.props.placeholder || "Any comments?"}
            variant="outlined"
            value={this.text}
            onChange={this.handleChange}
            disabled={this.submitting}
            InputProps={{
                className: classes.input,
                endAdornment: <InputAdornment position="end" className={classes.inputAdornment}>
                    {!this.submitting && <Button size="small" className={classes.cancelButton} onClick={this.cancel}>Cancel</Button>}
                    <Button variant="contained" color="primary" size="small" onClick={this.post} disabled={this.buttonDisabled || this.submitting}>
                        Post{this.submitting ? 'ing' : ''}
                        {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                    </Button>
                </InputAdornment>
            }}
        />;
    }

    private post = async () => {
        if (this.props.editing) {
            await this.update();
        }
        else {
            await this.create();
        }
    }

    private create = async () => {
        this.submitting = true;

        try {
            const response = await auth.fetch('/api/comments', {
                method: 'POST',
                body: JSON.stringify({
                    postId: this.props.postId,
                    parentId: this.props.comment?.id,
                    body: this.text
                })
            });

            if (response.status === 200) {
                const comment = await response.json() as Comment;

                this.clear();

                this.props.onSuccess(comment);
            }
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }
        finally {
            this.submitting = false;
        }
    }

    private update = async () => {
        this.submitting = true;

        try {
            const response = await auth.fetch(`/api/comments/${this.props.comment?.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    body: this.text
                })
            });

            if (response.status === 200) {
                const comment = await response.json() as Comment;

                this.clear();

                this.props.onSuccess(comment);
            }
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }
        finally {
            this.submitting = false;
        }
    }

    private cancel = () => {
        this.clear();

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    private clear = () => {
        this.text = '';
        this.buttonDisabled = true;
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.text = e.target.value;
        this.buttonDisabled = !this.text;
    }
}

export default withStyles(styles)(CommentBox);
