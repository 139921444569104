import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Paper, Typography } from '@material-ui/core';
import { auth } from '../../services/auth/Auth';
import { NotificationType } from '../../models/NotificationType';

const styles = (theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2, 3, 3)
    },
    submitting: {
        marginLeft: theme.spacing(1)
    },
    error: {
        marginLeft: theme.spacing(2)
    }
});

interface NotificationItem {
    type: NotificationType;
    description: string;
    note?: string;
    writerOnly?: boolean;
}

interface NotificationSettingsProps extends WithStyles<typeof styles> {
    userId: number;
    unsubscribes: NotificationType[];
    isWriter: boolean;
    onSave?: (unsubscribes: NotificationType[]) => void;
    token?: string; // if token is passed in, use anonymous api endpoint, otherwise authed one when updating settings
}

@observer
class NotificationSettings extends React.Component<NotificationSettingsProps> {
    private settings: NotificationItem[] = [
        { type: NotificationType.Announcement, description: 'There is an announcement or news from Storylect', note: 'We hate spam, so we\'ll limit it to important updates only.' },
        { type: NotificationType.Comment, description: 'Someone comments on my post', writerOnly: true },
        { type: NotificationType.Reply, description: 'Someone replies to my comment' },
    ];

    @observable private error: string = '';
    @observable private submitting: boolean = false;
    @observable private unsubscribes: NotificationType[] = [];

    constructor(props: NotificationSettingsProps) {
		super(props);

        this.unsubscribes = this.props.unsubscribes;
	}

    public render() {
        const { classes } = this.props;

        return <Paper className={classes.root}>
            <Typography variant="h6" paragraph>Email me when</Typography>
            {this.settings.filter(s => this.props.isWriter || !s.writerOnly).map(s =>
                <Box key={s.type} my={2}>
                    <FormControlLabel 
                        control={<Checkbox color="primary" checked={!this.unsubscribes.includes(s.type)} onChange={this.handleChange(s.type)} />} 
                        label={s.description} 
                    />
                    <Typography variant="caption" display="block" color="textSecondary">{s.note}</Typography>
                </Box>
            )}
            <Typography variant="body2" color="textSecondary">Note that you will still receive occasional legal, administrative and transactional emails.</Typography>
            <Box mt={3}>
                <Button variant="contained" color="primary" onClick={this.save} disabled={this.submitting}>
                    Sav{this.submitting ? 'ing' : 'e'}
                    {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                </Button>
                <Typography color="error" variant="body2" component="span" className={classes.error}>{this.error}</Typography>
            </Box>
        </Paper>;
    }

    private handleChange = (type: NotificationType) => (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            this.unsubscribes = this.unsubscribes.filter(u => u !== type);
        }
        else {
            this.unsubscribes.push(type);
        }
    }

    private save = async () => {
        this.error = '';
        try {
            this.submitting = true;

            const url = this.props.token ?
                `/api/users/${this.props.userId}/notification-settings?token=${this.props.token}` :
                '/api/me/notification-settings';

            const response = await auth.fetch(url, { 
                method: 'PUT', 
                body: JSON.stringify({ unsubscribes: this.unsubscribes }) 
            });

            if (response.status === 200) {

                if (this.props.onSave)
                    this.props.onSave(this.unsubscribes);
            }
            else {
                this.error = await response.text();
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.error = 'Error updating email settings. Try again later';
        }
        finally {
            this.submitting = false;
        }
    }
}

export default withStyles(styles)(NotificationSettings);