import * as React from 'react';
import { Container, Box, Typography, Chip, Paper, Link } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
import { RouterStore } from '../../stores/RouterStore';
import { Publication } from '../../models';
import { PostStatus } from '../../models/Post';
import { observable } from 'mobx';
import { PageLoader } from '../../shared/PageLoader';
//import { UserStore } from '../../stores/UserStore';
import { auth } from '../../services/auth/Auth';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { PaidContent } from '../../shared/icons/PaidContent';

const styles = (theme: Theme) => createStyles({
    root: {
		paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
	},
	paper: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0)
    },
    categoryChip: {
        marginRight: theme.spacing(1)
    },
    live: {
       color: theme.palette.success.main,
       fontWeight: 600
    },
    draft: {
        color: theme.palette.warning.main,
        fontWeight: 600
    },
    bold: {
        color: theme.palette.text.primary,
        fontWeight: 600
    },
    blue: {
        color: theme.palette.primary.main,
        fontWeight: 600
    }
});

interface DashboardProps extends WithStyles<typeof styles> {
	router: RouterStore;
	//userStore: UserStore;
}

@inject((stores: RootStore) => ({
	router: stores.routerStore,
	//userStore: stores.userStore
}))
@observer
class Dashboard extends React.Component<DashboardProps> {
    @observable private loading: boolean = true;
    @observable private publications: Publication[] = [];

	constructor(props: DashboardProps) {
		super(props);

		//once authenticated user resolves, check if we need to redirect
		//when(() => props.userStore.meResolved, this.onMeResolved);
	}

	public render() {
		const { classes } = this.props;

		if (this.loading)
			return <PageLoader />;

		return <Container className={classes.root} maxWidth="xl">
            <Typography variant="subtitle1" paragraph>All Publications</Typography>
            {_.orderBy(this.publications, [p => p.plans.length > 0, p => p.id], ['desc','desc']).map(p => <Paper className={classes.paper}>
                <Box flexGrow="3" flexBasis="0" px={1}>
                    <Box display="flex" alignItems="center" mb={0.5}>
                        {p.plans.length ? <PaidContent viewBox="0 0 70 70" color="error" style={{ marginRight: 4 }} /> : null}
                        <Link variant="body1" display="block" component={RouterLink} to={`/${p.slug}`}>{p.name}</Link>
                        <Typography variant="caption" color="textSecondary" style={{ marginLeft: 4 }}>({p.id})</Typography>
                    </Box>
                    <Typography variant="caption" component="div" gutterBottom>
                        <Typography variant="inherit" color="textSecondary">by</Typography> {p.authors.map(a => `${a.name} (${a.id})`).join(", ")}
                    </Typography>
                    {p.categories.map(c => <Chip key={c} label={c} size="small" variant="outlined" className={classes.categoryChip} />)}
                </Box>
                <Box flexGrow="1" flexBasis="0" px={1}>
                    <Typography variant="body2">Posts</Typography>
                    <Typography variant="body2" color="textSecondary">
                        Live: <span className={classes.live}>{
                            (p?.metrics?.posts[PostStatus.Published]?.private || 0) + 
                            (p?.metrics?.posts[PostStatus.Published]?.public || 0) +
                            (p?.metrics?.posts[PostStatus.Republished]?.private || 0) + 
                            (p?.metrics?.posts[PostStatus.Republished]?.public || 0)
                        }</span>
                        {((p?.metrics?.posts[PostStatus.Published]?.private || 0) + (p?.metrics?.posts[PostStatus.Republished]?.private || 0)) > 0 && <> (<span className={classes.bold}>{(p?.metrics?.posts[PostStatus.Published]?.private || 0) + (p?.metrics?.posts[PostStatus.Republished]?.private || 0)}</span> paid)</>}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Draft: <span className={classes.draft}>{(p?.metrics?.posts[PostStatus.Draft]?.private || 0) + (p?.metrics?.posts[PostStatus.Draft]?.public || 0) + (p?.metrics?.posts[PostStatus.Unpublished]?.private || 0) + (p?.metrics?.posts[PostStatus.Unpublished]?.public || 0)}</span>
                    </Typography>
                </Box>
                <Box flexGrow="1" flexBasis="0" px={1}>
                    <Typography variant="body2">Subscribers</Typography>
                    <Typography variant="body2" color="textSecondary">Total: <span className={classes.bold}>{p?.metrics?.subscribers?.total || 0}</span></Typography>
                    <Typography variant="body2" color="textSecondary">Paying: <span className={classes.blue}>{p?.metrics?.subscribers?.paying || 0}</span></Typography>
                </Box>
            </Paper>)}
		</Container>;
	}

	public componentDidMount() {
		this.loadPublications();
	}

    // private onMeResolved = () => {
	// 	const { userStore, router } = this.props;

	// }

    private loadPublications = async () => {
        try {
            let response = await auth.fetch('/api/admin/publications');
            if (response.status === 200) {
                this.publications = await response.json() as Publication[];
            }
        }
        catch (error) {
            // handle error!
            console.log(error);
        }
        finally {
            this.loading = false;
        }
    }
}

export default withStyles(styles)(Dashboard);
