import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import App from './App';
import { RootStore } from './stores/RootStore';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { GA } from './helpers/ga';
import { logger } from './services/logging/Logger';

// prepare MobX stores
const history = createBrowserHistory();
const rootStore = new RootStore(history);

// initialize google analytics and logger
GA.init('UA-166785964-1', rootStore.routerStore.history);
logger.init();

ReactDOM.render(
    <Provider {...rootStore}>
        <Router history={rootStore.routerStore.history}>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);

// [DN] let's unregister service worker that caches the shit out of everything
// I think CRA 4 doesn't include it by default, may need to upgrade the project to the latest CRA and React
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
        .then((registration) => {
            registration.unregister();
        })
        .catch((error) => {
            console.error(error.message);
        });
}