import * as React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box, Card, CardContent, CardActions, Theme, CardHeader, CardActionArea, IconButton, CardMedia, Tooltip } from '@material-ui/core';
import { Post, PostStatus } from '../../models/Post';
import { RootStore } from '../../stores/RootStore';
import { RouterStore } from '../../stores/RouterStore';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import Like from '../../shared/Like';
import { MetricType } from '../../models/MetricType';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import { Metrics, Publication } from '../../models';
import EditIcon from '@material-ui/icons/Edit';
import { PaidContent } from '../../shared/icons/PaidContent';
import VisibilityIcon from '@material-ui/icons/Visibility';

const styles = (theme: Theme) => createStyles({
    cardInnerXS: {
        padding: '8px',
    },
    cardTitle: {
        textAlign: 'center',
        fontSize: '30px',
        marginBottom: '16px',
    },
    rowContent: {
        padding: '15px 5px',
        borderBottom: '1px solid #ccc',
        '&:hover': {
            backgroundColor: '#c1f3fc',
            cursor: 'pointer',
        },
    },
    iconContainer: {
        '& svg': {
            color: '#c4c4c4',
            margin: '0 5px',
            '&:hover': {
                stroke: '#ccc',
            }
        }
    },
    reactionCount: {
        marginLeft: theme.spacing(0.5)
    },
    reaction: {
        marginLeft: theme.spacing(2)
    },
    cardHeader: {
        padding: 0,
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },
    cardHeaderContent: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    status: {
        borderBottomLeftRadius: theme.shape.borderRadius
    },
    cardFooter: {
        padding: theme.spacing(0, 0, 0, 2),
        justifyContent: 'space-between'
    },
    media: {
        height: 200
    }
});

interface DashboardTableItemProps extends WithStyles<typeof styles> {
    publication: Publication;
    post: Post;
    metrics: Metrics;
    onDelete: (element: Post) => () => void;
    router?: RouterStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore
}))
@observer
class DashboardTableItem extends React.Component<DashboardTableItemProps> {
    public render() {
        const { classes, post, metrics, publication } = this.props;

        const isLivePost = post.status === PostStatus.Published || post.status === PostStatus.Republished;

        return <Grid item xs={12} md={6}>
            <Card>
                <CardHeader className={classes.cardHeader} disableTypography
                    title={
                        <>
                            <Typography variant="caption" color="textSecondary">
                                {moment(post.updatedOn).format('lll')}
                            </Typography>
                            <Box display="flex" alignItems="center">
                                {publication.plans.length > 0 && (post.public ? <Typography color="secondary" variant="subtitle2">public</Typography> : <PaidContent viewBox="0 0 70 70" />)}
                                <Box ml={1} px={1} py={0.5} color='white' bgcolor={isLivePost ? 'success.main' : 'warning.main'} className={classes.status}>
                                    {isLivePost ? 'Live' : 'Draft'}
                                </Box>
                            </Box>
                        </>
                    }
                    classes= {{
                        content: classes.cardHeaderContent
                    }}
                />
                <CardActionArea onClick={post.slug ? this.navigateToPost(post.slug) : this.navigateToEditPost(post.id)}>
                    {post.images?.banner &&
                        <CardMedia
                            className={classes.media}
                            image={post.images?.banner}
                            title={post.title}
                        />
                    }
                    <CardContent>
                        <Typography variant="h6" color={post.title ? 'textPrimary' : 'textSecondary'}>
                            {post.title || 'Untitled'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {post.subtitle}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardFooter} disableSpacing>
                    <Box display="flex" alignItems="center">
                        <Tooltip arrow title={`${metrics[MetricType.Like] || 'No'} Like${metrics[MetricType.Like] === 1 ? '' : 's'}`}><div>
                            <Like postId={post.id}
                                count={metrics[MetricType.Like]}
                                liked={false}
                            />
                        </div></Tooltip>
                        <Tooltip arrow title={`${metrics[MetricType.Comment] || 'No'} Comment${metrics[MetricType.Comment] === 1 ? '' : 's'}`}>
                            <Box display="flex" alignItems="center" className={classes.reaction}>
                                <ModeCommentIcon color="action" fontSize="small" />
                                <Typography className={classes.reactionCount}>{metrics[MetricType.Comment] || 0}</Typography>
                            </Box>
                        </Tooltip>
                        {isLivePost && <Tooltip arrow title={`${metrics[MetricType.View] || 'No'} View${metrics[MetricType.View] === 1 ? '' : 's'}`}>
                            <Box display="flex" alignItems="center" className={classes.reaction}>
                                <VisibilityIcon color="action" fontSize="small" />
                                <Typography className={classes.reactionCount}>{metrics[MetricType.View] || 0}</Typography>
                            </Box>
                        </Tooltip>}
                    </Box>
                    <IconButton color="primary" onClick={this.navigateToEditPost(post.id)}>
                        <EditIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </Grid>;
    }


    // [DN] TODO: we should really create something like https://stackoverflow.com/questions/45377854/react-router-v4-navigate-from-mobx-store-action
    private navigateToEditPost = (id: number) => () => this.props.router!.push(`/post/${id}`);
    private navigateToPost = (slug: string) => () => this.props.router!.push(`/${this.props.publication.slug}/${slug}`);
}

export default withStyles(styles)(DashboardTableItem);
