import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Box, Chip, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Tooltip, Typography } from '@material-ui/core';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import SettingsIcon from '@material-ui/icons/Settings';
import { Subscription } from '../../models';
import { PlanPeriod, PlanType, SubscriberPlan } from '../../models/Plan';
import { RouterStore } from '../../stores/RouterStore';
import { RootStore } from '../../stores/RootStore';
import { constants } from '../../helpers/constants';
import UpdateSubscriptionDialog from './UpdateSubscriptionDialog';
import _ from 'lodash';
import moment from 'moment';

const styles = (theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2, 3, 3)
    }
});

interface SubscriptionSettingsProps extends WithStyles<typeof styles> {
    router?: RouterStore;
    subscriptions: Subscription[];
    onUnsubscribe: (publicationId: number) => void;
    onPlanUpdate: (publicationId: number, subscriberPlan: SubscriberPlan) => void;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore
}))
@observer
class SubscriptionSettings extends React.Component<SubscriptionSettingsProps> {
    @observable private dialogOpen: boolean = false;
    @observable private updatingSubscription: Subscription = {} as Subscription;
    @observable private subscriptionToDowngrade: Subscription | undefined;

    public render() {
        const { classes, subscriptions } = this.props;

        return <Paper className={classes.root}>
            <Typography variant="h6" paragraph>My subscriptions</Typography>
            <List>
                {_.orderBy(subscriptions, s => s.subscriberPlan).map(s =>
                    <ListItem key={s.name} button onClick={this.navigateToPublication(s.slug)}>
                        <ListItemText>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mr={1}>
                                <Typography>{s.name}</Typography>
                                {s.subscriberPlan && <>
                                    {(s.subscriberPlan.endDate && !s.subscriberPlan.upcomingPlan) ?
                                        <Chip label={`Cancels ${moment(s.subscriberPlan.endDate).format('ll')}`} size="small" /> :
                                        <Typography variant="body2" color="textSecondary">{constants.currencies.find(c => c.code === s.subscriberPlan.currency)?.symbol}{s.subscriberPlan.amount}/{s.subscriberPlan.period === PlanPeriod.Annual ? 'year' : 'month'}</Typography>
                                    }
                                </>}
                            </Box>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            {/* <Unsubscribe publication={s} onSuccess={this.onUnsubscribe(s.id)} /> */}
                            <Tooltip arrow title={s.subscriberPlan ? 'Update or Unsubscribe' : 'Unsubscribe'}>
                                <IconButton edge="end" onClick={this.update(s)}>
                                    {s.subscriberPlan ? <SettingsIcon /> : <UnsubscribeIcon />}
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
            </List>
            {this.dialogOpen && 
                <UpdateSubscriptionDialog 
                    subscription={this.updatingSubscription} 
                    subscriptionToDowngrade={this.subscriptionToDowngrade}
                    onDialogClose={this.toggleDialog} 
                    onUnsubscribe={this.onUnsubscribe}
                    onPlanUpdate={this.onPlanUpdate}
                />
            }
        </Paper>;
    }

    private toggleDialog = () => this.dialogOpen = !this.dialogOpen;

    private update = (subscription: Subscription) => () => {
        this.updatingSubscription = subscription;
        this.subscriptionToDowngrade = undefined;

        // see if we need to downgrade a subscription (in case original is getting cancelled and user still has a discounted "MultipleSubscriptions" one - this is the one to downgrade)
        if (subscription.subscriberPlan) {
            const otherSubscriptions = this.props.subscriptions.filter(s => s.subscriberPlan && s.subscriberPlan.id !== subscription.subscriberPlan.id && (!s.subscriberPlan.endDate || s.subscriberPlan.upcomingPlan));
            if (otherSubscriptions?.length === 1 && (otherSubscriptions[0].subscriberPlan.upcomingPlan || otherSubscriptions[0].subscriberPlan).type === PlanType.MultipleSubscriptions) {
                this.subscriptionToDowngrade = otherSubscriptions[0];
            }
        }

        this.toggleDialog();
    }

    // [DN] TODO: we should really create something like https://stackoverflow.com/questions/45377854/react-router-v4-navigate-from-mobx-store-action
    private navigateToPublication = (name: string) => () => this.props.router!.push(`/${name}`);

    private onUnsubscribe = (publicationId: number) => {
        this.props.onUnsubscribe(publicationId);
        this.toggleDialog();
    }

    private onPlanUpdate = (publicationId: number, subscriberPlan: SubscriberPlan, closeDialog: boolean) => {
        this.props.onPlanUpdate(publicationId, subscriberPlan);

        // when canceling the plan, there is a possibility it's called twice -> closeDialog parm is for that
        if (closeDialog)
            this.toggleDialog();
    }
}

export default withStyles(styles)(SubscriptionSettings);