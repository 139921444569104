import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Box, List, Link } from '@material-ui/core';
import { auth } from '../../services/auth/Auth';
import { Comment } from '../../models';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import CommentDetails from './CommentDetails';
import { RouterStore } from '../../stores/RouterStore';
import { RootStore } from '../../stores/RootStore';
import { Link as RouterLink } from 'react-router-dom';
import CommentBox from './CommentBox';
import { UserStore } from '../../stores/UserStore';

const styles = (theme: Theme) => createStyles({
    root: {

    }
});

interface CommentsProps extends WithStyles<typeof styles> {
    postId: number;
    router?: RouterStore;
    userStore?: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class Comments extends React.Component<CommentsProps> {
    @observable private comments: Comment[] = [];
    
    public render() {
        const { classes, postId } = this.props;

        return <Box className={classes.root}>
            <Box mb={1}>
                {this.renderCommentBox()}
            </Box>
            <List dense>
                {this.comments.map(c => <CommentDetails key={c.id} comment={c} postId={postId} />)}
            </List>
        </Box>;
    }

    public async componentDidMount() {
        await this.loadComments();
    }

    private renderCommentBox = () => {
        const { router, postId, userStore } = this.props;

        if (!userStore?.isAuthenticated) {
            return <Typography variant="subtitle1" paragraph>
                <Link component={RouterLink} to={{ pathname: '/login', state: { from: toJS(router?.location) } }}>Log In</Link> or <Link component={RouterLink} to={{ pathname: '/join', state: { from: toJS(router?.location) } }}>Sign Up</Link> to comment
            </Typography>;
        }

        return <CommentBox postId={postId} onSuccess={this.addComment} />;
    }

    private addComment = (comment: Comment) => this.comments.unshift(comment);

    private loadComments = async () => {
        try {
            const response = await auth.fetch(`/api/posts/${this.props.postId}/comments`);
            this.comments = await response.json() as Comment[];
        }
        catch (error) {
            // handle error!
            console.log(error);
        }
    }
}

export default withStyles(styles)(Comments);
