import React from 'react';
import { SvgIcon } from '@material-ui/core';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
// import CodeIcon from '@material-ui/icons/Code';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import StrikethroughSIcon from '@material-ui/icons/StrikethroughS';
import LinkIcon from '@material-ui/icons/Link';
import FormatTextdirectionLToRIcon from '@material-ui/icons/FormatTextdirectionLToR';
import TitleIcon from '@material-ui/icons/Title';
import ImageIcon from '@material-ui/icons/Image';
//import TextFormatIcon from '@material-ui/icons/TextFormat';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import GridOnIcon from '@material-ui/icons/GridOn';
//import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
// import FontDownloadOutlinedIcon from '@material-ui/icons/FontDownloadOutlined';
import FormatSizeIcon from '@material-ui/icons/FormatSize';

export default {
    em: <FormatItalicIcon />,
    italic: <FormatItalicIcon />,
    strong: <FormatBoldIcon />,
    alignLeft: <FormatAlignLeftIcon />,
    alignCenter: <FormatAlignCenterIcon />,
    alignRight: <FormatAlignRightIcon />,
    alignJustify: <FormatAlignJustifyIcon />,
    bold: <FormatBoldIcon />,
    code: <SvgIcon>
        <path d="M7.49602 10.552L3.86002 11.8L7.49602 13.048V15.088L1.37602 12.868V10.732L7.49602 8.512V10.552ZM12.8374 5.896H15.2374L11.2774 17.2H8.87742L12.8374 5.896ZM22.6226 10.732V12.868L16.5026 15.088V13.048L20.1386 11.8L16.5026 10.552V8.512L22.6226 10.732Z" />
    </SvgIcon>,
    underline: <FormatUnderlinedIcon />,
    strikethrough: <StrikethroughSIcon />,
    link: <LinkIcon />,
    paragraph: <FormatTextdirectionLToRIcon />,
    heading: <FormatSizeIcon />,
    blockquote: <FormatQuoteIcon />,
    // code_block: <UnfoldMoreIcon />,
    code_block: <SvgIcon>
        <path d="M3 19.5H21C22.3807 19.5 23.5 18.3807 23.5 17V7C23.5 5.61929 22.3807 4.5 21 4.5H3C1.61929 4.5 0.5 5.61929 0.5 7V17C0.5 18.3807 1.61929 19.5 3 19.5Z" />
        <path d="M8.62201 10.914L5.89501 11.85L8.62201 12.786V14.316L4.03201 12.651V11.049L8.62201 9.384V10.914ZM12.6281 7.422H14.4281L11.4581 15.9H9.65807L12.6281 7.422ZM19.9669 11.049V12.651L15.3769 14.316V12.786L18.1039 11.85L15.3769 10.914V9.384L19.9669 11.049Z" fill="white" />
    </SvgIcon>,
    ordered_list: <FormatListNumberedIcon />,
    bullet_list: <FormatListBulletedIcon />,
    image: <ImageIcon />,
    table: <GridOnIcon />,
    footnote: <TitleIcon />,
    undo: <UndoIcon />,
    redo: <RedoIcon />,
    lift: <TitleIcon />,
    join_up: <TitleIcon />,
    subscript: <SvgIcon>
        <path d="M21.5998 19.9V21H16.4398V20.13L19.2198 17.49C19.5332 17.19 19.7432 16.93 19.8498 16.71C19.9632 16.4833 20.0198 16.26 20.0198 16.04C20.0198 15.7133 19.9098 15.4633 19.6898 15.29C19.4698 15.1167 19.1465 15.03 18.7198 15.03C18.0065 15.03 17.4598 15.2733 17.0798 15.76L16.1698 15.06C16.4432 14.6933 16.8098 14.41 17.2698 14.21C17.7365 14.0033 18.2565 13.9 18.8298 13.9C19.5898 13.9 20.1965 14.08 20.6498 14.44C21.1032 14.8 21.3298 15.29 21.3298 15.91C21.3298 16.29 21.2498 16.6467 21.0898 16.98C20.9298 17.3133 20.6232 17.6933 20.1698 18.12L18.2998 19.9H21.5998Z" />
        <path d="M12.2664 19L8.46641 13.68L4.70641 19H1.74641L6.94641 11.86L2.02641 5H4.96641L8.54641 9.98L12.0864 5H14.9064L10.0064 11.76L15.2664 19H12.2664Z" />
    </SvgIcon>,
    superscript: <SvgIcon>
        <path d="M21.5998 8.9V10H16.4398V9.13L19.2198 6.49C19.5332 6.19 19.7432 5.93 19.8498 5.71C19.9632 5.48333 20.0198 5.26 20.0198 5.04C20.0198 4.71333 19.9098 4.46333 19.6898 4.29C19.4698 4.11667 19.1465 4.03 18.7198 4.03C18.0065 4.03 17.4598 4.27333 17.0798 4.76L16.1698 4.06C16.4432 3.69333 16.8098 3.41 17.2698 3.21C17.7365 3.00333 18.2565 2.9 18.8298 2.9C19.5898 2.9 20.1965 3.08 20.6498 3.44C21.1032 3.8 21.3298 4.29 21.3298 4.91C21.3298 5.29 21.2498 5.64667 21.0898 5.98C20.9298 6.31333 20.6232 6.69333 20.1698 7.12L18.2998 8.9H21.5998Z" />
        <path d="M12.2664 19L8.46641 13.68L4.70641 19H1.74641L6.94641 11.86L2.02641 5H4.96641L8.54641 9.98L12.0864 5H14.9064L10.0064 11.76L15.2664 19H12.2664Z" />
    </SvgIcon>
}
