import { observable, action, computed } from 'mobx';
import { User, Publication, Post, Plan, SubscriberPlan, Subscription } from '../models';
import { auth } from '../services/auth/Auth';
import _ from 'lodash';

export class UserStore {
    // [TODO] this needs to be updated back to false when token expires and "me" set to an empty object
    @observable public isAuthenticated: boolean = false;
    @observable public me: User = {} as User;

    constructor() {
        if (auth.isLoggedIn) {
            this.authenticate();
        }
    }

    @computed
    public get meResolved() {
        return !_.isEmpty(this.me);
    }

    @action
    public authenticate = async (token?: string) => {
        if (token) {
            auth.token = token;
        }

        this.isAuthenticated = auth.isLoggedIn;
        await this.setMe();
    }

    @action
    public addPublication = (publication: Publication) => this.me.publications.push(publication);

    @action
    public deletePublication = (publicationId: number) => {
        this.me.publications = this.me.publications.filter(s => s.id !== publicationId);

        // it's possible that author is subscribed to their own publication, so need to remove subscription as well
        this.deleteSubscription(publicationId);
    }

    @action
    public addSubscription = (subscription: Subscription) => this.me.subscriptions.push(subscription);

    @action
    public deleteSubscription = (publicationId: number) => {
        this.me.subscriptions = this.me.subscriptions.filter(s => s.id !== publicationId);
    }

    @action
    public updatePublicationPlans = (publicationId: number, plans: Plan[]) => {
        const publication = this.me.publications.find(p => p.id === publicationId);
        if (publication) {
            publication.plans = plans;
        }
    }

    @action
    public addSubscriberPlan = (publicationId: number, plan: SubscriberPlan) => {
        const subscription = this.me.subscriptions.find(p => p.id === publicationId);
        if (subscription) {
            subscription.subscriberPlan = plan;
        }
    }

    @action
    public updateSubscriberPlan = (publicationId: number, plan: SubscriberPlan) => {
        this.addSubscriberPlan(publicationId, plan);
    }

    @action
    public addPost = (post: Post) => this.me.posts.push(post);

    @action
    public deletePost = (postId: number) => {
        this.me.posts = this.me.posts.filter(s => s.id !== postId);
    }

    @action
    private setMe = async () => {
        try {
            const response = await auth.fetch('/api/me');
            this.me = await response.json() as User;
        }
        catch (error) {
            // handle error!
            console.log(error);
        }
    }

    // needs to be called when token expires or when we log out (log out is fine for now since we refresh the page -> this.me will reset)
    @action
    public deleteMe = async () => {
        this.me = {} as User;
        this.isAuthenticated = auth.isLoggedIn;
    }
}