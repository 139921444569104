import jwt_decode from 'jwt-decode';
import moment from 'moment';
import * as _ from 'lodash';

interface TokenData {
    id: number;
    email: string;
    exp: number;
}

class Auth {
    public get token(): string | null {
        return localStorage.getItem('token');
    }
    public set token(token: string | null) {
        localStorage.setItem('token', token!);
    }

    private isTokenExpired = (token: string) => {
        let expired: boolean = false;
        try {
            const decodedToken = jwt_decode<TokenData>(token);
            // console.log(decodedToken);
            // console.log(moment().format());
            // console.log(moment.unix(decodedToken.exp).format());
            expired = moment().isAfter(moment.unix(decodedToken.exp));
        } catch { 
            expired = true;
        }

        // [DN] [TODO] refresh token here??? call refresh-token api?
        if (expired) {
            this.logout();
        }

        return expired;
    }

    private isSuccessfulStatus = (status: number) => (status >= 200 && status < 300) || status === 304;

    public fetch = (input: RequestInfo, init?: RequestInit, ignoreUnauthorized: boolean = false) => this.fetchWrapper(input, init, false, ignoreUnauthorized);

    public fetchWithFile = (input: RequestInfo, init?: RequestInit) => this.fetchWrapper(input, init, true);

    private fetchWrapper = (input: RequestInfo, init?: RequestInit, fileUpload = false, ignoreUnauthorized = false) => {
        const token = this.token;

        // all POST fetches will use json content type
        const headers: HeadersInit = {};

        if (!fileUpload) {
            headers['Content-Type'] = 'application/json';
        }

        // add bearer token if present
        if (!!token) {
            headers['Authorization'] = 'Bearer ' + token;
        }

        // merge default options/headers with the ones that are passed in to the function
        const defaultOptions: RequestInit = { headers };
        init = _.merge(defaultOptions, init);
    
        return fetch(input, init).then((response: Response) => {
            // [DN] if Unauthorized (401), reload? 401 means something is wrong with the token - expired, invalid or else
            // should probably update userStore.isAuthenticated somehow instead of pure reloading... but it's fine like that for now I guess...
            if (response.status === 401 && !ignoreUnauthorized) {
               this.logout();
               window.location.reload();
            }
    
            // [DN] we want to resolve only, every fetch is being checked for 200 status, we don't use "catch" blocks (which is what reject will result in)
            // if (response.status !== 200) {
            //     return Promise.reject(response);
            // }
    
            return Promise.resolve(response);
        });
    }

    // [DN] [TODO] this is called a lot, cache the result somewhere? how do I determine when to change the result?
    public get isLoggedIn() {
        const token = this.token;
        // console.log(token);
        return !!token && !this.isTokenExpired(token);
    }

    public login = async (email: string, redirect: string) => {
        return await this.fetch('/api/users/login', { 
            method: 'POST',
            body: JSON.stringify({ email, redirect })
        });
    }

    public loginWithPassword = async (email: string, password: string) => {
        return await this.fetch('/api/users/password-login', { 
            method: 'POST',
            body: JSON.stringify({ email, password })
        });
    }

    // used when logging in using email link (passwordless)
    public verifyLogin = (token: string) => {
        this.token = token;
        return this.verifyToken().then(response => {

            if (!this.isSuccessfulStatus(response.status)) {
                this.logout();
            }

            return Promise.resolve(response);
        });
    }

    public verifyToken = () => this.fetch('/api/users/authenticate', undefined, true);

    public logout = () => localStorage.removeItem('token');

    public get user() {
        return jwt_decode<TokenData>(this.token!);
    }
}

export const auth = new Auth();