import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, ListItem, ListItemAvatar, Avatar, ListItemText, List, IconButton, Box, Link, Tooltip, Button } from '@material-ui/core';
import { Comment } from '../../models';
import { MetricType } from '../../models/MetricType';
import ChildComment from './CommentDetails';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import moment from 'moment';
import ReplyIcon from '@material-ui/icons/Reply';
import { auth } from '../../services/auth/Auth';
import CommentBox from './CommentBox';
import Like from '../../shared/Like';
import { UserStore } from '../../stores/UserStore';
import { RootStore } from '../../stores/RootStore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme: Theme) => createStyles({
    timeAgo: {
        marginLeft: theme.spacing(1)
    },
    nestedComments: {
        flexGrow: 1,
        marginLeft: theme.spacing(2.5),
        borderLeft: `1px solid ${theme.palette.divider}`
    },
    reaction: {
        marginLeft: theme.spacing(1)
    },
    listItem: {
        paddingRight: 0
    }
});

interface CommentDetailsProps extends WithStyles<typeof styles> {
    userStore?: UserStore;
    
    comment: Comment;
    postId: number; //[DN] TODO: this should be moved to Comment probably, however I don't know if I want to include Post object into all those comments
}

@inject((stores: RootStore) => ({
    userStore: stores.userStore
}))
@observer
class CommentDetails extends React.Component<CommentDetailsProps> {
    @observable private comment: Comment;

    @observable private showReplyForm: boolean = false;
    @observable private showEditForm: boolean = false;
    @observable private showDeleteConfirmation: boolean = false;

    constructor(props: CommentDetailsProps) {
		super(props);

        this.comment = props.comment;
	}

    public render() {
        const { classes, postId } = this.props;

        return <>
            <ListItem alignItems="flex-start" className={classes.listItem}>
                <ListItemAvatar>
                    <Avatar alt={this.comment.user?.name} src={this.comment.user?.avatarUrl}>{this.comment.user?.avatarAlt}</Avatar>
                </ListItemAvatar>
                <ListItemText>
                    {this.showEditForm ?
                        <CommentBox editing postId={postId} comment={this.comment} onSuccess={this.updateComment} onCancel={this.cancelEdit} /> :
                        <>
                            <Typography variant="subtitle2">
                                {this.comment.user?.name || 'someone'}
                                <Typography variant="caption" color="textSecondary" className={classes.timeAgo}>
                                    {moment(this.comment.createdOn).fromNow()}
                                </Typography>
                                {(this.comment.createdOn !== this.comment.updatedOn && !this.comment.deleted) &&
                                    <Typography variant="caption" color="textSecondary">
                                        &nbsp;(edited {moment(this.comment.updatedOn).fromNow()})
                                    </Typography>
                                }
                            </Typography>
                            {this.renderCommentBody()}
                            {!this.comment.deleted && this.renderFooter()}
                        </>
                    }
                </ListItemText>
            </ListItem>
            {this.comment.children && <ListItem className={classes.listItem}>
                <List disablePadding dense className={classes.nestedComments}>
                    {this.comment.children.map(c => <ChildComment key={c.id} comment={c} postId={postId} />)}
                </List>
            </ListItem>}
        </>;
    }

    private renderCommentBody = () => {
        if (this.comment.deleted) {
            return <Typography variant="body2"><i>deleted</i></Typography>;
        }

        // url regex
        // [DN] possibly look into https://github.com/alexplumb/material-ui-linkify and dependent/related libs
        var expression = /((?:https?:\/\/)?[\w\-~]+(?:\.[\w\-~]+)+(?:\/[\w\-~@:%]*)*(?:\.(?:js|html?|aspx?))?(?:#[\w\-\/]*)?(?:\?[^\s]*)?)/;

        // first, we remove all redundant newline characters, then we replace url-like text with actual links
        return this.comment.body.replace(/\n+/g, '\n').split('\n').map((p, i, array) => {
            const urlParsed = p.split(new RegExp(expression, 'gi')).map((part, index) => 
                index % 2 === 0 ? 
                    part : 
                    <Link key={index} href={part.match(/https?:\/\//) ? part : `http://${part}`} target="_blank" rel="noopener nofollow">{part}</Link>
            );

            return <Typography variant="body2" key={i} gutterBottom={!!array[i + 1]}>{urlParsed}</Typography>;
        });
    }

    private renderFooter = () => {
        const { postId, userStore, classes } = this.props;

        if (!userStore?.isAuthenticated)
            return null;

        return this.showReplyForm ? 
            <CommentBox postId={postId} comment={this.comment} onSuccess={this.addComment} onCancel={this.cancelReply} placeholder={`You're replying to ${this.comment.user?.name || 'someone'}`} /> :
            <Box display="flex" alignItems="center">
                <Box display="flex">
                    <Like commentId={this.comment.id}
                        count={this.comment.metrics && this.comment.metrics[MetricType.Like]}
                        liked={this.comment.reactions && this.comment.reactions[MetricType.Like]?.includes(userStore!.me.id)}
                        actionable={userStore?.isAuthenticated}
                    />
                    <Tooltip arrow title="Reply">
                        <IconButton size="small" onClick={this.reply} className={classes.reaction}><ReplyIcon /></IconButton>
                    </Tooltip>
                </Box>
                {this.comment.user?.id === userStore!.me.id && <Box ml={1} display="flex">
                    <Tooltip arrow title="Edit">
                        <IconButton size="small" onClick={this.edit}><EditIcon fontSize="small" /></IconButton>
                    </Tooltip>
                    {this.showDeleteConfirmation ?
                        <Box ml={2}>
                            <Typography variant="caption" color="error">Are you sure?</Typography>
                            <Button size="small" onClick={this.deleteComment} style={{ minWidth: 'auto' }} className={classes.reaction}>Yes</Button>
                            <Button size="small" onClick={this.cancelDelete} style={{ minWidth: 'auto' }}>No</Button>
                        </Box> :
                        <Tooltip arrow title="Delete">
                            <IconButton size="small" onClick={this.delete} className={classes.reaction}><DeleteIcon fontSize="small" /></IconButton>
                        </Tooltip>
                    }
                </Box>}
            </Box>;
    }

    private addComment = (comment: Comment) => {
        if (!this.comment.children)
            this.comment.children = [];

        this.comment.children.unshift(comment);

        this.cancelReply();
    }

    private updateComment = (comment: Comment) => {
        this.comment = Object.assign(this.comment, comment);

        this.cancelEdit();
    }

    private deleteComment = async () => {
        try {
            var response = await auth.fetch(`/api/comments/${this.comment.id}`, { method: 'DELETE' });
            if (response.status === 200) {
                const deletedComment = await response.json() as Comment;

                // keep original children, deletedComment doesn't return them
                this.comment = Object.assign(deletedComment, { children: this.comment.children });
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }

        this.cancelDelete();
    }

    private reply = () => this.showReplyForm = true;
    private cancelReply = () => this.showReplyForm = false;

    private edit = () => this.showEditForm = true;
    private cancelEdit = () => this.showEditForm = false;

    private delete = () => this.showDeleteConfirmation = true;
    private cancelDelete = () => this.showDeleteConfirmation = false;

}

export default withStyles(styles)(CommentDetails);
