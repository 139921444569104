import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Box, IconButton, Fab } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { auth } from '../services/auth/Auth';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

const styles = (theme: Theme) => createStyles({
    fab: {
        boxShadow: 'none'
    }
});

interface LikeProps extends WithStyles<typeof styles> {
    postId?: number;
    commentId?: number;
    count?: number;
    liked: boolean; // render liked state
    actionable?: boolean; // render button vs text (authed vs anon users normally)
    size?: 'default' | 'small' | 'large';
    isFab?: boolean;

    onSuccess?: (likeDiff: number) => void;
}

@observer
class Like extends React.Component<LikeProps> {
    @observable private count: number;
    @observable private liked: boolean;

    constructor(props: LikeProps) {
        super(props);

        this.count = props.count || 0;
        this.liked = props.liked;
    }

    public render() {
        const { classes, actionable, size, isFab } = this.props;

        return isFab ?
            <Box display="inline-flex" alignItems="center">
                {actionable ?
                    <Fab size="small" color={this.liked ? 'secondary' : 'inherit'} variant="extended" className={classes.fab} onClick={this.liked ? this.unlike : this.like}>
                        <ThumbUpIcon fontSize="small" />
                        {this.count > 0 && <Typography style={{ marginLeft: 3 }}>{this.count}</Typography>}
                    </Fab> :
                    <Box display="flex">
                        <ThumbUpIcon color="action" fontSize={size || 'small'}  />
                        {this.count > 0 && <Typography style={{ marginLeft: 3 }}>{this.count}</Typography>}
                    </Box>
                }
            </Box> :
            <Box display="inline-flex" alignItems="center">
                {actionable ?
                    <IconButton size="small" color={this.liked ? 'secondary' : 'default'} onClick={this.liked ? this.unlike : this.like}>
                        <ThumbUpIcon fontSize={size || 'small'} />
                    </IconButton> :
                    <Box display="flex" p="3px">
                        <ThumbUpIcon color="action" fontSize={size || 'small'} />
                    </Box>
                }
                {this.count > 0 && <Typography color={this.liked ? 'secondary' : 'inherit'}>{this.count}</Typography>}
            </Box>;
    }

    public componentDidUpdate(prevProps: LikeProps) {
        if (prevProps.count !== this.props.count && this.props.count !== this.count) {
            this.count = this.props.count || 0;
        }

        if (prevProps.liked !== this.props.liked && this.props.liked !== this.liked) {
            this.liked = this.props.liked;
        }
    }

    private like = async () => {
        this.liked = true;
        this.count++;

        try {
            let url = '';
            if (this.props.postId) {
                url = `/api/posts/${this.props.postId}/like`;
            }
            else if (this.props.commentId) {
                url = `/api/comments/${this.props.commentId}/like`;
            }

            var response = await auth.fetch(url, { method: 'POST' });
            if (response.status !== 200) {
                this.liked = false;
                this.count--;
            }
            else if (this.props.onSuccess) {
                this.props.onSuccess(1);
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here???? do i need to?
            // logger.exception(error);
            console.log(error);
            this.liked = false;
            this.count--;
        }
    }

    private unlike = async () => {
        this.liked = false;
        this.count--;

        try {
            let url = '';
            if (this.props.postId) {
                url = `/api/posts/${this.props.postId}/like`;
            }
            else if (this.props.commentId) {
                url = `/api/comments/${this.props.commentId}/like`;
            }

            var response = await auth.fetch(url, { method: 'DELETE' });
            if (response.status !== 200) {
                this.liked = true;
                this.count++;
            }
            else if (this.props.onSuccess) {
                this.props.onSuccess(-1);
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here???? do i need to?
            // logger.exception(error);
            console.log(error);
            this.liked = true;
            this.count++;
        }
    }
}

export default withStyles(styles)(Like);
