import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Container, TextField, Button, Typography, Box, InputAdornment, IconButton, Tooltip, Backdrop, CircularProgress, Grid, ButtonBase, FormControlLabel, Switch, Chip, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle } from '@material-ui/core';
import { observable, toJS, when } from 'mobx';
import { Post, PostStatus } from '../../models/Post';
import { auth } from '../../services/auth/Auth';
import { converter } from '../../helpers/converter';
import { ApiMessage, Publication } from '../../models';
import { PageLoader } from '../../shared/PageLoader';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { RouterStore } from '../../stores/RouterStore';
import { RootStore } from '../../stores/RootStore';
import AutoSave from '../../shared/AutoSave';
import { RouteComponentProps } from 'react-router-dom';
import { EditorStore } from '../../stores/EditorStore';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import { validation } from '../../helpers/validation';
import Editor from './Editor'
import { UserStore } from '../../stores/UserStore';
import { constants } from '../../helpers/constants';
import { image } from '../../services/image/Image';
import { prosemirrorHelper } from '../../services/prosemirror/helper';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    textField: {
        margin: theme.spacing(1, 0, 0.5)
    },
    textField2: {
        margin: theme.spacing(0.5, 0)
    },
    textField3: {
        marginBottom: theme.spacing(0.5)
    },
    inputAdornment: {
        marginRight: 0
    },
    backdrop: {
        zIndex: theme.zIndex.tooltip + 1,
        color: theme.palette.common.white
    },
    live: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main
    },
    draft: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.warning.main
    },
    uploadImage: {
        objectFit: 'cover',
        width: '100%',
        height: 160
    },
    header: {
        position: 'sticky',
        top: 63,
        backgroundColor: theme.palette.background.default
    }
});

interface MatchParams {
    id: string;
};

interface EditPostProps extends WithStyles<typeof styles>, RouteComponentProps<MatchParams> {
    router: RouterStore;
    editorStore: EditorStore;
    userStore: UserStore;
}

// [DN] IMPORTANT!!!! Save Draft and AutoSave are OFF for published posts

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    editorStore: stores.editorStore,
    userStore: stores.userStore
}))
@observer
class EditPost extends React.Component<EditPostProps> {
    private publication: Publication = {} as Publication;

    @observable private previewImageUrl: string = '';
    @observable private errors: Map<string, string> = new Map(); // key is field name, value is error message
    @observable private message: ApiMessage = { body: '', isError: false };
    @observable private deleteDialogOpen: boolean = false;

    // used by actions on the page (publish, save draft)
    @observable private submitting: boolean = false;

    constructor(props: EditPostProps) {
        super(props);

        // see if there is no post in store yet, or the one without ID, load it
        if (!props.editorStore.post.id) {
            when(() => props.userStore.meResolved, this.onMeResolved);
        }
        else {
            // I think you can only get here after NewPost which will have a publication object
            this.publication = props.editorStore.post.publication;
            this.message.body = `Last save ${moment(props.editorStore.post.updatedOn).format('llll')}`;
        }
    }

    public render() {
        const { classes, editorStore, userStore } = this.props;

        // [DN] TODO: so this.errors.set('tags') doesn't force component to rerender because it's checked inside the Autocomplete child input component
        // if I uncomment the code below it'll work fine, since any changes to this.errors will force rerender
        // need to find the right way to handle it, for now something has to trigger component render in order for tags to show an error
        //console.log(toJS(this.errors));

        if (!userStore.meResolved)
            return <PageLoader />;

        const isLivePost = editorStore.post.status === PostStatus.Published || editorStore.post.status === PostStatus.Republished;

        return <Container className={classes.root}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="h5">{this.publication.name} publication</Typography>
                <Tooltip arrow title="Delete Post">
                    <Button variant="outlined" size="small" color="secondary" onClick={this.toggleDeleteDialog}>Delete</Button>
                </Tooltip>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                        <input accept="image/*" id="pic" type="file" style={{ display: 'none' }} onChange={this.handleUpload} />
                        <label htmlFor="pic">
                            <ButtonBase component="span">
                                <img src={this.getImageUrl()} alt="post banner" className={classes.uploadImage} />
                            </ButtonBase>
                        </label>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <TextField name="title" fullWidth value={editorStore.post.title || ''} className={classes.textField3} onChange={this.handleChange}
                            InputProps={{ startAdornment: <InputAdornment disablePointerEvents position="start">Title:</InputAdornment> }}
                            error={this.errors.has('title')} helperText={this.errors.get('title')}
                        />
                        <TextField name="subtitle" fullWidth value={editorStore.post.subtitle || ''} className={classes.textField} onChange={this.handleChange}
                            InputProps={{ startAdornment: <InputAdornment disablePointerEvents position="start">Subtitle:</InputAdornment> }}
                            helperText="optional"
                        />
                        <TextField name="slug" fullWidth value={editorStore.post.slug || ''} className={classes.textField2} onChange={this.handleChange} disabled={editorStore.post.status !== PostStatus.Draft}
                            InputProps={{
                                startAdornment: <InputAdornment disablePointerEvents className={classes.inputAdornment} position="start">{`https://storylect.com/${this.publication.slug || ''}/`}</InputAdornment>
                            }}
                            error={this.errors.has('slug')} helperText={this.errors.has('slug') ? this.errors.get('slug') : "You won't be able to change post URL once it's published"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete multiple freeSolo value={editorStore.post.tags || []} onChange={this.handleTagsChange}
                            options={constants.tags}
                            renderInput={params => <TextField {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: <>
                                        <InputAdornment disablePointerEvents position="start">Tags:</InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                }}
                                placeholder="Type relevant tag and press Enter to add" helperText={this.errors.has('tags') ? this.errors.get('tags') : "Tags help with post discoverability"}
                                error={this.errors.has('tags')} />
                            }
                        />
                        {this.publication.plans.length > 0 &&
                            <Box display="flex" alignItems="center" mt={1}>
                                <Typography color="textSecondary">Public?</Typography>
                                <FormControlLabel className={classes.textField2}
                                    control={<Switch checked={editorStore.post.public} onChange={this.handleChange} name="public" />}
                                    label={<Typography variant="body2">{editorStore.post.public ? 'currently visible to everyone' : 'currently visible to paying subscribers only'}</Typography>}
                                />
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" py={1.5} className={classes.header}>
                <Box display="flex" alignItems="center">
                    <Chip label={isLivePost ? 'Live' : 'Draft'} size="small" className={isLivePost ? classes.live : classes.draft} />
                    <Box fontStyle="italic" ml={1}>
                        <Typography variant="caption">
                            {/* [DN] make sure to unload AutoSave when submitting through buttons, otherwise AutoSave will override */}
                            {(!isLivePost && !this.submitting) && <AutoSave onSave={this.updatePost} object={toJS(editorStore.post)} />}
                            <Typography variant="inherit" color={this.message.isError ? 'error' : 'initial'}>{this.message.body}</Typography>
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    {this.errors.size > 0 && <Typography color="error" variant="caption" style={{ marginRight: 8 }}>Unable to publish</Typography>}
                    <Button variant="outlined" size="small" onClick={this.saveDraft} style={{ marginRight: 8 }}>{isLivePost ? 'Unpublish' : 'Save'}</Button>
                    {userStore.me.emailVerified ?
                        <Button variant="contained" size="small" color="primary" onClick={this.publish}>Publish</Button> :
                        <Tooltip title={<Typography variant="caption">Verify your email (in user settings) to publish</Typography>} placement="top" arrow>
                            <Box display="inline-block"><Button variant="contained" size="small" color="primary" disabled>Publish</Button></Box>
                        </Tooltip>
                    }
                </Box>
            </Box>
            <Box border={this.errors.has('body') ? 1 : 0} borderColor={this.errors.has('body') ? 'error.main' : 'none'}>
                <Editor initialArticle={editorStore.post.body} onMarkdownChange={this.onMarkdownChange} />
            </Box>
            {this.errors.has('body') && <Typography color="error" variant="body2" align="right">{this.errors.get('body')}</Typography>}
            <Backdrop open={this.submitting} className={classes.backdrop}>
                <CircularProgress size={60} thickness={4} color="inherit" />
            </Backdrop>
            {this.renderDeleteDialog()}
        </Container>;
    }

    public componentWillUnmount() {
        // need to remove the post from the store when leaving, otherwise the same one is going to get loaded for all posts
        this.props.editorStore.clearPost();
    }

    private renderDeleteDialog = () => {
        return <Dialog open={this.deleteDialogOpen} onClose={this.toggleDeleteDialog}>
            <DialogTitle>Delete Post</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">Are you sure you want to delete this post?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.toggleDeleteDialog} color="primary">Cancel</Button>
                <Button onClick={this.delete} color="primary" variant="contained">Delete</Button>
            </DialogActions>
        </Dialog>;
    }

    private toggleDeleteDialog = () => this.deleteDialogOpen = !this.deleteDialogOpen;

    private onMeResolved = () => {
        const { editorStore, userStore, router } = this.props;

        const post = toJS(userStore.me.posts.find(p => p.id === +this.props.match.params.id));
        if (post) {
            editorStore.post = post;
            this.publication = post.publication || userStore.me.publications.find(p => p.id === post.publicationId)!;
            this.message.body = `Last save ${moment(post.updatedOn).format('llll')}`;
        }
        else {
            router.replace('/dashboard');
        }
    }

    private getImageUrl = () => this.previewImageUrl || this.props.userStore.me.posts.find(p => p.id === +this.props.editorStore.post.id)?.images?.banner || constants.defaultPostImageUrl;

    private handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { userStore } = this.props;

		const file = e.target.files![0];

		if (!file || !file.type.startsWith('image/')) {
			console.log('Invalid file type'); // do we need to tell user they are uploading invalid image???
			return;
		}

		// get temp image URL to show it to the user while the actual image is uploading
		this.previewImageUrl = image.getPreviewUrl(file);
		// console.log(this.previewImageUrl);

		try {
			const formData = new FormData();
			// [DN] when uploading files, the name always needs to be 'file' in order for API to handle it properly
			formData.append('file', file);

			const response = await auth.fetchWithFile(`/api/posts/${this.props.match.params.id}/upload`, {
				method: 'POST',
				body: formData
			});

			if (response.status === 200) {
                // update post in the user store
                const post = userStore.me.posts.find(p => p.id === +this.props.match.params.id)!;
                post.images = (await response.json() as Post).images;
                
                // no need to update editor store as image comes from and updates userstore directly
                //this.props.editorStore.post.images = post.images;
			}
			// [TODO] show user some kind of message (Success vs Error)
		}
		catch (error) {
			// handle error!
			console.log(error);
		}
		finally {
			image.releasePreviewUrl(this.previewImageUrl);
			this.previewImageUrl = '';
		}
	}

    private handleTagsChange = (e: React.ChangeEvent<{}>, values: string[], reason: AutocompleteChangeReason) => {
        this.clearApiMessage();

        if (values.length > 0 && values.slice(-1)[0].length < 3) {
            this.errors.set('tags', 'At least 3 characters are required');
        }
        else {
            this.errors.delete('tags');
            this.props.editorStore.post.tags = values;
        }
    }

    private onMarkdownChange = (output: object) => {
        this.clearApiMessage();

        // as user starts typing, we probably want to remove validation error
        this.errors.delete('body');

        this.props.editorStore.post.body = output;
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.clearApiMessage();

        const name = e.target.name;
        let value = e.target.type === 'text' ? e.target.value : e.target.checked;

        if (name === 'slug') {
            value = (value as string).toLowerCase();
        }

        // as user starts typing, we probably want to remove validation error
        this.errors.delete(name);

        (this.props.editorStore.post as any)[name] = value;

        if (name === 'title' && this.props.editorStore.post.status === PostStatus.Draft) {
            this.errors.delete('slug');

            this.props.editorStore.post.slug = converter.toSlug(value as string);
        }
    }

    // if post is live, don't clear the message, instead mimic auto save with "Unsaved Changes" message
    private clearApiMessage = () => this.message = {
        body: (this.props.editorStore.post.status === PostStatus.Published || this.props.editorStore.post.status === PostStatus.Republished) ? 'Unsaved changes, publish to make them live' : '',
        isError: false
    };

    private updatePost = async (publish: boolean = false) => {
        // need to make sure that slug always has either letters, numbers or dashes in DB at all times
        this.props.editorStore.post.slug = converter.toSlug(this.props.editorStore.post.slug);

        try {
            const response = await auth.fetch(`/api/posts/${this.props.match.params.id}`, {
                method: 'PUT',
                body: JSON.stringify(Object.assign({}, this.props.editorStore.post, { publish }))
            });

            if (response.status === 200) {
                const updatedPost = await response.json() as Post;

                // update post in the user store
                let post = this.props.userStore.me.posts.find(p => p.id === +this.props.match.params.id);
                post = Object.assign(post, updatedPost);

                // update status in editorStore
                this.props.editorStore.post.status = updatedPost.status;
            }

            return Promise.resolve(response);
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            //eturn Promise.reject(new Response());
            throw error;
        }
    }

    private saveDraft = async () => {
        this.submitting = true;

        try {
            const response = await this.updatePost();
            if (response.status === 200) {
                this.message = { body: `Last save ${moment().format('llll')}`, isError: false };
            }
            else {
                this.message = { body: `Not Saved: ${response.status === 500 ? 'network error' : await response.text()}`, isError: true };
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.message = { body: 'Not Saved: network error', isError: true };
        }
        finally {
            this.submitting = false;
        }
    }

    private publish = async () => {
        if (!this.isValid())
            return;

        // passed validation, let's cancel any autosaves and save this shit
        this.submitting = true;

        try {
            const response = await this.updatePost(true);
            if (response.status === 200) {
                this.props.router.push(`/${this.publication.slug}/${this.props.editorStore.post.slug}`);
            }
            else {
                this.message = { body: `Not Saved: ${response.status === 500 ? 'network error' : await response.text()}`, isError: true };
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.message = { body: 'Not Saved: network error', isError: true };
        }
        finally {
            this.submitting = false;
        }
    }

    // [TODO] create something better!!!!???
    private isValid = () => {
        const { editorStore } = this.props;

        this.errors.clear();

        if (!editorStore.post.title)
            this.errors.set('title', 'Title is required');
        if (!editorStore.post.slug)
            this.errors.set('slug', 'URL is required');
        else if (!validation.isValidSlug(editorStore.post.slug))
            this.errors.set('slug', 'Only letters, numbers and dashes are allowed');
        if (!editorStore.post.tags || editorStore.post.tags.length === 0)
            this.errors.set('tags', 'At least 1 tag is required');
        if (!editorStore.post.body)
            this.errors.set('body', 'Post body is required');
        else {
            // even though there is no text, the empty <p> tag may still be there, check for innerText
            const html = prosemirrorHelper.convertToHtmlFragment(editorStore.post.body);
            if (![...html.children].some(e => e.innerText))
                this.errors.set('body', 'Post body is required');
        }

        return this.errors.size === 0;
    }

    private delete = async () => {
        this.submitting = true;

        try {
            var response = await auth.fetch(`/api/posts/${this.props.match.params.id}`, { method: 'DELETE' });

            if (response.status === 200) {
                this.props.userStore.deletePost(+this.props.match.params.id);

                this.props.router.replace('/dashboard');
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }
        finally {
            this.submitting = false;
        }
    }
}

export default withStyles(styles)(EditPost);
