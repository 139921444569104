import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Container, Link } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
});

interface TermsProps extends WithStyles<typeof styles> {

}

class Terms extends React.Component<TermsProps> {
    public render() {

        const { classes } = this.props;

        return <Container className={classes.root}>
            <Typography variant="h4" gutterBottom>
                Terms of Use
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
                Oct 26th, 2021
            </Typography>

            <Typography variant="h5" gutterBottom>1. INTRODUCTION</Typography>
            <Typography variant="body2" paragraph>Welcome to Storylect! We are a publishing and direct subscription service that provides our members with access to a content publishing platform and ability to set subscription plans over the Internet (“The Services” or “Our Services”). These Terms of Use (“Terms of Use” or “Terms”) govern your use of our service and constitute a binding contract between you (“Customer” or “You”) and Storylect (“Company” or “We” or “Us”), regarding the terms under which the Company will provide Customer with access to The Services.
            BY ACTIVATING YOUR FREE STORYLECT ACCOUNT, CUSTOMER SIGNIFIES ITS AGREEMENT TO ABIDE BY THESE TERMS OF USE (“Acceptance”).
            PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY.  BY REGISTERING FOR, ACCESSING, BROWSING, POSTING, DOWNLOADING FROM, SUBSCRIBING TO, OR USING STORYLECT, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THE FOLLOWING TERMS.  YOU MAY NOT USE THE SERVICE AND ACCESS THE SITE IN ANY MANNER IF YOU DO NOT AGREE WITH THESE TERMS. STORYLECT RESERVES THE RIGHT TO CHANGE THESE TERMS AT ANY TIME BY PROVIDING NOTICE TO YOU OF SUCH CHANGES ON STORYLECT.COM, VIA EMAIL, AND/OR BY SOME OTHER MEANS. AFTER STORYLECT’S PROVISION OF SUCH NOTICE, YOU MUST AGREE TO THE NEW TERMS; OR OTHERWISE STOP USING OUR SERVICE IMMEDIATELY.
            </Typography>

            <Typography variant="h5" gutterBottom>ARBITRATION</Typography>
            <Typography variant="body2" paragraph>ALL DISPUTES BETWEEN YOU AND STORYLECT WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND NOT IN COURT. YOU ARE WAIVING YOUR RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.
            You agree that your assent, given electronically, will have the same legal effect as if it had been personally signed by You. To the extent permitted by law, these Terms of Use are intended to supersede any provisions of applicable law, which might otherwise limit their enforceability or effect, because they were entered into electronically.
            </Typography>

            <Typography variant="h5" gutterBottom>2. GENERAL</Typography>
            <Typography variant="body2" paragraph>The Service enables Customers to publish content, share it with their audience, set subscription plans and charge subscription fees. The Service also allows anyone to view free content for educational or entertainment purposes. Access to paid content will require a paid subscription.
            From time to time, we may update these Terms to clarify our practices or to reflect new or different practices, such as when we add new features, and Storylect reserves the right in its sole discretion to modify and/or make changes to these Terms of Use at any time. You will be notified electronically or via other available means when we make any change to these Terms of Use.
            Your continued use of the Service after changes become effective shall mean that you accept those changes. You should review the Terms regularly to ensure you are aware of the latest version of the Terms, as any revised Terms shall supersede all previous Terms.
            Storylect may modify the services or discontinue their availability at any time.
            You are solely responsible for all service, telephony, data charges and/or other fees and costs associated with your access to and use of the services, as well as for obtaining and maintaining all telephone, computer hardware, and other equipment required for such access and use.
            If You choose to access or use our services that involve payment of a fee, then you agree to pay, and will be responsible for payment of, that fee and all taxes associated with such access or use. If you provide credit card information to pay for such fees then you hereby represent and warrant that you are authorized to supply such information and hereby authorize Storylect to charge your credit card on a regular basis to pay the fees as they are due.
            If your payment method fails or your account is past due, Your access to paid content will be revoked. We will resume access to paid content providing You provide new form of payment that can be successfully billed.
            All of your use, access and other activities relating to the Services must be in compliance with all applicable laws and regulations, including, without limitations, laws relating to copyright and other intellectual property use, and to privacy and personal identity. You also agree to comply with all applicable laws regarding the transmission of technical data exported from the United States of America or the country in which You reside. You must agree to abide by all local rules regarding online conduct and acceptable content.
            </Typography>

            <Typography variant="h5" gutterBottom>3. ACCOUNT</Typography>
            <Typography variant="body2" paragraph>Your Storylect account will remain active until terminated. As long as your account is active and You agree to these Terms of Use you should be able to use The Services as a publishing entity or a reader. To use the Storylect service you must have Internet access and a compatible device.
            Publishing entity account (the “Publisher”) is identified by having created a publication on Storylect. Having the publication allows Customers to start publishing content on Our platform and charge subscription fees for it. Publishing accounts are allowed to subscribe to other publishers.
            Customers who access the Services for the purpose of education or entertainment can view free content and subscribe to paid content.
            User accounts that become subscribers (the “Subscriber”) will be billed on a recurring basis for the subscription amount. The subscription amount is set by publishing entities and may differ from publisher to publisher.
            </Typography>

            <Typography variant="h5" gutterBottom>4. CONTENT DISCLAIMER</Typography>
            <Typography variant="body2" paragraph>You understand that when using Storylect You will be exposed to content from a variety of sources, and that Storylect is not responsible for the accuracy, usefulness, or intellectual property rights of, or relating to, such content.  You further understand and acknowledge that You may be exposed to content that is inaccurate, offensive, indecent, or otherwise objectionable, and You agree to waive, and hereby do waive, any legal or equitable rights or remedies You have or may have against Storylect with respect thereto.  Storylect does not endorse any content or any opinion, recommendation, or advice expressed therein, and expressly disclaims any and all liability in connection with such content. If notified of content that allegedly does not conform to these Terms, Storylect may investigate the allegation and determine in its sole discretion whether to remove the content, which it reserves the right to do at any time and without notice. Storylect does not permit copyright infringing activities.
            </Typography>

            <Typography variant="h5" gutterBottom>5. PAYMENTS</Typography>
            <Typography variant="body2" gutterBottom><b>Billing Cycle.</b> The subscription fee for the content the Subscriber is subscribing to will be billed on a recurring basis for the amount set by Publisher. You must provide us with a current, valid, accepted payment method, which You may update from time to time (“Payment Method”). Your Payment Method will be billed on the calendar day corresponding to the commencement of your paid subscription. In some cases, the timing of your billing may change, for example if your Payment Method has not successfully settled or if your subscription began on a day not contained in a given month.
            We use a third-party payment processor (the “Third-party Payment Processor”, The Payment Processor) to process payment transactions. The subscription fee is charged directly from paying subscriber’s account and transferred directly to Publisher's account. STORYLECT WITHHOLDS SERVICE FEE (the “Revenue Share”) FROM EACH SUCCESSFUL TRANSACTION.
            The Third-party Payment Processor charges a transactional fee for each successful transaction. This fee is deducted from the subscription fee directly by The Payment Processor.
            The Third-party Payment Processor will pay the Revenue Share directly to Storylect. ALL REVENUE SHARE PAYMENTS ARE NON-REFUNDABLE BY STORYLECT. In case of a payment dispute (or any other dispute) between Publisher and Subscriber, you agree that Storylect is under no obligation to become involved and that Storylect will not be held liable for any such dispute. ANY AND ALL OBLIGATIONS TO THE SUBSCRIBERS, INCLUDING ANY REFUND OBLIGATIONS FOR THE REMAINING PORTION OF THE SUBSCRIPTION TERM, ARE SOLELY PUBLISHER’s RESPONSIBILITY. STORYLECT WILL NOT ISSUE ANY REFUNDS TO THE PUBLISHER OR THE SUBSCRIBER AND IS ENTITLED TO KEEP ANY AND ALL PORTIONS OF REVENUE SHARE YOU HAVE PAID TO STORYLECT.
            </Typography>
            <Typography variant="body2" gutterBottom><b>Payment Methods.</b> If payment is not successful, due to expiration, insufficient funds, or any other reason, and you do not change your Payment Method, we reserve the right to suspend your access to the Service until we have obtained a valid Payment Method. After Payment Method update You authorize us to continue billing the Payment Method on a regular basis according to your selected subscription plan. This may result in a change to your payment billing dates. For certain Payment Methods, the issuer of your Payment Method may add extra charges such as foreign transaction fee, currency conversion fee or other charges. Storylect is not responsible for such charges and will not refund any portion of such charges. Check with your Payment Method issuer for details on extra fees(if any).
            </Typography>
            <Typography variant="body2" gutterBottom><b>Account termination.</b> You can fully terminate your Storylect account at any time by emailing us at <Link href="mailto:support@storylect.com" target="_blank">support@storylect.com</Link>. Account termination does not entitle you to a refund. After your account is terminated you will not be able to use The Services unless you choose to create a new account.
            </Typography>
            <Typography variant="body2" gutterBottom><b>Subscription fees.</b> Subscription fees are set by Publishers and collected by Storylect at the time when the Subscriber chooses to subscribe to a publication. By subscribing the Subscriber agrees to pay a subscription fee on a recurring basis and authorizes Storylect to collect such payment using the Payment Method on file. Upon successful transaction completion we disburse funds to the Publisher’s bank
            account minus appicable fees. The subscription fee is not refundable by Storylect. All financial dispute matters are between a subscriber and an owner of the publication the subscriber is subscribed to.
            </Typography>
            <Typography variant="body2" gutterBottom><b>Cancellations and Refunds.</b> If you purchase a subscription to a publication that automatically renews, you may cancel the subscription any time before the end of the current billing period and the cancellation will take effect on the next billing period. You will retain access to the publication from the time you cancel until the end of the current billing period. You will not receive a refund or credit for any remaining days in your current billing period. Subscription fees paid are final and non-refundable unless otherwise determined by Storylect. If Storylect reasonably determines that a user has violated these Terms, Storylect may immediately terminate the subscription and the user’s access to one or all publications without notice and without refund. All payments made on Storylect are final and non-refundable. You agree to these payments at the time of subscribing and further agree that these payments are non-refundable. We are under no obligation to issue any refunds to the Subscribers or Publishers and we are entitled to keep any and all portions of any payments made on Storylect.
            </Typography>
            <Typography gutterBottom>
            For all payment related inquiries, please contact us at: <Link href="mailto:support@storylect.com" target="_blank">support@storylect.com</Link>
            </Typography>

            <Typography variant="h5" gutterBottom>6. PASSWORDS & ACCOUNT ACCESS</Typography>
            <Typography variant="body2" paragraph>The Customer who created the Storylect account and whose Payment Method is charged (the “Account Owner”) has access and control over the Storylect account. To maintain control over the account and to prevent anyone from accessing the account, the Account Owner should not reveal the password nor the Payment Method details associated with the account to anyone. You are responsible for updating and maintaining the accuracy of the information you provide to us relating to your account.
            You should be mindful of any communication requesting that you submit credit card or other account information. Providing your information in response to these types of communications can result in identity theft. Always access your sensitive account information by going directly to the Storylect website and not through a hyperlink in an email or any other electronic communication, even if it looks official. We can terminate your account or place your account on hold in order to protect you, Storylect or our partners from identity theft or other fraudulent activity.
            </Typography>

            <Typography variant="h5" gutterBottom>7. CUSTOMER WARRANTIES, RIGHTS, REPRESENTATIONS, RESTRICTIONS, AND OBLIGATIONS.</Typography>
            <Typography variant="body2" paragraph>Subject to these Terms of Use, Customer will be granted a limited, non-exclusive, revocable, non-transferable, and non-sublicensable right to access that portion of Our Service applicable to Your Subscription type. By agreeing to grant such access, the Company does not obligate itself to maintain the Website, or to maintain it in its present form. The Company may upgrade, modify, change, or enhance the Services and convert a Customer to a new version thereof at any time in its sole discretion, to the extent that this is not detrimental to Customer’s use of the Services and on reasonable prior notice to Customer (unless the change is of critical business importance or outside the Company’s control, in which case the Company will explain the reason for the changes as soon as is reasonably practicable).
            Customer agrees to abide by any rules or regulations that the Company publishes with respect to the conduct of Customers according to rules and regulations are hereby incorporated into these Terms of Use by this reference. The Company reserves the right to deny a Customer access to Our Service if, in the Company’s sole discretion, Customer has failed to abide by these Terms of Use or appear likely to do so.
            Customer accepts that the Company in its sole discretion may, but has no obligation to, monitor the Services or any portion thereof, and/or to oversee compliance with these Terms of Use.
            Customer promises, acknowledges, and agrees on behalf of her(him)self that:
            Access privileges may not be transferred to any third-parties;
            It will not access, store, distribute or transmit any Viruses via computer networks;
            It will comply with all applicable laws and regulations with respect to use of the Services;
            It will not rent, lease, sublicense, re-sell, distribute, transfer, copy or modify the Services or any component thereof;
            It will not translate, decompile, or create or attempt to create, by reverse engineering or otherwise, the source code from the object code made available hereunder;
            It will not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit the Services or any portion thereof;
            It will not delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Website or contained in the Services;
            It is solely responsible for acquiring, installing, operating and maintaining the hardware and software environment, network connections, and telecommunication links, necessary to access and use the Services;
            It will not use the Services in any manner, or in connection with any content, data, hardware, software or other materials that infringes upon or violates any patent, copyright, trade secret, trademark, or other intellectual property right of any third party, or that constitutes a defamation, libel, invasion of privacy, or violation of any right of publicity or other third-party right, or that is threatening, harassing or malicious.
            </Typography>

            <Typography variant="h5" gutterBottom>8. AVAILABILITY OF WEBSITE</Typography>
            <Typography variant="body2" paragraph>Customer recognizes that the traffic of data through the Internet may cause delays during the download of information from the Website and accordingly, it shall not hold the Company liable for delays that are ordinary in the course of Internet use. Customer further acknowledges and accepts that the Website will not be available on a continual twenty-four-hour basis due to such delays, or delays caused by the Company’s upgrading, modification, or standard maintenance of the Website.
            </Typography>

            <Typography variant="h5" gutterBottom>9. THIRD-PARTY LINKS OR INFORMATION</Typography>
            <Typography variant="body2" paragraph>This Website may contain links to other websites that are not operated by or related to the Company. The Company is not responsible for the content, accuracy or opinions expressed in such third-party websites, and does not investigate, monitor, or check these websites for accuracy or completeness. The inclusion of any linked website on this Website does not imply approval or endorsement of the linked website by the Company. A Customer that leaves this Website to access these third-party sites does so at its own risk.
            </Typography>

            <Typography variant="h5" gutterBottom>10. DISCLAIMERS OF STATEMENTS/WARRANTIES</Typography>
            <Typography variant="body2" paragraph>CUSTOMER’S USE OF THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES IS AT ITS OWN RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY STATEMENTS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY STATEMENT, WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY PROMISES THAT THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES OR ANY PORTION THEREOF, WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET Customer’S NEEDS OR EXPECTATIONS. COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. NO VERBAL OR WRITTEN REPRESENTATIONS, INFORMATION OR ADVICE GIVEN BY THE COMPANY OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY OR IN ANY WAY INCREASE THE SCOPE OF THIS WARRANTY.
            </Typography>

            <Typography variant="h5" gutterBottom>11. LIMITATION OF LIABILITY</Typography>
            <Typography variant="body2" paragraph>COMPANY DOES NOT IN ANY WAY EXCLUDE OR LIMIT ITS LIABILITY FOR (I) DEATH OR PERSONAL INJURY CAUSED BY ITS NEGLIGENCE; (II) FRAUD OR FRAUDULENT MISREPRESENTATION; OR (III) ANY OTHER MATTER FOR WHICH IT WOULD BE ILLEGAL FOR COMPANY TO EXCLUDE OR ATTEMPT TO EXCLUDE ITS LIABILITY.
            THIS SECTION APPLIES TO MULTI-USER SUBSCRIPTIONS: IN NO EVENT SHALL COMPANY, ITS LICENSORS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE TO Customer OR ANY THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF USE, LOSS OF DATA, OR LOSS OF PROFITS, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE SERVICES OR OF ANY WEB SITE REFERENCED OR LINKED TO FROM THE SERVICES. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THE FOREGOING, Customer’S EXCLUSIVE REMEDY AND COMPANY’S ENTIRE LIABILITY, IF ANY, FOR ANY CLAIMS ARISING OUT OF THESE TERMS OF USE, SHALL BE LIMITED TO THE LESSER OF (I) THE AMOUNT PAID BY Customer TO COMPANY IN SUBSCRIPTION FEES DURING THE TWO (2) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE ON WHICH THE CLAIM OR ACTION AROSE OR (II) TWO HUNDRED DOLLARS ($200), REGARDLESS OF WHETHER THE CLAIM OR ACTION IS BASED ON CONTRACT, TORT, WARRANTY, INDEMNIFICATION OR OTHERWISE. THE EXISTENCE OF MULTIPLE CLAIMS WILL NOT ENLARGE THIS LIMIT. Customer WILL BE RESPONSIBLE FOR ALL CLAIMS AND DAMAGES RESULTING FROM THE MISUSE OF THE SERVICES BY Customer AND/OR ITS AUTHORIZED END USERS.
            THIS SECTION APPLIES TO INDIVIDUAL Customer RESIDENTS OF A EUROPEAN UNION MEMBER COUNTRY: IN NO EVENT WILL COMPANY, ITS LICENSORS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL LOSSES (WHERE CONSEQUENTIAL LOSSES MEANS LOSS ARISING AS A SIDE EFFECT OF THE MAIN LOSS), WHICH ARE NOT A REASONABLY FORESEEABLE CONSEQUENCE OF SUCH MAIN LOSS OR DAMAGE, INCLUDING BUT NOT LIMITED TO (I) LOSS OF INCOME OR REVENUE; (II) LOSS OF BUSINESS; (III) LOSS OF PROFITS; (IV) LOSS OF ANTICIPATED SAVINGS; OR (V) LOSS OF DATA.
            </Typography>

            <Typography variant="h5" gutterBottom>12. INDEMNITY</Typography>
            <Typography variant="body2" paragraph>Customer agrees to compensate and defend fully the Company, its officers, employees, agents, successors, and assigns, from and against any damages, losses, and expenses (including reasonable attorneys’ fees) resulting from any third-party claim, action or demand arising out of any breach by Customer of any representation, warranty, covenant, obligation or duty of Customer under this Agreement.
            </Typography>

            <Typography variant="h5" gutterBottom>13. SURVIVAL</Typography>
            <Typography variant="body2" paragraph>All provisions relating to proprietary rights, payment of fees, confidentiality, disclaimer of warranty, indemnification, and limitation of liability, shall survive the expiration or earlier termination of these Terms of Use.
            </Typography>

            <Typography variant="h5" gutterBottom>14. CHANGES</Typography>
            <Typography variant="body2" paragraph>Except, for any provisions determining the primary contractual obligations of Customer and Company hereunder, Company has the right to revise and amend these Terms of Use from time to time to reflect changes in business needs including, but not limited to, changes in features and functionality, changes in market conditions, changes in technology, changes in payment methods, changes in relevant laws and regulatory requirements and changes in system capabilities. Changes are effective immediately upon posting and Customer’s continued use of any of the Services following the posting of revised Terms of Use means that Customer accepts and agrees to the changes, to the extent permitted by applicable laws.
            </Typography>

            <Typography variant="h5" gutterBottom>15. ASSIGNMENT</Typography>
            <Typography variant="body2" paragraph>Customer may not transfer these Terms of Use, in whole or in part. The Company may transfer these Terms of Use and/or subcontract some or all of its obligations hereunder at any time.
            </Typography>

            <Typography variant="h5" gutterBottom>16. INJUNCTION</Typography>
            <Typography variant="body2" paragraph>Customer acknowledges that a breach of any confidentiality or proprietary rights provision of these Terms of Use may cause Company irreparable damage, for which the award of damages would not be adequate compensation. Consequently, Company may seek an injunction to prevent Customer from taking any and all acts in violation of those provisions, which remedy shall be cumulative and not exclusive, and Company may seek the entry of an injunction enjoining any breach or threatened breach of those provisions, in addition to any other relief to which Company may be entitled at law or in equity.
            </Typography>

            <Typography variant="h5" gutterBottom>17. SEVERABILITY</Typography>
            <Typography variant="body2" paragraph>If any provision of these Terms of Use is held by a court of competent jurisdiction to be contrary to law, such provision shall be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law and the remaining provisions of these Terms of Use will remain in full force and effect.
            </Typography>

            <Typography variant="h5" gutterBottom>18. COMPLETE UNDERSTANDING</Typography>
            <Typography variant="body2" paragraph>These Terms of Use, together with the Privacy Policy & Cookie Policy, constitute the sole and entire agreement between Customer and Company with respect to the Services and supersedes all prior and contemporaneous understandings, agreements, representations, warranties or terms and conditions, both written and oral, with respect to the Services.
            </Typography>

            <Typography variant="h5" gutterBottom>19. OTHER LEGAL DOCUMENTS</Typography>
            <Typography variant="body2" paragraph>Please read <Link href="/privacy" target="_blank">Privacy Policy</Link>

            </Typography>

            <Typography variant="h5" gutterBottom>Contact Us</Typography>
            <Typography variant="body2" paragraph>If you have any questions about these Terms, please email us at <Link href="mailto:support@storylect.com" target="_blank">support@storylect.com</Link>
            </Typography>

            </Container>;
          }
      }

      export default withStyles(styles)(Terms);
