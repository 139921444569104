import { DOMSerializer, DOMParser as prosemirrorDom } from "prosemirror-model";
import schema from "../prosemirror/storylect-schema";
import { Node } from "prosemirror-model";

class ProseMirrorHelper {
    public convertContentToHTML(state: any) {
        return DOMSerializer.fromSchema(schema).serializeFragment(state);
    }

    public getHTMLstring(doc: any) {
        const dom = DOMSerializer.fromSchema(schema).serializeFragment(doc);
        const div = document.createElement('div');
        div.style.display = 'none';
        div.appendChild(dom);
        return div.innerHTML;
    }

    public getDOCconverted(html: string) {
        const parsedHTML = new DOMParser().parseFromString(html, "text/html");
        return prosemirrorDom.fromSchema(schema).parse(parsedHTML);
    }
    
    public convertDocToNodes(doc: any) {
        return Node.fromJSON(schema, doc);
    }

    public convertToHtmlFragment = (json: object) => {
        const node = Node.fromJSON(schema, json);
        return DOMSerializer.fromSchema(schema).serializeFragment(node);
    }
}

export const prosemirrorHelper = new ProseMirrorHelper();
