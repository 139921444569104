export interface Plan {
    id: number;
    period: PlanPeriod;
    amount: number;
    currency: string;
    type: PlanType;
}

export interface SubscriberPlan extends Plan {
    planId: number;
    startDate: string;
    endDate: string;
    upcomingPlan: SubscriberPlan;
}

export enum PlanType {
    Original = 1, // original/base price of the plan
    MultipleSubscriptions = 2, // price that subscriber will pay if they are subscribed to 2 or more publications
    Discounted = 3 // temporary discounted price set by the author
}

export enum PlanPeriod {
    Monthly = 1,
    Annual
    //Lifetime
}