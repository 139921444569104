import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { EditorState } from "prosemirror-state";
import { gapCursor } from 'prosemirror-gapcursor';
import { tableEditing, goToNextCell } from 'prosemirror-tables';
import { EditorView } from "prosemirror-view";
import { undo, redo, history } from "prosemirror-history"
import { keymap } from "prosemirror-keymap"
import { splitListItem } from 'prosemirror-schema-list';
import {
    baseKeymap,
    toggleMark,
    chainCommands,
    exitCode
} from "prosemirror-commands";
import { Node } from "prosemirror-model";
import schema from "../../services/prosemirror/storylect-schema";
import menu from '../../services/prosemirror/menu';
import EditorMenu from './EditorMenu';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { postStyles } from '../../services/prosemirror/styles';
import { logger } from '../../services/logging/Logger';

const styles = (theme: Theme) => createStyles({
    root: {
        '& .ProseMirror': {
            minHeight: '300px',
            //border: '1px solid #ccc',
            //marginTop: '15px',
            //padding: theme.spacing(0, 2),
            //borderRadius: theme.shape.borderRadius,
            outline: 'none'
        }
    },
    active: {
        border: '1px solid #ccc',
        padding: '5px',
        margin: '0 5px'
    },
    hidden: {
        display: 'none',
    },

    ...postStyles(theme)
});

interface EditorProps extends WithStyles<typeof styles> {
    initialArticle?: object,
    onMarkdownChange?: (output: object) => void,
}
@observer
class Editor extends React.Component<EditorProps> {
    private el: any;
    @observable public editor: any;
    private newBr = (state: any, dispatch: any) => {
      const br = schema.nodes.hard_break.create()
      dispatch(state.tr.replaceSelectionWith(br).scrollIntoView())
      return true
    }
    private keyMap = {
        ...baseKeymap,
        "Mod-z": undo,
        "Mod-y": redo,
        "Ctrl-z": undo,
        "Ctrl-y": redo,
        'Mod-b': toggleMark(schema.marks.strong),
        'Mod-i': toggleMark(schema.marks.em),
        'Mod-u': toggleMark(schema.marks.underline),
        'Mod-`': toggleMark(schema.marks.code),
        "Tab": goToNextCell(1),
        "Shift-Tab": goToNextCell(-1),
        "Shift-Enter": chainCommands(exitCode, this.newBr),
        "Ctrl-Enter": chainCommands(exitCode, this.newBr),
        "Enter": chainCommands(splitListItem(schema.nodes.list_item), baseKeymap['Enter'])
    }

    public componentDidMount() {
        const initialArticle = this.props.initialArticle;
        let doc = null;
        if (initialArticle) {
            doc = Node.fromJSON(schema, initialArticle);
        }

        const _state = EditorState.create({ doc, schema, plugins: [
          history(),
          keymap(this.keyMap),
          tableEditing(),
          gapCursor(),
        ] });
        this.editor = new EditorView(this.el, {
            state: _state,
            dispatchTransaction: (transaction: any) => {
                try {
                    const { state, transactions } = this.editor.state.applyTransaction(transaction);
                    this.editor.updateState(state);
                    if (transactions.some((tr: any) => tr.docChanged)) {
                        if (this.props.onMarkdownChange) {
                            this.props.onMarkdownChange(state.doc.toJSON());
                        }
                    }
                    this.forceUpdate();
                }
                catch (error) {
                    logger.exception(error);
                }
            }
        });
        this.editor.dom.classList.add(this.props.classes.post);
        this.editor.dom.focus();
    }
    public render() {
        const { classes } = this.props;
        return <Typography component="div" ref={(el: any) => this.el = el} className={classes.root}>
            <EditorMenu menu={menu} view={this.editor} />
        </Typography>;
    }
}


export default withStyles(styles)(Editor);
