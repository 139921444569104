import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Container, Link, Box } from '@material-ui/core';
import NotificationSettings from './NotificationSettings';
import { RouteComponentProps } from 'react-router-dom';
import { auth } from '../../services/auth/Auth';
import { User } from '../../models';
import { UserType } from '../../models/User';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { PageLoader } from '../../shared/PageLoader';
import { UserStore } from '../../stores/UserStore';
import { RootStore } from '../../stores/RootStore';
import { RouterStore } from '../../stores/RouterStore';
import { Link as RouterLink } from 'react-router-dom';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
});

interface MatchParams {
    token: string;
};

interface EmailSettingsProps extends WithStyles<typeof styles>, RouteComponentProps<MatchParams> {
    router: RouterStore;
    userStore: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class EmailSettings extends React.Component<EmailSettingsProps> {
    @observable private user: User = {} as User;
    @observable private loading: boolean = true;
    @observable private error: string = '';

    public render() {
        const { classes } = this.props;

        if (this.loading)
            return <PageLoader />;

        return <Container className={classes.root} maxWidth="md">
            {this.error ?
                <Box>
                    <Typography variant="h6" color="error">{this.error}</Typography>
                    <Link color="inherit" component={RouterLink} to="/" style={{ textDecoration: 'underline' }}>Go Home</Link>
                </Box> :
                <NotificationSettings userId={this.user.id} isWriter={this.user.types?.includes(UserType.Writer)} unsubscribes={this.user.unsubscribes} token={this.props.match.params.token} />
            }
        </Container>;
    }

    public async componentDidMount() {
        if (this.props.userStore.isAuthenticated) {
            this.props.router.replace('/settings/notifications');
        }
        else {
            await this.loadSettings();
        }
    }

    private loadSettings = async () => {
        try {
            let response = await auth.fetch(`/api/users/notification-settings?token=${this.props.match.params.token}`, undefined, true);

            if (response.status === 200) {
                this.user = await response.json() as User;
            }
            else {
                this.error = await response.text();
            }
        }
        catch (error) {
            // handle error!
            console.log(error);
            this.error = 'Error loading email settings. Try again later';
        }
        finally {
            this.loading = false;
        }
    }
}

export default withStyles(styles)(EmailSettings);
