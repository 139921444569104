import * as React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import { Button, Link, Typography } from '@material-ui/core';
import { logger } from './services/logging/Logger';
import ErrorIcon from '@material-ui/icons/Error';

const styles = (theme: Theme) => createStyles({
    root: {
        marginTop: '20%',
        textAlign: 'center'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    title: {
        marginBottom: theme.spacing(2)
    }
});


interface ErrorBoundaryProps extends WithStyles<typeof styles> {

}


interface ErrorBoundaryState {
    eventId: string;
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { 
            eventId: '',
            hasError: false
        };
    }

    public static getDerivedStateFromError() {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = logger.exception(error);
            this.setState({eventId});
        });
    }

    public render() {
        if (this.state.hasError) {
            const { classes } = this.props;

            //render fallback UI
            return <div className={classes.root}>
                <ErrorIcon color="error" style={{ fontSize: 60 }} />
                <div className={classes.title}>
                    <Typography variant="h5">Well, that's embarrassing...</Typography>
                    <Typography>Any feedback on what happened would help us tremendously!</Typography>
                </div>
                <Button className={classes.button} variant="contained" size="large" color="primary" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</Button>
                <Link color="inherit" href="\" style={{ textDecoration: 'underline' }}>Go Home</Link>
            </div>;
        }

        //when there's not an error, render children untouched
        return this.props.children;
    }
}

export default withStyles(styles)(ErrorBoundary);