import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import ShareIcon from '@material-ui/icons/Share';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import EmailIcon from '@material-ui/icons/Email';
import { GA } from '../helpers/ga';

const styles = (theme: Theme) => createStyles({
    vertical: {
        position: 'absolute'
    },
    horizontal: {

    },
    fab: {
        boxShadow: 'none'
    }
});

interface ShareProps extends WithStyles<typeof styles> {
    direction?: 'up' | 'down' | 'left' | 'right';
    tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
    gaCategory: string;
    gaLabel: string;
}

@observer
class Share extends React.Component<ShareProps> {
    @observable private open: boolean = false;

    private actions = [
        { icon: <FacebookIcon />, name: 'Facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}` },
        { icon: <TwitterIcon />, name: 'Twitter', url: `https://twitter.com/intent/tweet?url=${window.location.href}` },
        { icon: <LinkedInIcon />, name: 'LinkedIn', url: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}` }
        // { icon: <EmailIcon />, name: 'Email', url: `mailto:?subject=&body=${window.location.href}` }
    ];

    public render() {
        const { classes, direction, tooltipPlacement } = this.props;

        // const className = (direction === 'left' || direction === 'right') ? classes.horizontal : classes.vertical;

        return <SpeedDial ariaLabel="Share" icon={<ShareIcon fontSize="small" />} direction={direction || 'down'} className={classes.vertical}
                    open={this.open} onOpen={this.handleOpen} onClose={this.handleClose}
                    FabProps={{ size: 'small', color: 'inherit', variant: 'extended', className: classes.fab }}>
            {this.actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipPlacement={tooltipPlacement || 'left'}
                    onClick={this.share(action.url, action.name)}
                />
            ))}
        </SpeedDial>;
    }

    private handleOpen = () => this.open = true;
    private handleClose = () => this.open = false;

    private share = (url: string, name: string) => () => {
        const { gaCategory, gaLabel } = this.props;

        GA.event(gaCategory, name, gaLabel);

        this.handleClose();
        window.open(url, '_blank');
    }
}

export default withStyles(styles)(Share);
