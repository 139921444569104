import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Container, ListItem, Link } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
});

interface PrivacyProps extends WithStyles<typeof styles> {

}

class Privacy extends React.Component<PrivacyProps> {
    public render() {

        const { classes } = this.props;

        return <Container className={classes.root}>
            <Typography variant="h4" gutterBottom>
                Privacy Policy
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
                Effective date: Oct 26th, 2021
            </Typography>

            <Typography variant="body2" paragraph>
                Storylect and its partners and affiliates (collectively, “Storylect,” the “Company,” the “Service”, “we” or “us”) are sensitive to privacy issues, and it is important to us to protect the information provided to us by our users (“you”). Storylect provides this privacy and information practices policy to inform you about our online information practices, the kinds of information we may collect, how we intend to use and share that information, and how you can correct or change such information (“Privacy Policy”).  By using the Storylect.com, you explicitly consent to the information handling practices described in this notice.
            </Typography>
            <Typography variant="body2" paragraph>
                This Privacy Policy applies to personally identifiable information ("Personal Information") that is gathered and used by Storylect in the course of our business.  All individuals whose responsibilities include the processing of Personal Information on behalf of Storylect are expected to protect that data by adherence to this Privacy Policy. This Privacy Policy is intended to meet requirements globally, including those in North America, Europe, APAC, and other jurisdictions.
            </Typography>
            <Typography variant="body2" paragraph>
                This Privacy Policy is incorporated into and is subject to the Terms of Use. Your use of Storylect and any Personal Information provided by you through Storylect are subject at all times to this Privacy Policy and the Terms of use.
            </Typography>

            <Typography variant="h5" gutterBottom>Collecting and using your Personal Data</Typography>

            <Typography variant="h6" gutterBottom>Types of Data Collected</Typography>
            <Typography variant="subtitle1" gutterBottom>Personal Data</Typography>
            <Typography variant="body2" paragraph>
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to: Email Address and Usage Data
            </Typography>
            <Typography variant="subtitle1" gutterBottom>Usage Data</Typography>
            <Typography variant="body2" paragraph>
                Usage Data is collected automatically when using the Service.
                Usage Data may include information such as your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                When you access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
                We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>Tracking Technologies and Cookies</Typography>
            <Typography variant="body2" paragraph>
                We use Cookies and similar tracking technologies to track the activity on our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
                You can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our Service.
                For more information about the cookies we use and your choices regarding cookies, please contact us via email.
            </Typography>

            <Typography variant="h6" gutterBottom>Use of your Personal Data</Typography>
            <Typography variant="body2" paragraph>
                The Company may use Personal Information for the following purposes:
                To provide and maintain our Service, including monitoring our Service usage.
                To manage your Account: to manage your registration as a user of the Service. The Personal Information you provide can give you access to different functionalities of the Service that are available to you as a registered user.
                We use other companies’ services and people to perform tasks on our behalf and need to share your Personal Information with them to provide products or services to you( e.g. we may use a Third Party Payment Processor to receive and process your credit card transactions, such as Stripe)
                To contact you: To contact you by email for the purpose of sending updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
                To provide you: with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted out not to receive such information.
                To manage your requests: To attend and manage your requests to us.
                We may share your personal information in the following situations:
                With Service Providers: We may share your personal information with Service Providers to monitor and analyze the use of our Service
                For Business transfers: We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.
                With Affiliates: We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
                With Business partners: We may share your information with our business partners to offer you certain products, services or promotions.
                With other users: when you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If you interact with other users or register through a Third-Party Social Media Service, your contacts on the Third-Party Social Media Service may see your name, profile, pictures and description of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you and view your profile.
            </Typography>

            <Typography variant="h6" gutterBottom>Retention of your Personal Data</Typography>
            <Typography variant="body2" paragraph>
                The Company will retain your Personal Information only for as long as necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Information to the extent necessary to comply with our legal obligations (for example, if required by law), resolve disputes, and enforce our legal agreements and policies.
                The Company will also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
            </Typography>

            <Typography variant="h6" gutterBottom>Transfer of your Personal Data</Typography>
            <Typography variant="body2" paragraph>
                Your information, including Personal Information, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                The Company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Information will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
            </Typography>

            <Typography variant="h6" gutterBottom>Disclosure of your Personal Data</Typography>
            <Typography variant="subtitle1" gutterBottom>Business Transactions</Typography>
            <Typography variant="body2" paragraph>
                If the Company is involved in a merger, acquisition or asset sale, your Personal Information may be transferred. We will provide notice before your Personal Information is transferred and becomes subject to a different Privacy Policy.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>Law enforcement</Typography>
            <Typography variant="body2" paragraph>
                Under certain circumstances, the Company may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
            </Typography>
            <Typography variant="subtitle1" gutterBottom>Other legal requirements</Typography>
            <Typography variant="body2" paragraph>
                The Company may disclose your Personal Information in the good faith belief that such action is necessary to:
                <ListItem>Comply with a legal obligation</ListItem>
                <ListItem>Protect and defend the rights or property of the Company</ListItem>
                <ListItem>Prevent or investigate possible wrongdoing in connection with the Service</ListItem>
                <ListItem>Protect the personal safety of users of the Service or the public</ListItem>
                <ListItem>Protect against legal liability</ListItem>
            </Typography>

            <Typography variant="h5" gutterBottom>Social Media</Typography>
            <Typography variant="body2" paragraph>
                In general, online social media resources are interactive tools that enable users to collaborate and share information with others.  Social media resources include, but are not limited to, social networks, discussion boards, bulletin boards, blogs, wikis, and referral functions to share web site content and tools with a friend, colleague or client. The Company may collect Personal Information to enable users to use online social media resources offered either by us or a Third Party.  We may also enable you to use these social media resources to post or share Personal Information with others. We will comply with the terms of this Privacy Policy and the privacy policies applicable to the social media resources it uses.
            </Typography>
            <Typography variant="h5" gutterBottom>Google Analytics</Typography>
            <Typography variant="body2" paragraph>
                We may also use Google Analytics and Google Analytics Demographics and Interest Reporting to collect information regarding visitor behavior and visitor demographics on some of our Services, and to develop website content.  This analytics data is not tied to any Personal Information. For more information about Google Analytics,
                please visit <Link href="https://policies.google.com/technologies/partner-sites" target="_blank">policies.google.com/technologies/partner-sites</Link>.
            </Typography>
            <Typography variant="h5" gutterBottom>Security of your Personal Data</Typography>
            <Typography variant="body2" paragraph>
                The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, We cannot guarantee its absolute security.
            </Typography>
            <Typography variant="h5" gutterBottom>Email</Typography>
            <Typography variant="body2" paragraph>
                Users who provide us with Personal Information, or whose Personal Information we obtain from Third Parties, may receive periodic emails or newsletters from us with information on our or our business partners’ products and services or upcoming special offers/events we believe may be of interest to you.
                An “Unsubscribe” option will be provided at the top or bottom of each email sent by Storylect so that you can opt-out.  However, we may continue to send transaction-related emails regarding products or services.  As long as you remain our user we may need to send you certain communications you will not be able to opt out of(e.g. communications regarding updates to our Terms of Use or this Privacy Policy)
            </Typography>
            <Typography variant="h5" gutterBottom>Minors' Privacy</Typography>
            <Typography variant="body2" paragraph>
                Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us their Personal Information, please contact us. If we become aware that we have collected Personal Information from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers.
            </Typography>
            <Typography variant="h5" gutterBottom>Links to Other Websites</Typography>
            <Typography variant="body2" paragraph>
                Our Service may contain links to other websites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
                We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
            </Typography>
            <Typography variant="h5" gutterBottom>Changes to this Privacy Policy</Typography>
            <Typography variant="body2" paragraph>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </Typography>
            <Typography variant="h5" gutterBottom>Contact Us</Typography>
            <Typography variant="body2" paragraph>
                If you have any questions about this Privacy Policy, please email us at <Link href="mailto:support@storylect.com" target="_blank">support@storylect.com</Link>
            </Typography>
        </Container>;
    }
}

export default withStyles(styles)(Privacy);
