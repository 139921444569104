import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme, CssBaseline, Container, withStyles, WithStyles, createStyles, Theme, Typography, Box, Link, IconButton } from '@material-ui/core';
import { Facebook, Twitter, LinkedIn } from '@material-ui/icons';
import Routes from './Routes';
import Navigation from './Navigation';
// import { cyan } from '@material-ui/core/colors';
import ErrorBoundary from './ErrorBoundary';
import { Link as RouterLink } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { inject, observer } from 'mobx-react';
import { observable, when } from 'mobx';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { auth } from './services/auth/Auth';
import { RootStore } from './stores/RootStore';
import { UserStore } from './stores/UserStore';
import { tawkTo } from './helpers/tawkTo';

// declare module "@material-ui/core/styles/createBreakpoints" {
//     interface BreakpointOverrides {
//         mobile: true;
//         tablet: true;
//     }
// }

const themeOverride = createMuiTheme({
    // palette: {
    //   type: 'dark',
    //   background: {
    //       default: '#06151C',
    //       paper: '#122C40'
    //   },
    //   secondary: cyan
    // }
    typography: {
        fontFamily: [
            '"Work Sans"',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(',')
    },
    palette: {
        background: {
            default: '#FAFAFA'
        },
        primary: {
            main: '#2d9cdb'
        },
        secondary: {
            main: '#F15E4A'
        },
        text: {
            primary: '#474E55'
        }
    },
    // [DN] TODO, while you can add custom breakpoints, the support for them is still WIP, Grid and Hidden specifically
    // so just updating existing ones for now, until it's fixed
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 768,
            lg: 960,
            xl: 1280
            // mobile: 480,
            // sm: 600,
            // tablet: 768,
            // md: 960,
            // lg: 1280,
            // xl: 1920
        }
        // these + the one for 4k screens is what I want to have eventually
        // $bp-xxs: 384px; //24em
        // $bp-xs: 480px; //30em;
        // $bp-s: 592px; //37em;
        // $bp-m: 768px; //48em;
        // $bp-l: 960px; //60em;
        // $bp-xl: 1120px; //70em;
        // $bp-xxl: 1440px; //90em;
    },
    overrides: {
        MuiButton: {
            root: {
                whiteSpace: 'nowrap'
            },
            containedPrimary: {
                color: '#FFFFFF'
            },
        },
        MuiLink: {
          root: {
              // fontWeight: 500
          }
        },
    },
    props: {
        MuiButton: {
            disableElevation: false,
        },

        MuiCard: {
            variant: 'outlined'
        },
        MuiPaper: {
            // variant: 'outlined'
        },
        MuiTextField: {
            // variant: 'outlined'
        },
    },
});

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    container: {
        //padding: theme.spacing(3)
        flexGrow: 1
    },
    footer: {
        // marginTop: 'auto',
        padding: theme.spacing(5, 0),
        backgroundColor: theme.palette.grey[100],
        borderTop: `1px solid ${theme.palette.divider}`
    },
    footerLink: {
        margin: theme.spacing(0, 0.5)
    }
});

interface AppProps extends WithStyles<typeof styles> {
    userStore?: UserStore;
}

@inject((stores: RootStore) => ({
    userStore: stores.userStore
}))
@observer
class App extends React.Component<AppProps> {
    @observable private stripePromise: Promise<Stripe | null> | null = null;

    constructor(props: AppProps) {
		super(props);

		//do stuff when user is authenticated and resolved
        when(() => !!props.userStore?.meResolved, this.onMeResolved);
	}
    
    public render() {
        const { classes } = this.props;

        return <ThemeProvider theme={themeOverride}>
            <CssBaseline />
            <ErrorBoundary>
                <div className={classes.root}>
                    <Navigation />
                    <Box component="main" className={classes.container}>
                        <Elements stripe={this.stripePromise}>
                            <Routes />
                        </Elements>
                    </Box>
                    <footer className={classes.footer}>
                        <Container maxWidth="sm">
                            <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" mb={1}>
                                <Link className={classes.footerLink} variant="body1" href="https://storylect.tawk.help" target="_blank">FAQ</Link> |
                                <Link className={classes.footerLink} variant="body1" component={RouterLink} to="/about">About</Link> |
                                <Link className={classes.footerLink} variant="body1" component={RouterLink} to="/blog">Blog</Link>
                            </Box>
                            <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" mb={2}>
                                <Link className={classes.footerLink} variant="body1" component={RouterLink} to="/privacy">Privacy Policy</Link> |
                                <Link className={classes.footerLink} variant="body1" component={RouterLink} to="/terms">Terms of Use</Link>
                            </Box>
                            <Box display="flex" justifyContent="center" mb={3} mt={3}>
                                <Link href="https://www.facebook.com/storylect" target="_blank">
                                    <IconButton aria-label="facebook">
                                        <Facebook />
                                    </IconButton>
                                </Link>
                                <Link href="https://twitter.com/storylect" target="_blank">
                                    <IconButton aria-label="twitter">
                                        <Twitter />
                                    </IconButton>
                                </Link>
                            </Box>
                            <Typography align="center">© {new Date().getFullYear()} Storylect.com</Typography>
                        </Container>
                    </footer>
                </div>
            </ErrorBoundary>
        </ThemeProvider>;
    }

    public async componentDidMount() {
        tawkTo.init();

        try {
			const response = await auth.fetch('/api/config');
			const { stripePublishableKey } = await response.json();
            this.stripePromise = loadStripe(stripePublishableKey);
		}
		catch (error) {
			// handle error!
			console.log(error);
		}
    }

    private onMeResolved = () => {
		const { userStore } = this.props;

        tawkTo.event('logged-in', { id: userStore?.me?.id, name: userStore?.me?.name })

        // set name to user name (if present) and id
        const name = userStore?.me?.name ? `${userStore?.me?.name} (${userStore?.me?.id})` : userStore?.me?.id.toString();
        if (name) {
            tawkTo.setUser(name);
        }
	}
}

export default withStyles(styles)(App);
