import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, TextField, InputAdornment, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Link, CircularProgress, RadioGroup, FormControlLabel, Radio, FormControl, FormHelperText, Chip, Fade } from '@material-ui/core';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ApiMessage, Publication, User } from '../models';
import { PlanPeriod, PlanType, SubscriberPlan } from '../models/Plan';
import { auth } from '../services/auth/Auth';
import { validation } from '../helpers/validation';
import { constants } from '../helpers/constants';
import { CardElement } from '@stripe/react-stripe-js';
import { PaymentMethod, Stripe, StripeCardElementChangeEvent, StripeElements } from '@stripe/stripe-js';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import CheckIcon from '@material-ui/icons/Check';

const styles = (theme: Theme) => createStyles({
    input: {
        paddingRight: 6
    },
    submitting: {
        marginLeft: theme.spacing(1)
    },
    card: {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1.5, 2)
    },
    radioButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    originalPrice: {
        //position: 'relative',
        color: theme.palette.grey[500],
        marginRight: theme.spacing(0.5),
        textDecoration: 'line-through'
        // [DN] below is how we would customize line-through, i.e. make it diagonally
        // '&:before': {
        //     content: '""',
        //     position: 'absolute',
        //     left: 0,
        //     right: 0,
        //     top: '50%',
        //     borderTop: `1px solid ${theme.palette.grey[500]}`,
        //     transform: 'rotate(16deg)'
        // }
    },
    dialogAlert: {
        marginBottom: theme.spacing(2)
    },
    chip: {
        backgroundColor: theme.palette.success.main
    }
});

interface SubscriptionElementContentProps extends WithStyles<typeof styles, true> {
    authed: boolean;
    publication: Publication;
    user: User;
    onSuccess?: (token: string) => Promise<void>;
    onPlanSuccess?: (subscriberPlan: SubscriberPlan) => void;

    stripe: Stripe | null;
    elements: StripeElements | null;
}

@observer
class SubscriptionElementContent extends React.Component<SubscriptionElementContentProps> {
    // TODO: merge errors and message???
    @observable private errors: Map<string, string> = new Map(); // key is field name, value is error message
    @observable private message: ApiMessage = { body: '', isError: false };
    @observable private email: string = '';
    @observable private planId: number = 0;
    @observable private dialogOpen: boolean = false;
    // ugly, separate into 2 components?
    @observable private submitting: boolean = false;
    @observable private submittingPlan: boolean = false;

    // this is only used when subscription was successful - purely for short notification
    @observable private subscriptionConfirmationState: 'show' | 'hide' | 'none' = 'none';

    public render() {
        const { classes, authed, publication, user } = this.props;

        // render subscription confirmation for a coupel of seconds
        if (this.subscriptionConfirmationState !== 'none') {
            return <Fade in={this.subscriptionConfirmationState === 'show'} timeout={{ enter: 200, exit: 500 }} onExited={this.exitConfirmSubscription}>
                <Chip icon={<CheckIcon />} label="Subscribed" color="primary" className={classes.chip} />
            </Fade>;
        }

        // don't render anything if authed user has not loaded yet OR if user is already subscribed
        if (authed && (
            !user?.subscriptions ||
            (!publication.plans.length && user.subscriptions.some(s => s.id === publication.id)) ||
            (publication.plans.length && user.subscriptions.some(s => s.id === publication.id && s.subscriberPlan))
        ))
            return null;

        return <>
            {authed ?
                <>
                    <Button size="small" variant="contained" color="secondary" disabled={this.submitting} onClick={this.handleSubscribe}>
                        Subscrib{this.submitting ? 'ing' : 'e'}
                        {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                    </Button>
                    <Typography variant="caption" component="div" color={this.message.isError ? 'error' : 'textPrimary'}>{this.message.body}</Typography>
                </> :
                <Box minWidth={300}>
                    <TextField size="small" error={this.message.isError} variant="outlined" label="Enter Email" value={this.email} helperText={this.message.body}
                        onChange={this.handleChange} onKeyDown={this.handleKeyDown} disabled={this.submitting}
                        InputProps={{
                            className: classes.input,
                            endAdornment: <InputAdornment position="end">
                                <Button size="small" variant="contained" color="secondary" disabled={this.submitting} onClick={this.handleSubscribe}>
                                    Subscrib{this.submitting ? 'ing' : 'e'}
                                    {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                                </Button>
                            </InputAdornment>
                        }}
                    />
                    <Typography variant="caption" component="div">By subscribing, you agree to our <Link href="/privacy" target="_blank">Privacy Policy</Link> and <Link href="/terms" target="_blank">Terms</Link></Typography>
                </Box>
            }
            {publication.plans.length > 0 && this.renderDialog()}
        </>;
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.email = e.target.value;

        if (this.email.trim()) {
            this.message = { body: '', isError: false };
        }
    }

    private handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            await this.handleSubscribe();
        }
    }

    private handleSubscribe = async () => {
        const { authed, publication, user } = this.props;

        // if this is a new user or existing user is not subscribed yet, subscribe to free plan
        if (!authed || !user.subscriptions.some(s => s.id === publication.id)) {
            await this.subscribe();
        }

        // if there is no error and publication offers paid plans, show the dialog
        if (!this.message.isError && publication.plans.length > 0) {
            this.setDefaultPlan(); // doing it here instead of constructor or other places makes sure that user is already set
            this.openDialog();
        }
    }

    private setDefaultPlan = () => {
        const { publication, user, authed } = this.props;

        // if already set, disregard
        if (this.planId > 0)
            return;

        // see if user is already a paying subscriber (i.e. has subscription with subscriberPlan in it), make sure at least one paid plan is not cancelled (endDate is null)
        const isPayingSubscriber = authed && user.subscriptions.some(s => s.subscriberPlan && (!s.subscriberPlan.endDate || s.subscriberPlan.upcomingPlan));
        const defaultPlans = _(publication.plans).orderBy(p => p.period).groupBy(p => p.period).values().first();
        const defaultPlan = defaultPlans?.find(p => p.type === (
            (isPayingSubscriber && defaultPlans?.some(p => p.type === PlanType.MultipleSubscriptions)) 
                ? PlanType.MultipleSubscriptions : 
                PlanType.Original
        ));

        this.planId = defaultPlan?.id || 0;
    }

    private subscribe = async () => {
        this.message = { body: '', isError: false };

        this.email = this.email.trim();
        if (!this.props.authed && !validation.isValidEmail(this.email)) {
            this.message = { body: 'Email is invalid', isError: true };
            return;
        }

        this.submitting = true;

        try {
            var response = await auth.fetch(`/api/publications/${this.props.publication.id}/subscribe`, {
                method: 'POST',
                body: JSON.stringify({ email: this.email })
            });

            // use auth.isSuccessfulStatus instead?
            if (response.status === 200) {
                const { token } = await response.json();

                // show confirmation to user if it's a free publication
                if (!this.props.publication.plans.length) {
                    this.confirmSubscription();
                }

                if (this.props.onSuccess) {
                    await this.props.onSuccess(token);
                }

            }
            else {
                this.message = { body: response.status === 500 ? 'network error' : await response.text(), isError: true };
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.message = { body: 'network error', isError: true };
        }
        finally {
            this.submitting = false;
        }
    }

    private renderDialog = () => {
        const { theme, classes, user, publication } = this.props;

        const cardElementOptions = {
            style: {
                base: {
                    color: theme.palette.text.primary,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '16px',
                },
                invalid: {
                    color: theme.palette.error.main,
                    iconColor: theme.palette.error.main
                }
            }
        };

        const plan = publication.plans.find(p => p.id === this.planId);

        return <Dialog open={this.dialogOpen} onClose={this.closeDialog} maxWidth="xs">
            <DialogTitle>Pick subscription plan</DialogTitle>
            <DialogContent>
                <Alert severity="info" className={classes.dialogAlert}>You are subscribed to a free plan that gives you access to occasional free content</Alert>
                <Typography variant="body2">To get full access, select one of the following options:</Typography>
                <FormControl error={this.errors.has('plan')} margin="normal">
                    <RadioGroup value={this.planId} onChange={this.onPlanChange}>
                        {this.renderPlans()}
                    </RadioGroup>
                    <FormHelperText>{this.errors.get('plan')}</FormHelperText>
                </FormControl>
                <Box>
                    <Typography variant="caption" paragraph>You will be charged {constants.currencies.find(c => c.code === plan?.currency)?.symbol}{plan?.amount} for the first {plan?.period === PlanPeriod.Annual ? 'year' : 'month'} and your subscription will automatically renew on {plan?.period === PlanPeriod.Annual ? 'an annual' : 'a monthly'} basis until you cancel. You can cancel at any time. <Link underline="always" href="https://storylect.tawk.help/article/cancellations-and-refunds" target="_blank">Cancellations take effect at the end of your current billing period and all previously paid fees are non-refundable</Link>.</Typography>
                    {user.cardLast4 ?
                        <TextField size="small" label="Your Card" defaultValue={`**** **** **** ${user.cardLast4}`} fullWidth margin="dense" disabled variant="outlined" /> :
                        <CardElement options={cardElementOptions} className={classes.card} onChange={this.onStripeChange} />
                    }
                    {this.errors.has('stripe') && <Typography color="error" variant="body2">{this.errors.get('stripe')}</Typography>}
                </Box>
                {this.errors.has('api') && <Typography color="error" variant="body2">{this.errors.get('api')}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.closeDialog} color="primary">Cancel</Button>
                <Button variant="contained" color="primary" disabled={this.submittingPlan} onClick={this.planSubscribe}>
                    Subscrib{this.submittingPlan ? 'ing' : 'e'}
                    {this.submittingPlan && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private renderPlans = () => {
        const { classes, user, publication, authed } = this.props;

        // see if user is already a paying subscriber (i.e. has subscription with subscriberPlan in it), make sure at least one paid plan is not cancelled (endDate is null)
        const isPayingSubscriber = authed && user.subscriptions.some(s => s.subscriberPlan && (!s.subscriberPlan.endDate || s.subscriberPlan.upcomingPlan));

        // not sure if order is preserved when grouping (hopefully it is)
        return _(publication.plans).orderBy(p => p.period).groupBy(p => p.period).map((plansPerPeriod, planPeriod) => {
            let period = 'month';
            if (+planPeriod === PlanPeriod.Annual) {
                period = 'year';
            }

            const orginalPlan = plansPerPeriod.find(p => p.type === PlanType.Original);
            const multiplePlan = plansPerPeriod.find(p => p.type === PlanType.MultipleSubscriptions);
            const currentPlan = (isPayingSubscriber && multiplePlan) ? multiplePlan : orginalPlan;

            return <FormControlLabel key={currentPlan?.id} value={currentPlan?.id} control={<Radio style={{ paddingTop: 0, paddingBottom: 0 }} />} className={classes.radioButton}
                label={<Typography>
                    {currentPlan?.type === PlanType.MultipleSubscriptions &&
                        <span className={classes.originalPrice}>{constants.currencies.find(c => c.code === orginalPlan?.currency)?.symbol}{orginalPlan?.amount}</span>
                    }
                    {constants.currencies.find(c => c.code === currentPlan?.currency)?.symbol}{currentPlan?.amount}/{period}
                    {currentPlan?.type === PlanType.MultipleSubscriptions && <Typography variant="caption" color="textSecondary" display="block">Discounted price for being a paying subscriber</Typography>}
                </Typography>}
            />;

        }).value();
    }

    private openDialog = () => this.dialogOpen = true;
    private closeDialog = () => this.dialogOpen = false;

    private onPlanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.planId = +e.target.value;
        this.errors.delete('plan');
    }

    private onStripeChange = (e: StripeCardElementChangeEvent) => {
        console.log(e);
        if (e.error) {
            this.errors.set('stripe', e.error.message);
        }
        else {
            this.errors.delete('stripe');
        }
    }

    private planSubscribe = async () => {
        const { stripe, elements, user } = this.props;

        this.errors.clear();

        if (!this.planId || this.planId <= 0) {
            this.errors.set('plan', 'Plan is invalid');
            return;
        }

        let paymentMethod: PaymentMethod | undefined;
        if (!user.cardLast4) {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                this.errors.set('stripe', 'Error with payment processor. Try again later.');
                return;
            }

            // create payment method in Stripe
            const cardElement = elements.getElement(CardElement)!;
            const pmResult = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement
            });

            // see if there is a Stripe error
            if (pmResult.error) {
                this.errors.set('stripe', pmResult.error.message!);
                return;
            }

            paymentMethod = pmResult.paymentMethod;
        }

        // all good, let's subscribe user to the paid plan!
        this.submittingPlan = true;

        try {
            var response = await auth.fetch(`/api/publications/${this.props.publication.id}/plansubscribe`, {
                method: 'POST',
                body: JSON.stringify({
                    stripePaymentMethodId: paymentMethod?.id,
                    cardLast4: paymentMethod?.card?.last4,
                    planId: this.planId
                })
            });

            // use auth.isSuccessfulStatus instead?
            if (response.status === 200) {
                const subscriberPlan = await response.json() as SubscriberPlan;

                this.confirmSubscription();

                if (this.props.onPlanSuccess)
                    this.props.onPlanSuccess(subscriberPlan);
            }
            else {
                this.errors.set('api', await response.text());
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.errors.set('api', 'Error subscribing to plan. Try again later');
        }
        finally {
            this.submittingPlan = false;
        }
    }

    private confirmSubscription = () => {
        this.subscriptionConfirmationState = 'show';
        window.setTimeout(() => this.subscriptionConfirmationState = 'hide', 3000);
    }
    private exitConfirmSubscription = () => {
        this.subscriptionConfirmationState = 'none';
    }
}

export default withStyles(styles, { withTheme: true })(SubscriptionElementContent);