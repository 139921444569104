import Paper from '@material-ui/core/Paper';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { observer, inject } from 'mobx-react';
import * as React from 'react';
import { observable } from 'mobx';
import { Container, Link } from '@material-ui/core';
import { RootStore } from '../../stores/RootStore';
import { RouterStore } from '../../stores/RouterStore';
import { auth } from '../../services/auth/Auth';
import { UserStore } from '../../stores/UserStore';
import { ApiMessage } from '../../models';
import { Link as RouterLink } from 'react-router-dom';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 3, 3)
    }
});

interface EmailVerificationProps extends WithStyles<typeof styles> {
    router: RouterStore;
    userStore: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class EmailVerification extends React.Component<EmailVerificationProps> {
    @observable private message: ApiMessage = { body: 'Verifying...', isError: false };

    public render() {
        const { classes } = this.props;

        return <Container className={classes.root} maxWidth='sm'>
            <Paper className={classes.paper}>
                <Typography variant="h6" color={this.message.isError ? 'error' : 'textPrimary'} paragraph>{this.message.body}</Typography>
                {this.message.isError &&
                    <Typography>Please visit your <Link component={RouterLink} to="/settings">account settings</Link> and resend the link again.</Typography>
                }
            </Paper>
        </Container>;
    }

    public async componentDidMount() {
        const { userStore, router } = this.props;

        try {
            const params = new URLSearchParams(router.location.search);
            const token = params.get('token');
            if (!!token) {
                const redirect = params.get('redirect') || '/settings';

                const response = await auth.verifyLogin(token);

                if (response.status === 200) {
                    // authenticating (no need to save token as it's already saved with auth.verifyLogin)
                    userStore.authenticate();
                    router.replace(redirect);
                }
                else {
                    //const error = await response.text();
                    this.message = { body: 'Verification Link has expired', isError: true };
                }
            }
            else {
                this.message = { body: 'Verification Link is invalid', isError: true };
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.message = { body: 'Email Verification Failed', isError: true };
        }
    }
}

export default withStyles(styles)(EmailVerification);
