import { History } from 'history';
import { RouterStore as BaseRouterStore, syncHistoryWithStore } from 'mobx-react-router';

export class RouterStore extends BaseRouterStore {
    // @observable public isAuthenticated: boolean = false;
    
    constructor(history: History) {
        super();
        this.history = syncHistoryWithStore(history, this);

        // [DN] [TODO]? not sure if it's the right way but I need it for Navigation.tsx or any component that is outside of router
        // it observes changes to the location/history and updates this.isAuthenticated
        // [DN] update 8/27/20: obsolete, commenting this out just for future reference on how to use reaction (use userStore.isAuthenticated instead)
        //reaction(() => ({ url: this.location.pathname }), (data) => this.isAuthenticated = auth.isLoggedIn, { fireImmediately: true });
    }
}