import * as React from 'react';
import { Container, Box, Typography,  Paper, TextField, Button, Divider, CircularProgress, FormControlLabel, Checkbox } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
// import { RouterStore } from '../../stores/RouterStore';
import { User } from '../../models';
import { observable } from 'mobx';
// import { PageLoader } from '../../shared/PageLoader';
//import { UserStore } from '../../stores/UserStore';
import { auth } from '../../services/auth/Auth';
import _ from 'lodash';

const styles = (theme: Theme) => createStyles({
    root: {
		paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
	},
	paper: {
        //display: 'flex',
        //alignItems: 'center',
        padding: theme.spacing(1, 3, 3),
        marginBottom: theme.spacing(3),
        //height: '100%'
    },
    error: {
        marginLeft: theme.spacing(2)
    },
    submitting: {
        marginLeft: theme.spacing(1)
    }
});

interface EmailRequest {
    subject: string;
    body: string;
    fromId: number;
    toId: number;
    toMeOnly: boolean;
}

interface EmailResponse {
    sent: number;
    failed: number;
}

interface EmailData {
    totalUsers: number;
    totalAuthors: number;
    authors: number;
    users: User[];
    emailTemplate: {
        footer: string;
        header: string;
    };
}

interface EmailsProps extends WithStyles<typeof styles> {
	//router: RouterStore;
	//userStore: UserStore;
}

@inject((stores: RootStore) => ({
	//router: stores.routerStore,
	//userStore: stores.userStore
}))
@observer
class Emails extends React.Component<EmailsProps> {
    @observable private form: EmailRequest = { toMeOnly: true } as EmailRequest;
    @observable private result: EmailResponse | null = null;
    @observable private emailData: EmailData = {} as EmailData;
    @observable private errors: Map<string, string> = new Map(); // key is field name, value is error message

    @observable private submitting: boolean = false;
    @observable private sending: boolean = false;

	constructor(props: EmailsProps) {
		super(props);

		//once authenticated user resolves, check if we need to redirect
		//when(() => props.userStore.meResolved, this.onMeResolved);
	}

	public render() {
		const { classes } = this.props;

		//if (this.loading)
		//	return <PageLoader />;

		return <Container className={classes.root}>
            <Box display="flex">
                <Box flexGrow="2" flexBasis="0">
                    <Paper className={classes.paper}>
                        <TextField name="subject" label="Subject" fullWidth value={this.form.subject || ''} onChange={this.handleChange}
                            error={this.errors.has('subject')} helperText={this.errors.get('subject')} margin="normal" variant="outlined" size="small" />
                        <TextField name="body" label="Body" fullWidth value={this.form.body || ''} onChange={this.handleChange}
                            error={this.errors.has('body')} helperText={this.errors.get('body')} margin="normal" multiline rows={4} rowsMax={Infinity} variant="outlined" size="small" />
                        <Box mt={1}>
                            <FormControlLabel control={<Checkbox color="primary" checked={this.form.toMeOnly} onChange={this.handleChange} name="toMeOnly" />} label="send to me only" />
                        </Box>
                        <Box mt={2}>
                            <Button color="primary" variant="contained" onClick={this.send} disabled={this.sending}>
                                Send{this.sending ? 'ing' : ''}
                                {this.sending && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                            </Button>
                            {this.errors.has('api') && <Typography color="error" variant="body2" component="span" className={classes.error}>{this.errors.get('api')}</Typography>}
                            {this.result !== null && <>
                                <Typography variant="body2" component="span" className={classes.error}>Sent: <strong>{this.result.sent}</strong></Typography>
                                <Typography variant="body2" component="span" className={classes.error}>Failed: <strong>{this.result.failed}</strong></Typography>
                            </>}
                        </Box>
                    </Paper>
                </Box>
                <Box flexGrow="1" flexBasis="0" ml={2}>
                    <Paper className={classes.paper}>
                        <Typography variant="subtitle1">Filters</Typography>
                        <Box display="flex" alignItems="center">
                            <TextField name="fromId" label="From" type="number" variant="outlined" margin="normal" size="small" value={this.form.fromId || ''} onChange={this.handleChange} />
                            <span>&nbsp;-&nbsp;</span>
                            <TextField name="toId" label="To" type="number" variant="outlined" margin="normal" size="small" value={this.form.toId || ''} onChange={this.handleChange} />
                        </Box>
                        <Box mt={2}>
                            <Button variant="outlined" size="small" onClick={this.getUserData} disabled={this.submitting}>
                                Get{this.submitting ? 'ting' : ''} Data
                                {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                            </Button>
                        </Box>
                        <Box my={2}><Divider /></Box>
                        <Box>
                            <Typography variant="body2">Total Users: <strong>{this.emailData.totalUsers}</strong></Typography>
                            <Typography variant="body2">Total Authors: <strong>{this.emailData.totalAuthors}</strong></Typography>
                            <Typography variant="body2">Authors To Email: <strong>{this.emailData.authors}</strong></Typography>
                        </Box>
                    </Paper>
                </Box>
            </Box>
            <div dangerouslySetInnerHTML={{ __html: (this.emailData.emailTemplate?.header || '') + (this.form.body || '<span style="color:#ff9800">--> BODY goes here <--</span>') + (this.emailData.emailTemplate?.footer || '') }} />
		</Container>;
	}

	public async componentDidMount() {
		await this.getUserData();
	}

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        //const value = e.target.value;
        const value = e.target.value || e.target.checked;

        // as user starts typing, we probably want to remove validation error
        this.errors.delete(name);

        (this.form as any)[name] = value;
    }

    private send = async () => {
        this.errors.clear();
        this.result = null;

        // if (!this.isValid())
        //     return;

        this.sending = true;

        // passed validation, let's save this shit
        try {
            let response = await auth.fetch('/api/admin/emails', { method: 'POST', body: JSON.stringify(this.form) });
            if (response.status === 200) {
                this.result = await response.json() as EmailResponse;
            }
            else {
                this.errors.set('api', await response.text());
            }
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }
        finally {
            this.sending = false;
        }
    }

    // private onMeResolved = () => {
	// 	const { userStore, router } = this.props;

	// }

    private getUserData = async () => {
        this.submitting = true;

        try {
            const response = await auth.fetch(`/api/admin/email-data?fromId=${this.form.fromId}&toId=${this.form.toId}`);
            if (response.status === 200) {
                this.emailData = await response.json() as EmailData;
            }
        }
        catch (error) {
            // handle error!
            console.log(error);
        }
        finally {
            this.submitting = false;
        }
    }
}

export default withStyles(styles)(Emails);
