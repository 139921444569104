import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { auth } from '../../services/auth/Auth';

// this is needed during stripe onboarding to regenerate stripe url
export default class StripeOnboardingRefresh extends React.Component<{}> {
    public render() {
        return <Box mt={5}><Typography align="center" variant="h5">Connecting to Stripe...</Typography></Box>;
    }

    public async componentDidMount() {
		try {
            const response = await auth.fetch('/api/payments/stripe/onboard', { method: 'POST' });

            if (response.status === 200) {
                const { url } = await response.json();
                window.location.href = url;
            }
            else {
                window.location.href = '/settings';
            }
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }
	}
}
