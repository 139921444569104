import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Link, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { auth } from '../../services/auth/Auth';
import { Alert } from '@material-ui/lab';
import { Plan, Publication } from '../../models';
import { PlanPeriod, PlanType } from '../../models/Plan';
import { StripeStatus } from '../../models/User';
import { RootStore } from '../../stores/RootStore';
import { RouterStore } from '../../stores/RouterStore';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { constants } from '../../helpers/constants';

const styles = (theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2, 3, 3)
    },
    submitting: {
        marginLeft: theme.spacing(1)
    },
    alert: {
        padding: theme.spacing(0, 2)
    },
    error: {
        marginLeft: theme.spacing(2)
    },
    marginTop: {
        marginTop: theme.spacing(1)
    }
});

interface AggregatedPlan {
    period: PlanPeriod;
    currency: string;
    prices: {
        // [DN] [TODO] would be nice to make amounts numbers, but not sure how to deal with it when handling decimals from the input change
        amount: string;
        type: PlanType;
    }[]
}

interface PublicationSettingsProps extends WithStyles<typeof styles> {
    router?: RouterStore;
    stripeStatus: StripeStatus;
    publications: Publication[];
    onSave: (publicationId: number, plans: Plan[]) => void;
    onDelete: (publicationId: number) => void;
    setStripeReady?: () => void;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore
}))
@observer
class PublicationSettings extends React.Component<PublicationSettingsProps> {
    @observable private editablePlans: {[publicationId: number]: AggregatedPlan[]} = {};
    @observable private goingToStripe: boolean = false;
    @observable private refreshingStripeStatus: boolean = false;
    @observable private stripeRefreshStatus: string = ''; // this is returned from API to give user a feedback on REFRESH action
    @observable private submitting: boolean = false;
    @observable private errors: Map<string, string> = new Map(); // key is field name, value is error message

    @observable private deleteDialogOpen: boolean = false;
    @observable private deleting: boolean = false;
    @observable private publicationToDelete: Publication | undefined;

    constructor(props: PublicationSettingsProps) {
        super(props);

        this.editablePlans = _.mapValues(_(props.publications).keyBy(p => p.id).value(), p => this.toAggregatedPlans(p.plans));
	}

    public render() {
        const { classes, publications } = this.props;

        return <Paper className={classes.root}>
            <Typography variant="h6" paragraph>My publications</Typography>
            
            {this.renderPaymentMessage()}

            {publications.map((p, i) => {
                return <Box key={p.id} mt={2}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <Link variant="subtitle1" component={RouterLink} to={`/${p.slug}`}>{p.name}</Link>
                        <Tooltip arrow title="Delete Publication">
                            <Button variant="outlined" size="small" color="secondary" onClick={this.openDeleteDialog(p)}>Delete</Button>
                        </Tooltip>
                    </Box>
                    {this.props.stripeStatus === StripeStatus.Ready && this.renderPaymentSettings(p.id)}
                    {i < publications.length - 1 && <Box mt={3}><Divider /></Box>}
                </Box>;
            })}

            {this.renderDeleteDialog()}
        </Paper>;
    }

    private renderPaymentMessage = () => {
        const { stripeStatus, classes } = this.props;

        if (stripeStatus === StripeStatus.New) {
            return <Alert severity="info" action={
                <Button onClick={this.connectWithStripe} disabled={this.goingToStripe}>
                    Connect
                    {this.goingToStripe && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                </Button>
            }>Connect with Stripe to monetize your work</Alert>;
        }
        else if (stripeStatus === StripeStatus.Initiated) {
            return <>
                <Alert severity="info" action={
                    <Button onClick={this.connectWithStripe} disabled={this.goingToStripe}>
                        Continue
                        {this.goingToStripe && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                    </Button>
                }>Complete onboarding with Stripe to monetize your work</Alert>
                <Alert severity="info" variant="outlined" style={{ marginTop: 16 }}>
                    Sometimes, it takes a few seconds for Stripe to verify your information. 
                    If you fully completed the onboarding process, try <Button onClick={this.refreshStripeStatus} disabled={this.refreshingStripeStatus} color="primary">refreshing the status{this.refreshingStripeStatus && <CircularProgress size={18} thickness={7} className={classes.submitting} />}</Button> to see if verification is done in order to set up your subscription plans. 
                    Otherwise, please click CONTINUE above to complete the onboarding.
                    {!!this.stripeRefreshStatus && <Typography variant="body2" color="error" className={classes.marginTop}>{this.stripeRefreshStatus}</Typography>}
                </Alert>
            </>;
        }
        else {
            return null;
        }
    }

    private connectWithStripe = async () => {
        this.goingToStripe = true;

        try {
            const response = await auth.fetch('/api/payments/stripe/onboard', { method: 'POST' });

            if (response.status === 200) {
                const { url } = await response.json();
                window.location.href = url;
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }
        finally {
            this.goingToStripe = false;
        }
    }

    private refreshStripeStatus = async () => {
        this.refreshingStripeStatus = true;
        this.stripeRefreshStatus = '';

        try {
            const response = await auth.fetch('/api/payments/stripe/status');
            // should probably check for 200 instead, it's ok for now I guess
            this.stripeRefreshStatus = await response.text();

            // this.stripeRefreshStatus is empty when user is stripe ready
            if (!this.stripeRefreshStatus && this.props.setStripeReady)
                this.props.setStripeReady();
        }
        catch (error) {
            console.log(error);
        }
        finally {
            this.refreshingStripeStatus = false;
        }
    }

    private renderPaymentSettings = (publicationId: number) => {
        const { classes } = this.props;

        return <Box>
            {this.editablePlans[publicationId].map(p => 
                <Box key={p.period} mt={2} mb={3}>
                    <Typography variant="subtitle2">{PlanPeriod[p.period]} Price</Typography>
                    <Box mt={1} mb={2}>
                        {/* <Typography variant="caption" display="block">This is your regular price</Typography> */}
                        <TextField placeholder="Amount" style={{ width: 100 }} value={p.prices.find(pr => pr.type === PlanType.Original)?.amount || ''} onChange={this.handleAmountChange(p, PlanType.Original)} 
                            error={this.errors.has(`amount-${p.period}-${PlanType.Original}`)} helperText={this.errors.get(`amount-${p.period}-${PlanType.Original}`)} />
                        <Select value={p.currency} renderValue={value => value as string} onChange={this.handleSelectChange(p)}>  
                            {constants.currencies.map(currency => <MenuItem key={currency.code} value={currency.code}>{currency.code} - {currency.name}</MenuItem>)}
                        </Select>
                    </Box>
                    <Box>
                        <Typography variant="caption" display="block">Optional discounted {PlanPeriod[p.period]} price for those who are already subscribed to other paid publications. This is an incentive for them to subscribe to your publication as well. While it's optional, it's highly recommended to set it and lower it in order to convert more readers.</Typography>
                        <TextField placeholder="Amount" style={{ width: 100 }} value={p.prices.find(pr => pr.type === PlanType.MultipleSubscriptions)?.amount || ''} onChange={this.handleAmountChange(p, PlanType.MultipleSubscriptions)} 
                            error={this.errors.has(`amount-${p.period}-${PlanType.MultipleSubscriptions}`)} helperText={this.errors.get(`amount-${p.period}-${PlanType.MultipleSubscriptions}`)} />
                        <Select value={p.currency} renderValue={value => value as string} onChange={this.handleSelectChange(p)}>  
                            {constants.currencies.map(currency => <MenuItem key={currency.code} value={currency.code}>{currency.code} - {currency.name}</MenuItem>)}
                        </Select>
                    </Box>
                    {this.errors.has(`amount-${p.period}`) && <Typography color="error" variant="caption" component="div" className={classes.marginTop}>{this.errors.get(`amount-${p.period}`)}</Typography>}
                </Box>
            )}
            <Button variant="contained" color="primary" onClick={this.save(publicationId)} disabled={this.submitting}>
                Sav{this.submitting ? 'ing' : 'e'}
                {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
            </Button>
            {this.errors.has('api') && <Typography color="error" variant="body2" component="span" className={classes.error}>{this.errors.get('api')}</Typography>}
        </Box>;
    }

    private handleSelectChange = (plan: AggregatedPlan) => (e: React.ChangeEvent<{value: unknown}>) => {
        plan.currency = e.target.value as string;
    }
    private handleAmountChange = (plan: AggregatedPlan, type: PlanType) => (e: React.ChangeEvent<HTMLInputElement>) => {
        // this is pretty primitive, but ok for now
        if (/^[0-9.]*$/.test(e.target.value)) {
            this.errors.delete(`amount-${plan.period}`);
            this.errors.delete(`amount-${plan.period}-${type}`);

            let price = plan.prices.find(p => p.type === type)!;
            if (!price) {
                plan.prices.push({ amount: e.target.value, type });
            }
            else {
                if (e.target.value) {
                    price.amount = e.target.value;
                }
                else {
                    plan.prices = plan.prices.filter(p => p.type !== type);
                }
            }
        }
    }

    private save = (publicationId: number) => async () => {
        if (!this.isValid(publicationId))
            return;

        this.submitting = true;

        try {
            const plansDto = this.editablePlans[publicationId].flatMap(p => p.prices.map(pr => ({ 
                period: p.period,
                amount: +pr.amount,
                currency: p.currency,
                type: pr.type
            })));

            var response = await auth.fetch(`/api/publications/${publicationId}/plans`, { 
                method: 'POST', 
                body: JSON.stringify(plansDto) 
            });

            if (response.status === 200) {
                const plans = await response.json() as Plan[];

                this.editablePlans[publicationId] = this.toAggregatedPlans(plans);

                this.props.onSave(publicationId, plans);
            }
            else {
                this.errors.set('api', await response.text());
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.errors.set('api', 'Error updating prices. Try again later');
        }
        finally {
            this.submitting = false;
        }
    }

    // [TODO] create something better!!!!???
    private isValid = (publicationId: number) => {
        this.errors.clear();

        // see if no prices are set yet
        if (this.editablePlans[publicationId].every(p => !p.prices.length)) {
            this.errors.set('api', 'No prices are set');
        }

        this.editablePlans[publicationId].forEach(p => {
            p.prices.forEach(pr => {
                if (isNaN(+pr.amount)) {
                    this.errors.set(`amount-${p.period}-${pr.type}`, 'Invalid price');
                }
                else if (+pr.amount < 3) {
                    this.errors.set(`amount-${p.period}-${pr.type}`, `Price should be ${constants.currencies.find(c => c.code === p.currency)?.symbol}3 or more`);
                }
            });

            // Original price has to be higher than all others for the same period
            if (p.prices.length && _.orderBy(p.prices, [pr => +pr.amount, pr => pr.type], ['desc','desc'])[0]?.type !== PlanType.Original) {
                this.errors.set(`amount-${p.period}`, 'Discounted price have to be lower than the base price');
            }
        });

        return this.errors.size === 0;
    }

    private toAggregatedPlans = (plans: Plan[]) => {
        return Object.values(PlanPeriod).filter(period => !isNaN(+period)).map(period => {
            const periodPlans = plans.filter(p => p.period === period);
            // see if publication already contains plans filtered by period
            if (periodPlans.length) {
                return { 
                    period: periodPlans[0].period, 
                    currency: periodPlans[0].currency, 
                    prices: periodPlans.map(plan => ({ amount: plan.amount.toString(), type: plan.type })) 
                } as AggregatedPlan;
            }
            else {
                return { period, currency: 'USD', prices: [] } as AggregatedPlan
            }
        });
    }

    private renderDeleteDialog = () => {
        const { classes } = this.props;

        return <Dialog open={this.deleteDialogOpen} onClose={this.closeDeleteDialog}>
            <DialogTitle>Delete Publication</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">You are about to delete <i>{this.publicationToDelete?.name}</i> publication. Your subscribers will lose access to all posts in this publication. Do you still want to proceed?</DialogContentText>
                {this.errors.has('dialog-api') && <Typography color="error" variant="body2">Unable to delete: {this.errors.get('dialog-api')}. Please contact us</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.closeDeleteDialog} color="primary">Cancel</Button>
                <Button variant="contained" color="primary" onClick={this.delete} disabled={this.deleting}>
                    Delet{this.deleting ? 'ing' : 'e'}
                    {this.deleting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private openDeleteDialog = (publication: Publication) => () => {
        this.publicationToDelete = publication;
        this.deleteDialogOpen = true;
    }
    private closeDeleteDialog = () =>  {
        this.deleteDialogOpen = false;
        this.publicationToDelete = undefined;
        this.errors.delete('dialog-api');
    }

    private delete = async () => {
        this.errors.clear();
        this.deleting = true;

        try {
            var response = await auth.fetch(`/api/publications/${this.publicationToDelete!.id}`, { method: 'DELETE' });

            if (response.status === 200) {
                this.props.onDelete(this.publicationToDelete!.id);

                this.closeDeleteDialog();
            }
            else {
                this.errors.set('dialog-api', await response.text());
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.errors.set('dialog-api', 'Error deleting publication. Try again later');
        }
        finally {
            this.deleting = false;
        }
    }
}

export default withStyles(styles)(PublicationSettings);