import * as React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { Typography } from '@material-ui/core';
import { observable } from 'mobx';
import moment from 'moment';

interface AutoSaveProps {
    object: any;
    onSave: () => Promise<Response>;
}

@observer
export default class AutoSave extends React.Component<AutoSaveProps> {
    private timeoutId: number | undefined;

    @observable private message: string = ''
    @observable private isError: boolean = false;

    public render() {
        // don't render anything if object is empty, i.e. initial load
        if (_.isEmpty(this.props.object)) {
            return null;
        }

        return <Typography variant="inherit" color={this.isError ? 'error' : 'initial'}>{this.message}</Typography>;
    }

    public componentDidUpdate(prevProps: AutoSaveProps) {
        // see if there are any changes to the object
        if (!_.isEqual(prevProps.object, this.props.object))
        {
            // clear any timeouts while object is being updated
            if (this.timeoutId)
                window.clearTimeout(this.timeoutId);

            // changes detected, update messaging
            this.isError = false;
            this.message = 'Unsaved changes';

            // save 2 seconds after any changes to the object are made
            this.timeoutId = window.setTimeout(this.save, 2000);
        }
    }

    public componentWillUnmount() {
        window.clearTimeout(this.timeoutId);
    }

    private save = async () => {
        // start saving
        this.isError = false;
        this.message = 'Saving...';

        try {
            const response = await this.props.onSave();

            // update message based on fetch response
            if (response.status === 200) {
                this.message = `Last save ${moment().format('llll')}`;
            }
            else {
                this.isError = true;
                this.message = `Not Saved: ${response.status === 500 ? 'network error' : await response.text()}`;
            }
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.isError = true;
            this.message = 'Not Saved: network error';
        }
    }
}
