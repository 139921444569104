import * as _ from 'lodash';

export const converter = {

    toSlug: (text: string) => {
        // [DN] not sure if we should exclude them, some people say we should for SEO, some don't
        // const stopwords = ['a', 'an', 'the'];

        // another way of doing it in case we need more control
        // return text.toLowerCase()
        //     .replace(/['"`]+/g, '') // remove all quotes
        //     .replace(/[^a-z0-9]+/g, ' ') // replace all non letters or numbers with spaces
        //     .trim() // trim here
        //     .replace(/ +/g, '-'); // replace one or more spaces with hyphen

        return _.kebabCase(text) // returns hyphen separated string
            .replace(/^(.{40}[^-]*).*/, '$1'); // if more than 40 characters long, cut it at the next hyphen
    }

        
    // add more...
};