import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, Link, Radio, RadioGroup, Typography } from '@material-ui/core';
import { PlanPeriod, SubscriberPlan } from '../../models/Plan';
import { Publication, Subscription } from '../../models';
import { auth } from '../../services/auth/Auth';
import moment from 'moment';
import { constants } from '../../helpers/constants';
import _ from 'lodash';
import { Alert } from '@material-ui/lab';

const styles = (theme: Theme) => createStyles({
    radioButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    submitting: {
        marginLeft: theme.spacing(1)
    }
});

interface UpdateSubscriptionDialogProps extends WithStyles<typeof styles> {
    subscription: Subscription;
    subscriptionToDowngrade?: Subscription; // this is the subscription that will be updated from MultipleSubscriptions to Original
    onUnsubscribe: (publicationId: number) => void;
    onPlanUpdate: (publicationId: number, subscriberPlan: SubscriberPlan, closeDialog: boolean) => void;
    onDialogClose: () => void;
}

@observer
class UpdateSubscriptionDialog extends React.Component<UpdateSubscriptionDialogProps> {
    @observable private submitting: boolean = false;
    @observable private fullPublication: Publication = {} as Publication;
    @observable private planId: number;
    @observable private errors: Map<string, string> = new Map(); // key is field name, value is error message

    constructor(props: UpdateSubscriptionDialogProps) {
		super(props);

        this.planId = props.subscription.subscriberPlan ? props.subscription.subscriberPlan.planId : 0;
	}

    public render() {
        const { classes, subscription, onDialogClose } = this.props;

        // disable Update button when
        const updateDisabled = this.submitting || // form is being submitted
            this.planId === subscription.subscriberPlan?.planId || // current plan is selected
            this.planId === subscription.subscriberPlan?.upcomingPlan?.planId || // upcoming plan is selected
            (!!subscription.subscriberPlan?.endDate && !subscription.subscriberPlan.upcomingPlan); // subscription is canceled but still active

        return <Dialog open={true} onClose={onDialogClose} maxWidth="sm">
            <DialogTitle>{subscription.subscriberPlan ? 'Update your subscription' : 'Unsubscribe'}</DialogTitle>
            <DialogContent>
                {subscription.subscriberPlan ? this.renderSubscriberPlan() : this.renderUnsubscribe()}
                {this.errors.has('api') && <Typography color="error" variant="body2">{this.errors.get('api')}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onDialogClose} color="primary">Cancel</Button>
                <Button variant="contained" color="primary" disabled={updateDisabled} onClick={this.update}>
                    {subscription.subscriberPlan ? 'Updat' : 'Unsubscrib'}{this.submitting ? 'ing' : 'e'}
                    {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    public componentDidMount() {
        if (this.props.subscription.subscriberPlan) {
            this.loadPublication();
        }
    }

    private renderUnsubscribe = () => {
        const { subscription } = this.props;

        return <Typography>You are about to unsubscribe from <i>{subscription.name}</i> publication. You will stop receiving updates from its authors. Do you still want to proceed?</Typography>;
    }

    private renderSubscriberPlan = () => {
        const { classes, subscription } = this.props;

        return <>
            <Typography>You are updating <i>{subscription.name}</i> subscription</Typography>
            {_.isEmpty(this.fullPublication) ? <Box textAlign="center" mt={2}><CircularProgress /></Box> : this.renderPlans()}
            {this.renderAlert()}
        </>;
    }

    private renderPlans = () => {
        const { subscription } = this.props;

        // let's find what other plans publication has besides the one user is subscribed to
        const otherPlans = this.fullPublication.plans.filter(p => p.period !== subscription.subscriberPlan.period);
        // if we can match the type - get it, otherwise get the other one (provided we only have monthly and annual plans)
        // [TODO] need to restructure it if we introduce more plans (like lifetime)
        const otherPlan = otherPlans.find(p => p.type === (subscription.subscriberPlan.upcomingPlan || subscription.subscriberPlan).type) || otherPlans.find(p => p.type !== (subscription.subscriberPlan.upcomingPlan || subscription.subscriberPlan).type);

        return <FormControl error={this.errors.has('plan')} margin="normal">
            <RadioGroup value={this.planId} onChange={this.onPlanChange}>
                <Box my={1}>
                    <FormControlLabel value={subscription.subscriberPlan.planId} control={<Radio style={{ paddingTop: 0, paddingBottom: 0 }} />}
                        label={`${constants.currencies.find(c => c.code === subscription.subscriberPlan.currency)?.symbol}${subscription.subscriberPlan.amount}/${subscription.subscriberPlan.period === PlanPeriod.Annual ? 'year' : 'month'}`}
                    />
                    <Typography variant="caption" color="textSecondary" display="block" style={{ paddingLeft: 33 }}>
                        You're currently on this plan since <strong>{moment(subscription.subscriberPlan.startDate).format('ll')}</strong>
                        {(subscription.subscriberPlan.endDate && !subscription.subscriberPlan.upcomingPlan) &&
                            <span>. Your subscription will end <strong>{moment(subscription.subscriberPlan.endDate).format('ll')}</strong></span>
                        }
                        {subscription.subscriberPlan.upcomingPlan?.period === subscription.subscriberPlan.period &&
                            <span>. The price is set to {subscription.subscriberPlan.upcomingPlan.amount > subscription.subscriberPlan.amount ? 'increase' : 'decrease'} to <strong>{`${constants.currencies.find(c => c.code === subscription.subscriberPlan.upcomingPlan.currency)?.symbol}${subscription.subscriberPlan.upcomingPlan.amount}`}</strong> on <strong>{moment(subscription.subscriberPlan.upcomingPlan.startDate).format('ll')}</strong></span>
                        }
                    </Typography>
                </Box>
                {(subscription.subscriberPlan.upcomingPlan && subscription.subscriberPlan.upcomingPlan.period !== subscription.subscriberPlan.period) ?
                    <Box my={1}>
                        <FormControlLabel value={subscription.subscriberPlan.upcomingPlan.planId} control={<Radio style={{ paddingTop: 0, paddingBottom: 0 }} />}
                            label={`${constants.currencies.find(c => c.code === subscription.subscriberPlan.upcomingPlan.currency)?.symbol}${subscription.subscriberPlan.upcomingPlan.amount}/${subscription.subscriberPlan.upcomingPlan.period === PlanPeriod.Annual ? 'year' : 'month'}`}
                        />
                        <Typography variant="caption" color="textSecondary" display="block" style={{ paddingLeft: 33 }}>
                            Your subscription will switch to this plan <strong>{moment(subscription.subscriberPlan.upcomingPlan.startDate).format('ll')}</strong>
                        </Typography>
                    </Box> :
                    (otherPlan && <Box my={1}>
                        <FormControlLabel value={otherPlan.id} control={<Radio style={{ paddingTop: 0, paddingBottom: 0 }} />}
                            label={`${constants.currencies.find(c => c.code === otherPlan.currency)?.symbol}${otherPlan.amount}/${otherPlan.period === PlanPeriod.Annual ? 'year' : 'month'}`}
                        />
                    </Box>)
                }
                <Box my={1}>
                    <FormControlLabel value={0} control={<Radio style={{ paddingTop: 0, paddingBottom: 0 }} />}
                        label="Cancel my subscription"
                    />
                </Box>
            </RadioGroup>
            <FormHelperText>{this.errors.get('plan')}</FormHelperText>
        </FormControl>;
    }

    private renderAlert = () => {
        const { classes, subscription, subscriptionToDowngrade } = this.props;

        if (this.planId === subscription.subscriberPlan.planId || this.planId === subscription.subscriberPlan.upcomingPlan?.planId) {
            return null;   
        }

        // [TODO] make sure to check it calculates it the same way as stripe
        // i.e. when does the billing period end when person subscribes on Jan 28th-31st? would it be Feb 28th?
        // what if they subscribe on Feb 28th, would it be March 31st or March 28th?
        const currentBillingPeriodEnd = moment(subscription.subscriberPlan.startDate).add(moment().diff(subscription.subscriberPlan.startDate, subscription.subscriberPlan.period === PlanPeriod.Annual ? 'years' : 'months') + 1, subscription.subscriberPlan.period === PlanPeriod.Annual ? 'year' : 'month').format('ll');

        // subscription is canceled but still active
        if (!subscription.subscriberPlan.upcomingPlan && subscription.subscriberPlan.endDate) {
            if (this.planId === 0) {
                return <Alert severity="info">
                    Your subscription has already been canceled. However, you still have full access to it until the end of your current billing period on <strong>{moment(subscription.subscriberPlan.endDate).format('ll')}</strong>.
                </Alert>;
            }
            else {
                return <Alert severity="info">
                    Your subscription has been canceled. You cannot update it.
                </Alert>;
            }
        }

        if (this.planId === 0) {
            // variant="filled" - try this variant for more attention??
            return <Alert severity="error">
                You're about to cancel your subscription. There will be no refund as per our <Link href="/terms" target="_blank">terms</Link>. However, your subscription will remain active for the remainder of your current billing period that ends on <strong>{currentBillingPeriodEnd}</strong> and you will continue to have the same access to the publication until then. Your subscription will not be renewed, but you'll be switched to a free plan.
                {subscriptionToDowngrade && <Typography variant="body2" style={{ marginTop: 4 }}><strong>Please note</strong> you're getting a discounted price on <i>{subscriptionToDowngrade.name}</i> subscription for being subscribed to multiple publications. It will automatically be switched to its full price on its renewal date.</Typography>}
            </Alert>;
        }
        else {
            return <Alert severity="warning">You're about to switch your subscription to a new plan. This change will take effect at the start of the next billing period on <strong>{currentBillingPeriodEnd}</strong>.</Alert>;
        }
    }

    private onPlanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.planId = +e.target.value;
        this.errors.delete('plan');
        this.errors.delete('api');
    }

    private loadPublication = async () => {
        try {
            let response = await auth.fetch(`/api/publications/${this.props.subscription.id}`);
            if (response.status === 200) {
                this.fullPublication = await response.json() as Publication;
            }
        }
        catch (error) {
            // handle error!
            console.log(error);
        }
    }

    private update = async () => {
        const { subscription } = this.props;

        this.errors.clear();

        if (!subscription.subscriberPlan) {
            // if there is no plan, just unsubscribe then
            await this.unsubscribe();
        }
        else if (this.planId > 0) {
            // update plan if other than 0
            await this.updatePlan();
        }
        else {
            // if planId is 0 - user is cancelling the subscription
            await this.cancelSubscription();
        }
    }

    private unsubscribe = async () => {
        this.submitting = true;

        try {
            var response = await auth.fetch(`/api/publications/${this.props.subscription.id}/subscribe`, { method: 'DELETE' });

            if (response.status === 200) {
                if (this.props.onUnsubscribe)
                    this.props.onUnsubscribe(this.props.subscription.id);
            }
            else {
                this.errors.set('api', await response.text());
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.errors.set('api', 'Error unsubscribing. Try again later');
        }
        finally {
            this.submitting = false;
        }
    }

    private updatePlan = async () => {
        this.submitting = true;

        try {
            var response = await auth.fetch(`/api/publications/${this.props.subscription.id}/plansubscribe`, {
                method: 'POST',
                body: JSON.stringify({ planId: this.planId })
            });

            // use auth.isSuccessfulStatus instead?
            if (response.status === 200) {
                const subscriberPlan = await response.json() as SubscriberPlan;

                if (this.props.onPlanUpdate)
                    this.props.onPlanUpdate(this.props.subscription.id, subscriberPlan, true);
            }
            else {
                this.errors.set('api', await response.text());
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.errors.set('api', 'Error updating subscription plan. Try again later');
        }
        finally {
            this.submitting = false;
        }
    }

    private cancelSubscription = async () => {
        this.submitting = true;

        try {
            var response = await auth.fetch(`/api/publications/${this.props.subscription.id}/plansubscribe`, { method: 'DELETE' });

            if (response.status === 200) {
                const apiResult = await response.json() as { canceledPlan: SubscriberPlan, downgradedPlan?: { publicationId: number, plan: SubscriberPlan } };
                console.log(apiResult);

                if (this.props.onPlanUpdate) {
                    this.props.onPlanUpdate(this.props.subscription.id, apiResult.canceledPlan, !apiResult.downgradedPlan);

                    if (apiResult.downgradedPlan) {
                        this.props.onPlanUpdate(apiResult.downgradedPlan.publicationId, apiResult.downgradedPlan.plan, true);
                    }
                }
            }
            else {
                this.errors.set('api', await response.text());
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.errors.set('api', 'Error cancelling subscription plan. Try again later');
        }
        finally {
            this.submitting = false;
        }
    }
}

export default withStyles(styles)(UpdateSubscriptionDialog);