import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { RouterStore } from '../../stores/RouterStore';
import { RootStore } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { Container, Typography, Divider, Box, Avatar, Button, ButtonBase } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { observable } from 'mobx';
import { PageLoader } from '../../shared/PageLoader';
import { auth } from '../../services/auth/Auth';
import { Post, PostStatus } from '../../models/Post';
import { MetricType } from '../../models/MetricType';
import Comments from './Comments';
import { prosemirrorHelper } from '../../services/prosemirror/helper';
import { constants } from '../../helpers/constants';
import { UserStore } from '../../stores/UserStore';
import moment from 'moment';
import Like from '../../shared/Like';
import { Helmet } from 'react-helmet';
import Share from '../../shared/Share';
import { Alert } from '@material-ui/lab';
import { postStyles } from '../../services/prosemirror/styles';
import { SubscriptionElement } from '../../shared/SubscriptionElement';
import { SubscriberPlan, Subscription } from '../../models';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    pubAvatar: {
        marginRight: theme.spacing(1)
    },
    userAvatar: {
        marginRight: theme.spacing(2)
    },
    paywall: {
        position: 'relative',

        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            top: -200,
            height: 200,
            background: `linear-gradient(top, rgba(255, 255, 255, 0), ${theme.palette.background.default})`
        }
    },

    ...postStyles(theme)
});

interface MatchParams {
    publicationSlug: string;
    postSlug: string;
};

interface PostDetailsProps extends WithStyles<typeof styles>, RouteComponentProps<MatchParams> {
    router: RouterStore;
    userStore: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class PostDetails extends React.Component<PostDetailsProps> {
    @observable private post: Post = {} as Post;
    @observable private loading: boolean = true;

    public render() {
        const { classes, userStore } = this.props;

        if (this.loading || (userStore.isAuthenticated && !userStore.meResolved))
            return <PageLoader />;

        // [DN] TODO: we should probably return HTML from API for public page, basically thi block below ort something similar
        const postBody = this.post.body;
        let postForDisplaying = '';
        let postConvert = null;
        if (postBody) {
            postConvert = prosemirrorHelper.convertDocToNodes(postBody);
            postForDisplaying = prosemirrorHelper.getHTMLstring(postConvert);
        }

        // check if post is not public and user is a paying customer of this publication (or an author of the post)
        const paywallEnabled = !this.post.public && 
            (
                !userStore.isAuthenticated || 
                (
                    !userStore.me.subscriptions.some(s => s.id === this.post.publication.id && s.subscriberPlan) &&
                    !this.post.authors.some(a => a.id === userStore.me.id)
                )
            );

        return <Container className={classes.root}>
            {(this.post.status === PostStatus.Published || this.post.status === PostStatus.Republished) &&
                <Helmet>
                    <title>{this.post.title} - Storylect</title>
                    <meta name="description" content={this.post.subtitle || this.post.publication.description} />
                    <meta property="og:title" content={this.post.title} />
                    <meta property="og:description" content={this.post.subtitle || this.post.publication.description} />
                    {this.post.images?.banner && <meta property="og:image" content={this.post.images.banner} />}
                </Helmet>
            }
            {(this.post.status === PostStatus.Draft || this.post.status === PostStatus.Unpublished) &&
                <Box maxWidth={400} mb={4} mx="auto">
                    <Alert severity="warning" action={<Button onClick={this.navigateToEditPost}>EDIT</Button>}>This is a draft and only visible to you.</Alert>
                </Box>
            }
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems="center">
                <ButtonBase onClick={this.goBackToPublication} disableRipple disableTouchRipple>
                    <Avatar alt={this.post.publication.name} src={this.getAvatarUrl()} className={classes.pubAvatar}/>
                    <Typography variant="h6" component="h1">{this.post.publication.name}</Typography>
                </ButtonBase>
                <Box mt={{ xs: 3, md: 0 }} ml={{ md: 3 }}>
                    <SubscriptionElement publication={this.post.publication} user={userStore.me} authed={userStore.isAuthenticated} onSuccess={this.onSubscribe} onPlanSuccess={this.onPlanSubscribe} />
                </Box>
            </Box>
            <Box my={3}><Divider /></Box>
            <Typography variant="h4" component="h1">{this.post.title}</Typography>
            <Typography variant="subtitle1" component="h2" color="textSecondary">{this.post.subtitle}</Typography>
            <Box display="flex" my={3} alignItems="center">
                <Avatar alt={this.post.authors[0].name} src={this.post.authors[0].avatarUrl}>{this.post.authors[0].avatarAlt}</Avatar>
                <Box flexGrow="1" ml={2}>
                    <Typography variant="subtitle2">{this.post.authors[0].name}</Typography>
                    <Typography variant="body2" color="textSecondary">{moment(this.post.createdOn).format('ll')}</Typography>
                </Box>
                <Box display="flex" pr={6} minHeight={34}>
                    <Like isFab postId={this.post.id}
                        count={this.post.metrics[MetricType.Like]}
                        liked={this.post.reactions[MetricType.Like]?.includes(userStore.me.id)}
                        actionable={auth.isLoggedIn}
                        onSuccess={this.onLike}
                        size="default"
                    />
                    <Box>
                        <Share gaCategory="Post Share" gaLabel={`${this.post.publication.slug}/${this.post.slug}`} />
                    </Box>
                </Box>
            </Box>
            <Typography component="div" className={classes.post} dangerouslySetInnerHTML={{ __html: postForDisplaying }} />
            {paywallEnabled ? 
                <Box className={classes.paywall} textAlign="center" mb={2}>
                    <Typography variant="h5">Subscribe to continue reading</Typography>
                    <Typography variant="subtitle1" paragraph>This content is for paying subscribers only</Typography>
                    <SubscriptionElement publication={this.post.publication} user={userStore.me} authed={userStore.isAuthenticated} onSuccess={this.onSubscribe} onPlanSuccess={this.onPlanSubscribe} />
                </Box> :
                <>
                    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems={{ sm: 'center' }} mt={3}>
                        <Box display="flex" minHeight={56}>
                            <Like isFab postId={this.post.id}
                                count={this.post.metrics[MetricType.Like]}
                                liked={this.post.reactions[MetricType.Like]?.includes(userStore.me.id)}
                                actionable={auth.isLoggedIn}
                                onSuccess={this.onLike}
                                size="default"
                            />
                            <Box ml={1}>
                                <Share direction="right" tooltipPlacement="top" gaCategory="Post Share" gaLabel={`${this.post.publication.slug}/${this.post.slug}`} />
                            </Box>
                        </Box>
                        <Box mt={{ xs: 2, sm: 0 }} ml={{ md: 3 }}>
                            <SubscriptionElement publication={this.post.publication} user={userStore.me} authed={userStore.isAuthenticated} onSuccess={this.onSubscribe} onPlanSuccess={this.onPlanSubscribe} />
                        </Box>
                    </Box>
                    <Box my={4}><Divider /></Box>
                    <Comments postId={this.post.id} />
                </>
            }
        </Container>;
    }

    public async componentDidMount() {
        // [DN] TODO: I only need to load it when someone lands on the page, the post can be passed in as a prop from publications page too
        await this.loadPost();
    }

    private onLike = (likeDiff: number) => {
        // [DN] since we have 2 LIKE buttons for the same post, we need to make sure that the other one is updated as well
        this.post.metrics[MetricType.Like] = (this.post.metrics[MetricType.Like] || 0) + likeDiff;

        if (likeDiff > 0)
            this.post.reactions[MetricType.Like] = [...(this.post.reactions[MetricType.Like] || []), this.props.userStore.me.id];
        else
            this.post.reactions[MetricType.Like] = this.post.reactions[MetricType.Like].filter(i => i !== this.props.userStore.me.id);
    }

    private onSubscribe = async (token: string) => {
        const { userStore } = this.props;

        // if token is present, this is a new user, make sure to log them in (new subscription will already be included since it makes DB call to get current user)
        if (token) {
            await userStore.authenticate(token);
        }
        else {
            userStore.addSubscription(this.post.publication as Subscription);
        }

        return Promise.resolve();
    }

    private onPlanSubscribe = async (subscriberPlan: SubscriberPlan) => {
        const { userStore } = this.props;

        userStore.addSubscriberPlan(this.post.publication.id, subscriberPlan);

        // need to reload private post to include full body of it now that user is a paying subscriber
        if (!this.post.public) {
            await this.loadPost();
        }
    }

    private goBackToPublication = () => this.props.router.push(`/${this.post.publication.slug}`);

    private getAvatarUrl = () => this.post.publication.images?.avatar || constants.defaultPublicationAvatarUrl;

    private loadPost = async () => {
        const { match } = this.props;

        try {
            // [DN] TODO: need to be able to check publication slug as well to make sure we get the correct post (in case of duplicate post slug)
            let response = await auth.fetch(`/api/publications/${match.params.publicationSlug}/posts/${match.params.postSlug}`);
            if (response.status === 200) {
                this.post = await response.json() as Post;

                this.recordView();
            }
            else {
                this.props.router.replace('/');
            }
        }
        catch (error) {
            // handle error!
            console.log(error);
        }
        finally {
            this.loading = false;
        }
    }

    private navigateToEditPost = () => this.props.router.push(`/post/${this.post.id}`);

    private recordView = async () => {
        try {
            await auth.fetch(`/api/posts/${this.post.id}/view`, { method: 'POST' });
        }
        catch (error) {
            console.log(error);
        }
    }
}

export default withStyles(styles)(PostDetails);
