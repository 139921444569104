import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, IconButton, Avatar, Tooltip, Menu, MenuItem, Typography, Box, Link as ALink } from '@material-ui/core';
// import MenuIcon from '@material-ui/icons/Menu';
import { observer, inject } from 'mobx-react';
import { observable, toJS } from 'mobx';
import { RouterStore } from './stores/RouterStore';
import { RootStore } from './stores/RootStore';
import { auth } from './services/auth/Auth';
import { UserStore } from './stores/UserStore';
import { constants } from './helpers/constants';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';

const styles = (theme: Theme) => createStyles({
    menuButton: {
        marginRight: theme.spacing(1)
    },
    logo: {
        flexGrow: 1
    },
    logoImg: {
        [theme.breakpoints.down('xs')]: {
            width: 128
        }
    },
    desktop: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex'
        }
    }
});

interface NavigationProps extends WithStyles<typeof styles> {
    transparent?: boolean;
    router?: RouterStore;
    userStore?: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class Navigation extends React.Component<NavigationProps> {
    @observable private menuElement: HTMLElement | null = null;

    public render() {
        const { classes, router, userStore, transparent } = this.props;

        // home page doesn't have a header
        if (!transparent && router?.location.pathname === '/') {
            return null;
        }

        const showStandWithUkraine = ['/', '/login', '/join', '/privacy', '/terms', '/about'].includes(router?.location.pathname || '');

        return <>
            <AppBar position={transparent ? 'relative' : 'sticky'} color={transparent ? 'transparent' : 'default'} elevation={transparent ? 0 : 4}>
                {showStandWithUkraine && <Box bgcolor="primary.dark" color="primary.contrastText" p={1.5} textAlign="center">
                    <img style={{ paddingRight: 8, verticalAlign: 'middle' }} alt="Ukraine" src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAAB3RJTUUH5gQGEBYM1SrxrwAAA9JJREFUSMfVk8uPVEUUxn+nqm6/3z00MOJgkNFBCcFogtEYE0JM3GrYGBNduzZRN7rzrzBRE3es3KmBxAUxJArIRg0OMDDynAGmm373rToubj8GGYLs9CSVW3Wr6nxffed88H8PYd/HUnjxSGPbwsKBaqO2NByFRrc3kMFgtNHr9Fa7zebyaGP9sqxfuKv57d5/985jATi7eOhTNdFbauyiiVJZg0diRbyA815dphXSpVVqi78y6J6Uw1//jO9dsMPLbbUl9Sc/eSBp7b3jiGKC2Mgh5gOQhqqiqiggCCICIlZFqhhbxUYH1KTexYQ7qD0fZ577BWPOypvHzrtc9lauVOnWttclVy6WWq3e3nvt/pEQfN+pckdVGxqUoAqq92soAjJdGTBzyeAVjAtE2Y5G+RZRtkcqKzadLdi0Vun7FLGccoI2gYR90EllELkP5r7Ppv9GkSJiiioCmPGh6fmaAVpM5AkKOtmWrUG2jIRYUCUERZmqUDDAPU2eQNCQbE1JPPCUhyZHExUSmaf3cw7VPgo+gPcGFYMPlqCKYmbsZTZ9GNBEBR0fVMg4H8zIiKeRW2Np203KuQ7exzS7KVbXs1waRtxugw8O1ILKQ98xaRRVnYhg3dH9J/zrz69w+OBVdj3hSadjhJjRSGneM1y+XuTsxZ2cWt7LmZUFVm5UaXciUDMeMq2bquKDEtTg1eLs0MqFE9GX9bq+n6+XcZUKOAt4CCMYttFuh1FnQKct3LhT5Per85xefZpz1xdZ3lhgLd7JIJojylco1sqUKimK5hb7qmd446kfgzMSrFdLGDNJCpTIoGoIaomDRQjUsy0OLbR4af4PRsHSjgvcjes0aTCMamQrReaqQ+ZzV9iRvoKj5x2i0VTEiQ8mCm5uf0nUCCFpCCueuXyTJ3NNsoWLpPMOU8xDOgdxgGFMPHLBoJKf+IAHnLyFwTbNddx9cUg6T70FP64LgiJDA1rY1MjMPDJJPnPmoxwxJTjj2DFAcbYZZsbZymOPRBjnGedQpOsEyt5Dt4vvB9o4OlhUkIwJpmBj0iEkYMIjjL1Z4qRwLXfztm3KRvRViNLHvUmd98FtoKh1ko9sZkdEWLIqLzgZHLT4PUa1KKpYtxWYbhrgvay5c3/m3762nruWzYzijz5f/ueFc599uPv7S1d3m9cOXqlV892lTDR6OZsKr+azYX8hr/NFNGvTiItB4sRscSxxt+1Wmk377b9RdRrp6lF++uIY3/wwHz2zq72jXhrtqZR5tlw2C4VSVE5l0sFLtNbvu99u33WnT52p//U4+f+b8TffCulUwWSw+wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNC0wNlQxNjoyMjoxMi0wNDowMPtI+d4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDQtMDZUMTY6MjI6MTItMDQ6MDCKFUFiAAAAAElFTkSuQmCC'/>
                    <Typography display="inline" style={{ paddingRight: 8, verticalAlign: 'middle' }}>Storylect stands with the people of Ukraine!</Typography>
                    <ALink href="/stand-with-ukraine" target="_blank" variant="body1" style={{ color:'yellow', verticalAlign: 'middle', display: 'inline-block' }}>Join us &gt;</ALink>
                </Box>}
                <Toolbar>
                    <div className={classes.logo}>
                        <Link to="/">
                            <img src={`${constants.imagePath}/logo.svg`} alt="storylect logo" className={classes.logoImg} />
                        </Link>
                    </div>
                    {userStore?.isAuthenticated ?
                        <>
                            <div className={classes.desktop}>
                                {userStore.meResolved && userStore.me.publications.length > 0 && <Tooltip title="Dashboard" arrow>
                                    <IconButton color="primary" component={Link} to="/dashboard">
                                        <DashboardIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>}
                                <Tooltip title="User Settings" arrow>
                                    <IconButton component={Link} to="/settings">
                                        <Avatar alt={userStore.me.name} src={userStore.me.avatarUrl}>{userStore.me.avatarAlt}</Avatar>
                                    </IconButton>
                                </Tooltip>
                                <Button onClick={this.logout}>Log Out</Button>
                            </div>
                            <div className={classes.mobile}>
                                <IconButton color="primary" onClick={this.handleMenuOpen}>
                                    <MenuIcon fontSize="large" />
                                </IconButton>
                                <Menu anchorEl={this.menuElement} keepMounted open={!!this.menuElement} onClose={this.handleMenuClose}>
                                    {userStore.meResolved && userStore.me.publications.length > 0 && <MenuItem dense onClick={this.navigateTo('/dashboard')}>
                                        <Typography>Dashboard</Typography>
                                    </MenuItem>}
                                    <MenuItem dense onClick={this.navigateTo('/settings')}>
                                        <Typography>Settings</Typography>
                                    </MenuItem>
                                    <MenuItem dense onClick={this.logout}>
                                        <Typography>Log Out</Typography>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </> :
                        <>
                            <div className={classes.desktop}>
                                <Button component={Link} to={{ pathname: '/login', state: { from: toJS(router?.location) } }} className={classes.menuButton} variant="contained" color="primary">Log In</Button>
                                <Button component={Link} to={{ pathname: '/join', state: { from: toJS(router?.location) } }} variant="contained" color="secondary">Register</Button>
                            </div>
                            <div className={classes.mobile}>
                                <Button component={Link} to={{ pathname: '/login', state: { from: toJS(router?.location) } }} className={classes.menuButton} size="small" variant="contained" color="primary">Log In</Button>
                                <Button component={Link} to={{ pathname: '/join', state: { from: toJS(router?.location) } }} variant="contained" color="secondary" size="small">Register</Button>
                            </div>
                        </>
                    }
                </Toolbar>
            </AppBar>
        </>;
    }

    private handleMenuOpen = (e: React.MouseEvent<HTMLElement>) => this.menuElement = e.currentTarget;
    private handleMenuClose = () => this.menuElement = null;

    private navigateTo = (to: string) => () => {
        this.handleMenuClose();
        this.props.router!.push(to);
    }

    private logout = () => {
        auth.logout();
        window.location.reload();
    }
}

export default withStyles(styles)(Navigation);
