import { ILogger } from './ILogger';
import * as Sentry from '@sentry/browser';
import { isDev } from '../../helpers/isDev';

// for the sake of getting it done faster, lets abstract it in a simple way
class Logger implements ILogger {
    private isDev: boolean;

    constructor() {
        this.isDev = isDev();
    }

    private recordMessage = (message: string, severity: Sentry.Severity) => {
        if (!this.isDev) {
            Sentry.captureMessage(message, severity);
        }
        else {
            console.log('LOGGER:');
            console.log(`${severity}: ${message}`);
        }
    }

    public init = () => {
        if (!this.isDev) {
            Sentry.init({dsn: "https://a04043390aee46669f21cb765f23ccb5@o395893.ingest.sentry.io/5248517"});
        }
    }

    public debug = (message: string) => this.recordMessage(message, Sentry.Severity.Debug);

    public info = (message: string) => this.recordMessage(message, Sentry.Severity.Info);

    public warning = (message: string) => this.recordMessage(message, Sentry.Severity.Warning);

    public error = (message: string) => this.recordMessage(message, Sentry.Severity.Error);

    public fatal = (message: string) => this.recordMessage(message, Sentry.Severity.Fatal);

    public exception = (error: any): string => {
        if (!this.isDev) {
            return Sentry.captureException(error);
        }
        else {
            console.log('LOGGER:');
            console.log(error);
            return 'none';
        }
    }
}

export const logger = new Logger();