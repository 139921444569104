import { marks, nodes } from 'prosemirror-schema-basic'
import { orderedList, bulletList, listItem } from 'prosemirror-schema-list'
import { tableNodes } from 'prosemirror-tables'
import { Schema } from 'prosemirror-model'

const subscript = {
  excludes: 'superscript',
  parseDOM: [
    { tag: 'sub' },
    { style: 'vertical-align=sub' }
  ],
  toDOM: () => ['sub']
}

const superscript = {
  excludes: 'subscript',
  parseDOM: [
    { tag: 'sup' },
    { style: 'vertical-align=super' }
  ],
  toDOM: () => ['sup']
}
const strikethrough = {
  parseDOM: [
    { tag: 'strike' },
    { style: 'text-decoration=line-through' },
    { style: 'text-decoration-line=line-through' }
  ],
  toDOM: () => ['span', {
    style: 'text-decoration-line:line-through'
  }]
}

const underline = {
  parseDOM: [
    { tag: 'u' },
    { style: 'text-decoration=underline' }
  ],
  toDOM: () => ['span', {
    style: 'text-decoration:underline'
  }]
}
const paragraph = {
  group: 'block',
  attrs: {
    class: {
      default: ''
    }
  },
  content: 'inline*',
  toDOM (node: any) {
    return ['p', { class: node.attrs.class }, 0]
  },
  parseDOM: [{
    tag: 'p',
    getAttrs: (node: any) => {
      return {
        textAlign: node.attributes
          ? node.attributes.class
          : node.attrs.class
      }
    }
  }]
}
const heading = {
  attrs: {
    level: {
      default: 1,
    },
    class: {
      default: ''
    },
},
  content: "inline*",
  group: "block",
  parseDOM: [{tag: "h1", attrs: {level: 1}},
             {tag: "h2", attrs: {level: 2}},
             {tag: "h3", attrs: {level: 3}},
             {tag: "h4", attrs: {level: 4}},
             {tag: "h5", attrs: {level: 5}},
             {tag: "h6", attrs: {level: 6}}],
  toDOM: (node:any) => { return ["h" + node.attrs.level, { class: node.attrs.class }, 0]},
}
const link = {
       attrs: {
         href: {default: '/'},
         title: {default: null},
         target: {default: '_blank'},
         rel: {default: 'noopener nofollow'}
       },
       inclusive: false,
       parseDOM: [{tag: 'a[href]', getAttrs(dom: HTMLAnchorElement) {
         return {
             href: dom.getAttribute('href'),
             title: dom.getAttribute('title'),
             target: dom.getAttribute('target'),
             rel: dom.getAttribute('rel')
           };
       }}],
       toDOM(node: any) {
           const {href, title, target, rel} = node.attrs;
           return ['a', {href, title, target, rel}, 0];
       }
   }
const listNodes = {
  ordered_list: {
    ...orderedList,
    content: 'list_item+',
    group: 'block'
  },
  bullet_list: {
    ...bulletList,
    content: 'list_item+',
    group: 'block'
  },
  list_item: {
    ...listItem,
    content: 'paragraph block*'
  }
}

const _nodes = {
  ...nodes,
  ...listNodes,
  ...tableNodes({
    tableGroup: 'block',
    cellContent: 'block+',
    cellAttributes: {
      background: {
        default: null,
        getFromDOM(dom: any) { return dom.style.backgroundColor || null },
        setDOMAttr(value, attrs) { if (value) attrs.style = (attrs.style || "") + `background-color: ${value};` }
      },
    },
  }),
  paragraph,
  heading,
}
const _marks = {
  ...marks,
  subscript,
  superscript,
  strikethrough,
  underline,
  link,
}

export default new Schema({ nodes: _nodes, marks: _marks })
