import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { observer, inject } from 'mobx-react';
import * as React from 'react';
import { Container, Grid, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, Tab, Divider, Button, CircularProgress, Box } from '@material-ui/core';
import { RootStore } from '../../stores/RootStore';
import { RouterStore } from '../../stores/RouterStore';
import { PageLoader } from '../../shared/PageLoader';
import { NotificationType, Plan, SubscriberPlan, User } from '../../models';
import { UserStore } from '../../stores/UserStore';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import PasswordSettings from './PasswordSettings';
import ProfileSettings from './ProfileSettings';
import NotificationSettings from './NotificationSettings';
import { StripeStatus, UserType } from '../../models/User';
import { auth } from '../../services/auth/Auth';
import PublicationSettings from './PublicationSettings';
import { observable } from 'mobx';
import { RouteComponentProps } from 'react-router-dom';
import SubscriptionSettings from './SubscriptionSettings';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(2, 3, 3)
    },
    submitting: {
        marginLeft: theme.spacing(1)
    },
    tab: {
        textTransform: 'capitalize'
    },
    tabPanel: {
        padding: theme.spacing(3, 0)
    }
});

interface MatchParams {
    page: string;
};

interface UserSettingsProps extends WithStyles<typeof styles>, RouteComponentProps<MatchParams> {
    router: RouterStore;
    userStore: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class UserSettings extends React.Component<UserSettingsProps> {
    private allPages = ['account', 'notifications', 'publications', 'subscriptions'];

    @observable private goingToStripe: boolean = false;

    public render() {
        const { classes, userStore, match } = this.props;

        if (!userStore.meResolved)
            return <PageLoader />;
        
        // filter out allowed pages depending on different conditions
        let pages = this.allPages;
        if (!userStore.me.subscriptions.length) {
            pages = pages.filter(p => p != 'subscriptions');
        }
        if (!userStore.me.publications.length) {
            pages = pages.filter(p => p != 'publications');
        }

        const page = (!match.params.page || !pages.includes(match.params.page)) ? 'account' : match.params.page;

        return <Container className={classes.root}>
            <TabContext value={page}>
                <Paper>
                    <TabList onChange={this.handleTabChange} variant="scrollable" scrollButtons="on">
                        {pages.map(p => <Tab key={p} label={p} value={p} className={classes.tab} />)}
                    </TabList>
                </Paper>
                <TabPanel value="account" className={classes.tabPanel}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <ProfileSettings user={userStore.me} onSave={this.profileUpdated} onImageUpload={this.imageUploaded} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PasswordSettings isSet={userStore.me.hasPassword} onSuccess={this.passwordUpdated} />
                            {(userStore.me.publications.length > 0 && userStore.me.stripeStatus !== StripeStatus.Ready) &&
                                <Box mt={3}>
                                    <Paper className={classes.paper}>
                                        <Typography variant="h6" gutterBottom>Start monetizing</Typography>
                                        {this.renderPaymentMessage()}
                                        <Button variant="contained" color="primary" onClick={this.connectWithStripe} disabled={this.goingToStripe}>
                                            {userStore.me.stripeStatus === StripeStatus.Initiated ? 'Continue' : 'Connect'} with Stripe
                                            {this.goingToStripe && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                                        </Button>
                                    </Paper>
                                </Box>
                            }
                            {userStore.me.stripeStatus === StripeStatus.Ready &&
                                <Box mt={3}>
                                    <Paper className={classes.paper}>
                                        <Typography variant="h6" paragraph>View your Stripe Dashboard</Typography>
                                        <Button variant="contained" color="primary" onClick={this.loginToStripeExpress} disabled={this.goingToStripe}>
                                            Log{this.goingToStripe ? 'ging' : ''} In
                                            {this.goingToStripe && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                                        </Button>
                                    </Paper>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value="notifications" className={classes.tabPanel}>
                    <NotificationSettings userId={userStore.me.id} isWriter={userStore.me.types?.includes(UserType.Writer)} unsubscribes={userStore.me.unsubscribes} onSave={this.emailSettingsUpdated} />
                </TabPanel>
                {userStore.me.publications.length > 0 &&
                    <TabPanel value="publications" className={classes.tabPanel}>
                        <PublicationSettings stripeStatus={userStore.me.stripeStatus} publications={userStore.me.publications} onSave={this.publicationUpdated} onDelete={this.publicationDeleted} setStripeReady={this.setStripeReady} />
                    </TabPanel>
                }
                {userStore.me.subscriptions.length > 0 &&
                    <TabPanel value="subscriptions" className={classes.tabPanel}>
                        <SubscriptionSettings subscriptions={userStore.me.subscriptions} onUnsubscribe={this.onUnsubscribe} onPlanUpdate={this.onPlanUpdate} />
                    </TabPanel>
                }
            </TabContext>
        </Container>;
    }

    private renderPaymentMessage = () => {
        const { userStore } = this.props;

        if (userStore.me.stripeStatus === StripeStatus.New) {
            return <Typography variant="body2" paragraph>We partner with Stripe for fast and secure payments. Set up your Stripe account to start monetizing your work.</Typography>;
        }
        else if (userStore.me.stripeStatus === StripeStatus.Initiated) {
            return <Typography variant="body2" paragraph>You haven't finished setting up your Stripe account. Please complete it to start monetizing your work.</Typography>;
        }
        else {
            return null;
        }
    }

    private handleTabChange = (e: React.ChangeEvent<{}>, value: string) => this.props.router.push(`/settings/${value}`);;

    private onUnsubscribe = (publicationId: number) => {
        this.props.userStore.deleteSubscription(publicationId);
    }

    private onPlanUpdate = (publicationId: number, subscriberPlan: SubscriberPlan) => {
        this.props.userStore.updateSubscriberPlan(publicationId, subscriberPlan);
    }

    private profileUpdated = (user: User) => {
        this.props.userStore.me = Object.assign(this.props.userStore.me, user);
    }

    private emailSettingsUpdated = (unsubscribes: NotificationType[]) => {
        this.props.userStore.me.unsubscribes = unsubscribes;
    }

    private imageUploaded = (url: string) => {
        this.props.userStore.me.avatarUrl = url;
    }

    private passwordUpdated = () => {
        this.props.userStore.me.hasPassword = true;
    }

    private publicationUpdated = (publicationId: number, plans: Plan[]) => {
       this.props.userStore.updatePublicationPlans(publicationId, plans);
    }

    private publicationDeleted = (publicationId: number) => {
        this.props.userStore.deletePublication(publicationId);
    }

    private setStripeReady = () => {
        this.props.userStore.me.stripeStatus = StripeStatus.Ready;
    }

    private connectWithStripe = async () => {
        this.goingToStripe = true;

        try {
            const response = await auth.fetch('/api/payments/stripe/onboard', { method: 'POST' });

            if (response.status === 200) {
                const { url } = await response.json();
                window.location.href = url;
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }
        finally {
            this.goingToStripe = false;
        }
    }

    private loginToStripeExpress = async () => {
        this.goingToStripe = true;

        try {
            const response = await auth.fetch('/api/payments/stripe/login', { method: 'POST' });

            if (response.status === 200) {
                const { url } = await response.json();
                //window.location.href = url;
                window.open(url, '_blank');
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }
        finally {
            this.goingToStripe = false;
        }
    }
}

export default withStyles(styles)(UserSettings);
