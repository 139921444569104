import { setBlockType, toggleMark, wrapIn } from 'prosemirror-commands';
import { redo, undo } from 'prosemirror-history';
import { wrapInList } from 'prosemirror-schema-list';
import schema from "./storylect-schema";
import { image } from '../../services/image/Image';
import { auth } from '../../services/auth/Auth';
import icons from './icons';

const markActive = (type: any) => (_state: any) => {
    if (!_state) return;
    const { from, $from, to, empty } = _state.state.selection
    return empty
        ? type.isInSet(_state.state.storedMarks || $from.marks())
        : _state.state.doc.rangeHasMark(from, to, type)
}

const blockActive = (type: any, attrs = {}) => (view: any) => {
    if (!view) return;
    const { $from, to, node } = view.state.selection

    if (node) {
        return node.hasMarkup(type, attrs)
    }

    return to <= $from.end() && $from.parent.hasMarkup(type, attrs)
}
const getSelectionObject = function (selection: any) {
    const { $anchor, $head } = selection;
    return {
        from: $anchor.pos <= $head.pos ? $anchor.parent : $head.parent,
        to: $head.pos > $anchor.pos ? $head.parent : $anchor.parent,
        fromFound: false,
        toFound: false
    }
}
const Helper = function () {
    return {
        findNodeBySelection(_state: any, dispatch: any, className: string) {
            if (_state) {
                const state = _state;
                let { from, to, fromFound, toFound } = getSelectionObject(state.selection)
                const commands: any[] = []
                let transaction = state.tr;
                state.doc.descendants((node: any, pos: number) => {
                    if (!node.isInline && (node === from || (fromFound && !toFound))) {
                        if (!commands.filter(({ type }) => type === node.type.name).length) {
                            const command = { node, class: className, pos }
                            commands.push(command)
                        }
                        fromFound = true
                    }
                    toFound = toFound || (from === to || node === to)
                })
                commands.forEach((element: any) => {
                    let attrs = { ...element.node.attrs, 'class': element.class };
                    transaction.setNodeMarkup(element.pos, null, attrs)
                });
                dispatch(transaction);
            }
        }
    }
}

function setSelectedNodeClasses(className: string, state: any, dispatch: any) {
    const helper = Helper();
    return helper.findNodeBySelection(state, dispatch, className);
}

const promptForURL = () => {
    let url = window && window.prompt('Enter the URL', 'https://')

    if (url && !/^https?:\/\//i.test(url)) {
        url = 'http://' + url
    }

    return url
}

const buildFileSelector = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('id', 'hiddenFilePicker');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('display', 'none')
    fileSelector.setAttribute('accept', 'image/*')
    fileSelector.style.display = "none";
    document.body.appendChild(fileSelector);
    return fileSelector;
}

export default {
    marks: {
        strong: {
            title: 'Bold',
            content: icons.strong,
            active: markActive(schema.marks.strong),
            run: toggleMark(schema.marks.strong)
        },
        em: {
            title: 'Italic',
            content: icons.em,
            active: markActive(schema.marks.em),
            run: toggleMark(schema.marks.em)
        },
        underline: {
            title: 'Underline',
            content: icons.underline,
            active: markActive(schema.marks.underline),
            run: toggleMark(schema.marks.underline),
        },
        strikethrough: {
            title: 'Strikethrough',
            content: icons.strikethrough,
            active: markActive(schema.marks.strikethrough),
            run: toggleMark(schema.marks.strikethrough)
        },
        code: {
            title: 'Inline Code',
            content: icons.code,
            active: markActive(schema.marks.code),
            run: toggleMark(schema.marks.code)
        },
        subscript: {
            title: 'Subscript',
            content: icons.subscript,
            active: markActive(schema.marks.subscript),
            run: toggleMark(schema.marks.subscript)
        },
        superscript: {
            title: 'Superscript',
            content: icons.superscript,
            active: markActive(schema.marks.superscript),
            run: toggleMark(schema.marks.superscript)
        },
        link: {
            title: 'Add or remove link',
            content: icons.link,
            enable: (editorView: any) => {
                if (!editorView || !editorView.state) return false;
                return !editorView.state.selection.empty;
            },
            run: (state: any, dispatch: any) => {
                const href = promptForURL()
                if (!href) return false
                toggleMark(schema.marks.link, { href })(state, dispatch)
            }
        }
    },
    alignments: {
        alignLeft: {
            title: 'Align left',
            content: icons.alignLeft,
            run: (state: any, dispatch: any) => setSelectedNodeClasses('sl--align-left', state, dispatch)
        },
        alignCenter: {
            title: 'Align center',
            content: icons.alignCenter,
            run: (state: any, dispatch: any) => setSelectedNodeClasses('sl--align-center', state, dispatch)
        },
        alignRight: {
            title: 'Align right',
            content: icons.alignRight,
            run: (state: any, dispatch: any) => setSelectedNodeClasses('sl--align-right', state, dispatch)
        },
        alignJustify: {
            title: 'Justify',
            content: icons.alignJustify,
            run: (state: any, dispatch: any) => setSelectedNodeClasses('sl--align-justify', state, dispatch)
        },
    },
    blocks: {
        headings: {
            title: 'Text Size',
            type: 'headings',
            content: icons.heading,
            active: blockActive(schema.nodes.heading, { level: 1 }), // [DN] this should be blockActive(schema.nodes.paragraph) for level 0
            run: (level: number, state: any, dispatch: any) => {
                return level === 0 ?
                    setBlockType(schema.nodes.paragraph)(state, dispatch) :
                    setBlockType(schema.nodes.heading, { level })(state, dispatch);
            }
        },
        code_block: {
            title: 'Code Block',
            content: icons.code_block,
            active: blockActive(schema.nodes.code_block),
            run: setBlockType(schema.nodes.code_block)
        },
        blockquote: {
            title: 'Quote',
            content: icons.blockquote,
            active: blockActive(schema.nodes.blockquote),
            run: wrapIn(schema.nodes.blockquote)
        },
        bullet_list: {
            title: 'Bulleted list',
            content: icons.bullet_list,
            active: blockActive(schema.nodes.bullet_list),
            run: wrapInList(schema.nodes.bullet_list)
        },
        ordered_list: {
            title: 'Numbered list',
            content: icons.ordered_list,
            active: blockActive(schema.nodes.ordered_list),
            run: wrapInList(schema.nodes.ordered_list)
        },
        // lift: {
        //   title: 'Lift out of enclosing block',
        //   content: icons.lift,
        //   run: lift
        // },
        // join_up: {
        //   title: 'Join with above block',
        //   content: icons.join_up,
        //   run: joinUp
        // }
    },
    insert: {
        image: {
            title: 'Insert image',
            content: icons.image,
            run: (state: any, dispatch: any) => {
                const fileSelector = buildFileSelector()
                const getPreview = async (e: any) => {
                    const file = e.target.files![0];
                    const src = image.getPreviewUrl(file);
                    const img = schema.nodes.image.createAndFill({ src });

                    //[DN] TODO: how to show preview image (img) before replacing it with a real one after it is uploaded????
                    //[DN] although it's pretty fast without it, but if we can figure it out - the faster the better I guess...
                    //dispatch(state.tr.replaceSelectionWith(img));

                    try {
                        const formData = new FormData();
                        formData.append('file', file);

                        const response = await auth.fetchWithFile('/api/posts/image', {
                            method: 'POST',
                            body: formData
                        });
                        if (response.status === 200) {
                            const { url } = await response.json();
                            const replacement = img.type.create({ src: url });
                            dispatch(state.tr.replaceSelectionWith(replacement));
                        }
                    }
                    catch (error) {
                        console.log(error);
                    }
                    finally {
                        image.releasePreviewUrl(src);
                    }
                }
                fileSelector.addEventListener("change", getPreview, false);
                fileSelector.click();
            }
        },
        table: {
            title: 'Insert table',
            type: 'table',
            content: icons.table,
            run: (state: any, dispatch: any) => {
                let rowCount = (window && window.prompt('How many rows?', '2')) || '2';
                let colCount = (window && window.prompt('How many columns?', '2')) || '2';
                let _colCount = parseInt(colCount, 10);
                let _rowCount = parseInt(rowCount, 10);

                const cells = []
                while (_colCount--) {
                    cells.push(schema.nodes.table_cell.createAndFill())
                }

                const rows = []
                while (_rowCount--) {
                    rows.push(schema.nodes.table_row.createAndFill(null, cells))
                }

                const table = schema.nodes.table.createAndFill(null, rows)
                dispatch(state.tr.replaceSelectionWith(table))
            }
        }
    },
    history: {
        undo: {
            title: 'Undo',
            content: icons.undo,
            enable: (editorView: any) => {
                if (!editorView || !editorView.state) return false;
                return undo(editorView.state);
            },
            run: undo
        },
        redo: {
            title: 'Redo',
            content: icons.redo,
            enable: (editorView: any) => {
                if (!editorView || !editorView.state) return false;
                return redo(editorView.state);
            },
            run: redo
        }
    }
}
