import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { RouterStore } from '../../stores/RouterStore';
import { RootStore } from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { Container, Typography, Box, Avatar, Card, CardActionArea, CardMedia, CardContent, CardActions, Grid, CardHeader, withWidth, WithWidth, isWidthUp, Tooltip } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { observable } from 'mobx';
import { PageLoader } from '../../shared/PageLoader';
import { auth } from '../../services/auth/Auth';
import { Post, Publication, SubscriberPlan, Subscription } from '../../models';
import { UserStore } from '../../stores/UserStore';
import { constants } from '../../helpers/constants';
import moment from 'moment';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import { MetricType } from '../../models/MetricType';
import Like from '../../shared/Like';
import { Helmet } from 'react-helmet';
import Share from '../../shared/Share';
import { SubscriptionElement } from '../../shared/SubscriptionElement';
import { PaidContent } from '../../shared/icons/PaidContent';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    avatar: {
        width: 120,
        height: 120
    },
    posts: {

    },
    media: {
        height: 200
    },
    categoryChip: {
        marginRight: theme.spacing(1)
    },
    reactionCount: {
        marginLeft: theme.spacing(0.5)
    },
    reaction: {
        marginLeft: theme.spacing(2)
    },
    cardFooter: {
        padding: theme.spacing(2),
        alignItems: 'flex-end'
    },
    cardAction: {
        margin: 0,
        lineHeight: 0
    }
});

interface MatchParams {
    publicationSlug: string;
};

interface PublicationDetailsProps extends WithStyles<typeof styles>, WithWidth, RouteComponentProps<MatchParams> {
    router: RouterStore;
    userStore: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class PublicationDetails extends React.Component<PublicationDetailsProps> {
    @observable private publication: Publication = {} as Publication;
    @observable private loading: boolean = true;

    public render() {
        const { classes, width, userStore } = this.props;

        if (this.loading)
            return <PageLoader />;

        return <Container className={classes.root}>
            <Helmet>
                <title>{this.publication.name} - Storylect</title>
                <meta name="description" content={this.publication.description} />
                <meta property="og:title" content={this.publication.name} />
                <meta property="og:description" content={this.publication.description} />
                {this.publication.images?.banner && <meta property="og:image" content={this.publication.images.banner} />}
            </Helmet>
            <Box display="flex" alignItems="center" flexDirection={{ xs: 'column', lg: 'row' }} mb={5}>
                <Box display="flex" alignItems="center" flexGrow="1" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Box mr={{ sm: 3 }} position="relative">
                        <Avatar alt={this.publication.name} src={this.getAvatarUrl()} className={classes.avatar} />
                        <Box position="absolute" bottom={{ xs: 32, sm: 42 }} right={{ xs: 42, sm: 32 }}>
                            <Share direction={isWidthUp('sm', width) ? 'right' : 'down'} tooltipPlacement={isWidthUp('sm', width) ? 'bottom' : 'right'} gaCategory="Publication Share" gaLabel={this.publication.slug} />
                        </Box>
                    </Box>
                    <Box mt={{ xs: 2, sm: 0 }}>
                        <Typography variant="h5" component="h1">{this.publication.name}</Typography>
                        <Typography variant="subtitle1">{this.publication.description}</Typography>
                        <Typography variant="caption" color="textSecondary" component="div" gutterBottom>
                            by {this.publication.authors.map(a => a.name).join(", ")}
                        </Typography>
                        {/* {this.publication.categories.map(c => <Chip label={c} size="small" color="primary" variant="outlined" className={classes.categoryChip} />)} */}
                    </Box>
                </Box>
                <Box mt={{ xs: 2, lg: 0 }} ml={{ lg: 2 }}>
                    <SubscriptionElement publication={this.publication} user={userStore.me} authed={userStore.isAuthenticated} onSuccess={this.onSubscribe} onPlanSuccess={this.onPlanSubscribe} />
                </Box>
            </Box>

            <Grid container spacing={4} className={classes.posts}>
                {this.publication.posts && this.publication.posts.map(p =>
                    <Grid key={p.id} item xs={12} md={6}>
                        {this.renderPost(p)}
                    </Grid>
                )}
            </Grid>
        </Container>;
    }

    // don't really need to await anything, let it load
    public componentDidMount() {
        this.loadPublication();
    }

    private renderPost = (post: Post) => {
        const { classes, userStore } = this.props;

        return <Card>
            <CardHeader
                avatar={
                    <Avatar alt={post.authors[0].name} src={post.authors[0].avatarUrl}>{post.authors[0].avatarAlt}</Avatar>
                }
                title={post.authors[0].name}
                subheader={moment(post.createdOn).format('ll')}
                action={!post.public && <Tooltip arrow title="Paid Content"><span><PaidContent viewBox="0 0 70 70" style={{ fontSize: 40 }} /></span></Tooltip>}
                classes={{
                    action: classes.cardAction
                }}
            />
            <CardActionArea onClick={this.navigateToPost(post.slug)}>
                {post.images?.banner &&
                    <CardMedia
                        className={classes.media}
                        image={post.images?.banner}
                        title={post.title}
                    />
                }
                <CardContent>
                    <Typography gutterBottom variant="h5">
                        {post.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {post.subtitle}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardFooter} disableSpacing>
                <Like postId={post.id}
                      count={post.metrics[MetricType.Like]}
                      liked={post.reactions[MetricType.Like]?.includes(userStore.me.id)}
                      actionable={auth.isLoggedIn}
                />
                <Box display="inline-flex" alignItems="center" className={classes.reaction}>
                    <ModeCommentIcon color="action" fontSize="small" />
                    <Typography className={classes.reactionCount}>{post.metrics[MetricType.Comment] || 0}</Typography>
                </Box>
            </CardActions>
        </Card>;
    }


    private getAvatarUrl = () => this.publication.images?.avatar || constants.defaultPublicationAvatarUrl;

    private navigateToPost = (slug: string) => () => this.props.router.push(`/${this.publication.slug}/${slug}`);

    private loadPublication = async () => {
        try {
            let response = await auth.fetch(`/api/publications/${this.props.match.params.publicationSlug}`);
            if (response.status === 200) {
                this.publication = await response.json() as Publication;
                this.loading = false; // [DN] we may want to finish loading here to get something to the user while posts are loading

                // load posts for the publication
                response = await auth.fetch(`/api/publications/${this.publication.id}/posts`);
                this.publication.posts = await response.json() as Post[];
            }
            else {
                this.props.router.replace('/');
            }
        }
        catch (error) {
            // handle error!
            console.log(error);
            this.loading = false;
        }
        finally {
            // this.loading = false;
        }
    }

    private onSubscribe = async (token: string) => {
        const { userStore } = this.props;

        // if token is present, this is a new user, make sure to log them in (new subscription will already be included since it makes DB call to get current user)
        if (token) {
            await userStore.authenticate(token);
        }
        else {
            userStore.addSubscription(this.publication as Subscription);
        }

        return Promise.resolve();
    }

    private onPlanSubscribe = async (subscriberPlan: SubscriberPlan) => {
        const { userStore } = this.props;

        userStore.addSubscriberPlan(this.publication.id, subscriberPlan);
    }
}

export default withWidth()(withStyles(styles)(PublicationDetails));
