import React from 'react'
import map from 'lodash/map'
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
// import { MenuItem, Dropdown } from "prosemirror-menu"
import {
    addColumnAfter, addColumnBefore, deleteColumn, addRowAfter, addRowBefore, deleteRow,
    mergeCells, splitCell, toggleHeaderRow, toggleHeaderColumn, toggleHeaderCell,
    deleteTable
} from 'prosemirror-tables';
import { Typography } from '@material-ui/core';
import { logger } from '../../services/logging/Logger';

const styles = (theme: Theme) => createStyles({
    buttonContainer: {
        '& button': {
            display: 'flex',
            backgroundColor: 'white',
            cursor: 'pointer',
            border: 'none',
            borderRadius: theme.shape.borderRadius,
            marginRight: theme.spacing(0.75),
            position: 'relative',
            padding: theme.spacing(0.5),
            '&:hover': {
                backgroundColor: '#E3E3E3',
                '& .hidden': {
                    display: 'block',
                },
            },
            '&[disabled]:hover': {
                backgroundColor: 'inherit',
                cursor: 'auto'
            }
        },
        '& .table': {
            marginRight: theme.spacing(0.75),
            position: 'relative',
            cursor: 'pointer',
            '& button': {
                marginRight: 0
            },
            '&:hover': {
                '& .hidden': {
                    display: 'block',
                },
            }
        },
        '& .hidden': {
            textAlign: 'left',
            top: '14px',
            left: '-25px',
            width: 'fit-content',
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: theme.shape.borderRadius,
            display: 'none',
            position: 'absolute',
            backgroundColor: 'white',
            padding: 0,
            listStyleType: 'none',
            zIndex: '2',
            whiteSpace: 'nowrap',
            '& li': {
                padding: theme.spacing(0.25, 1.5),
                '&:hover': {
                    backgroundColor: '#D3D3D3',
                }
            }
        },
        '& .banner.active': {
            backgroundColor: 'grey',
        },
        display: 'flex',
        position: 'sticky' as 'sticky', // DC: have no idea wtf is going on with TS
        top: 116,
        border: '1px solid #ccc',
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.shape.borderRadius,
        justifyContent: 'center',
        backgroundColor: 'white',
        flexWrap: 'wrap'
    },
    groupContainer: {
        display: 'flex',
        //margin: theme.spacing(0.5, 0),
        //borderRight: '1px solid',
        // '& button:first-child': {
        //   marginLeft: '5px',
        // }
    },
});

const tableMenu = [
    { title: "Insert column before", cmd: addColumnBefore },
    { title: "Insert column after", cmd: addColumnAfter },
    { title: "Insert row before", cmd: addRowBefore },
    { title: "Insert row after", cmd: addRowAfter },
    { title: "Delete column", cmd: deleteColumn },
    { title: "Delete row", cmd: deleteRow },
    { title: "Delete table", cmd: deleteTable },
    { title: "Merge cells", cmd: mergeCells },
    { title: "Split cell", cmd: splitCell },
    { title: "Toggle header column", cmd: toggleHeaderColumn },
    { title: "Toggle header row", cmd: toggleHeaderRow },
    { title: "Toggle header cells", cmd: toggleHeaderCell },
    // item("Make cell green", setCellAttr("background", "#dfd")),
    // item("Make cell not-green", setCellAttr("background", null))
];

const Button = (editorView: any) => (item: any, key: any) => {
    if (item.type === 'headings') {
        const numbers = [0, 1, 2, 3, 4, 5, 6];
        const listItems = numbers.map((number) =>
            <li key={number.toString()} onMouseDown={e => {
                try {
                    e.preventDefault();
                    item.run(number, editorView.state, editorView.dispatch);
                }
                catch (error) {
                    logger.exception(error);
                }
            }}>
                <Typography variant="body2">{number === 0 ? 'Normal' : `Heading ${number}`}</Typography>
            </li>
        );
        return <button
            key={key}
            title={item.title}>
            {item.content}
            <ul className="hidden">{listItems}</ul>
        </button>;
    }
    if (item.type === 'table') {
        const listItems = tableMenu.map((tableLine) =>
            <li key={tableLine.title} onMouseDown={e => {
                try {
                    e.preventDefault();
                    tableLine.cmd(editorView.state, editorView.dispatch);
                }
                catch (error) {
                    logger.exception(error);
                }
            }}>
                <Typography variant="body2">{tableLine.title}</Typography>
            </li>
        );
        return <div className='table' key={key}>
            <button
                title={item.title}
                onMouseDown={e => {
                    try {
                        e.preventDefault();
                        item.run(editorView.state, editorView.dispatch);
                    }
                    catch (error) {
                        logger.exception(error);
                    }
                }}>
                {item.content}
            </button>
            <ul className="hidden">{listItems}</ul>
        </div>
    }
    return <button
        key={key}
        type={'button'}
        title={item.title}
        className={`banner ${item.active && item.active(editorView) ? "active" : ""}`}
        disabled={item.enable && !item.enable(editorView)}
        onMouseDown={e => {
            try {
                e.preventDefault();
                item.run(editorView.state, editorView.dispatch);
            }
            catch (error) {
                logger.exception(error);
            }
        }}>{item.content}</button>
}

const MenuBar = ({ menu, children, view, classes }: { menu: any; children?: any, view?: any, classes?: any }) => {
    return <div className={classes.buttonContainer}>
        {children && (
            <span className="group-button">
                {children}
            </span>
        )}
        {map(menu, (item, key) => {
            return <div className={classes.groupContainer} key={key}>
                {map(item, Button(view))}
            </div>
        })}
    </div>
}

export default withStyles(styles)(MenuBar)
