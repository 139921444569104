import * as React from 'react';
import { Route, RouteComponentProps, Switch, withRouter, Redirect } from 'react-router';
import Home from './pages/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import LogIn from './pages/LogIn/LogIn';
import { ProtectedRoute } from './shared/ProtectedRoute';
import { auth } from './services/auth/Auth';
import Register from './pages/Register/Register';
import NewPublication from './pages/Register/NewPublication';
import UserSettings from './pages/User/Settings';
import PublicationDetails from './pages/Publication/PublicationDetails';
import PostDetails from './pages/Post/PostDetails';
import NewPost from './pages/Dashboard/NewPost';
import EditPost from './pages/Dashboard/EditPost';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import AboutUs from './pages/About';
import { Helmet } from 'react-helmet';
import { constants } from './helpers/constants';
import { UserStore } from './stores/UserStore';
import { inject } from 'mobx-react';
import { RootStore } from './stores/RootStore';
import EmailVerification from './pages/LogIn/EmailVerification';
import EmailSettings from './pages/User/EmailSettings';
import StripeOnboardingRefresh from './pages/User/StripeOnboardingRefresh';
import AdminDashboard from './pages/Admin/Dashboard';
import Emails from './pages/Admin/Emails';
import StandWithUkraine from './pages/StandWithUkraine';

interface RoutesProps extends RouteComponentProps<{}> {
    userStore?: UserStore;
}

@inject((stores: RootStore) => ({
    userStore: stores.userStore
}))
class Routes extends React.Component<RoutesProps> {
    public render() {
        //const isLoggedIn = auth.isLoggedIn;
        const isLoggedIn = this.props.userStore?.isAuthenticated;

        return <>
            <Helmet>
                <title>Free Publishing Platform for Writing and Journalism - Storylect</title>
                <meta name="description" content="Storylect makes it easy for independent writers, journalists and bloggers to publish their writing, build audience and monetize via subscriptions. All great stories start here." />
                <meta property="og:title" content="Free Publishing Platform for Writing and Journalism - Storylect" />
                <meta property="og:description" content="Storylect makes it easy for independent writers, journalists and bloggers to publish their writing, build audience and monetize via subscriptions. All great stories start here." />
                <meta property="og:image" content={`${constants.imagePath}/hero-1920x1280.jpg`} />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Switch>
                <Route exact path="/" component={Home} />

                {/* if user is logged in, we don't want to have login and register routes at all */}
                {!isLoggedIn && <Route path="/login" component={LogIn} />}
                {!isLoggedIn && <Route path="/join" component={Register} />}
                <Route path="/verify-email" component={EmailVerification} />

                <Route path="/privacy" component={Privacy} />
                <Route path="/terms" component={Terms} />
                <Route path="/about" component={AboutUs} />
                <Route path="/stand-with-ukraine" component={StandWithUkraine} />
                <Route path="/email-settings/:token?" component={EmailSettings} />
                <ProtectedRoute path="/ground-control/emails" isInternal={true} component={Emails} />
                <ProtectedRoute path="/ground-control" isInternal={true} component={AdminDashboard} />
                <ProtectedRoute path="/dashboard" component={Dashboard} />
                <ProtectedRoute path="/settings/stripe-onboarding-refresh" component={StripeOnboardingRefresh} />
                <ProtectedRoute path="/settings/:page?" component={UserSettings} />
                <ProtectedRoute path="/new-publication" component={NewPublication} />
                <ProtectedRoute path="/new-post" component={NewPost} />
                <ProtectedRoute path="/post/:id" component={EditPost} />
                <Route exact path="/:publicationSlug" component={PublicationDetails} />
                <Route exact path="/:publicationSlug/:postSlug" component={PostDetails} />

                {/* this is catch-all route that redirects to home page */}
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </>;
    }

    public componentDidUpdate(prevProps: RoutesProps) {
        // every time route changes check whether user is still logged in, if not remove him from the store and un-authenticate 
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (!auth.isLoggedIn) {
                this.props.userStore?.deleteMe();
            }
        }
    }
}

export default withRouter(Routes);
