import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import { observer, inject } from 'mobx-react';
import * as React from 'react';
import { observable, toJS, when } from 'mobx';
import { Container, TextField, InputAdornment, Grid, CircularProgress, Button, Paper, Tooltip, Box, List, ListItem, ListItemIcon, ListItemText, Link } from '@material-ui/core';
import { RootStore } from '../../stores/RootStore';
import { RouterStore } from '../../stores/RouterStore';
import { auth } from '../../services/auth/Auth';
import { NewPublicationRequest } from './NewPublicationRequest';
import { PageLoader } from '../../shared/PageLoader';
import { validation } from '../../helpers/validation';
import { UserStore } from '../../stores/UserStore';
import { constants } from '../../helpers/constants';
import DoneIcon from '@material-ui/icons/Done';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 3, 3)
    },
    error: {
        marginLeft: theme.spacing(2)
    },
    inputAdornment: {
        marginRight: 0
    },
    submitting: {
        marginLeft: theme.spacing(1)
    },
    icon: {
        minWidth: 'auto',
        marginRight: theme.spacing(1)
    }
});

interface NewPublicationProps extends WithStyles<typeof styles> {
    router: RouterStore;
    userStore: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class NewPublication extends React.Component<NewPublicationProps> {
    @observable private form: NewPublicationRequest = {} as NewPublicationRequest;
    @observable private errors: Map<string, string> = new Map(); // key is field name, value is error message
    @observable private submitting: boolean = false;

    constructor(props: NewPublicationProps) {
        super(props);

        //once authenticated user resolves, check if we need to redirect
        when(() => props.userStore.meResolved, this.onMeResolved);
    }

    public render() {
        const { classes, userStore } = this.props;

        if (!userStore.meResolved)
            return <PageLoader />;

        return <Container className={classes.root} maxWidth="md">
            <Paper className={classes.paper}>
                <Typography variant="h5" paragraph>Create your publication</Typography>
                <Box p={2} border={1} borderColor="grey.300" borderRadius="borderRadius" mb={1.5}>
                    <Typography variant="body2">Before you can start writing you need to create a publication. Think of it as your own online periodical that your readers can subscribe to. <Link href="/blog/planning-online-publication" target="_blank">Learn how</Link> to create a successful publication.</Typography>
                    <List dense>
                        <ListItem>
                            <ListItemIcon className={classes.icon}><DoneIcon fontSize="small" color="primary" /></ListItemIcon>
                            <ListItemText primary="no membership fees, no limits, no risk"/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon className={classes.icon}><DoneIcon fontSize="small" color="primary" /></ListItemIcon>
                            <ListItemText primary="make money via your subscriptions"/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon className={classes.icon}><DoneIcon fontSize="small" color="primary" /></ListItemIcon>
                            <ListItemText primary="you are fully in control of what to write about"/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon className={classes.icon}><DoneIcon fontSize="small" color="primary" /></ListItemIcon>
                            <ListItemText primary="mix and match free and paid content"/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon className={classes.icon}><DoneIcon fontSize="small" color="primary" /></ListItemIcon>
                            <ListItemText primary="rich editor to customize your articles the way you want them"/>
                        </ListItem>
                    </List>
                    <Typography variant="caption">And don’t worry, you can edit publication info later, if need be (<strong>with the exception of URL</strong>).</Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField name="name" label="Publication Name" placeholder="Make it short and catchy" fullWidth value={this.form.name || ''} onChange={this.handleChange}
                            error={this.errors.has('name')} helperText={this.errors.get('name')} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="description" label="Short Description" placeholder="What's your publication about?" fullWidth value={this.form.description || ''} onChange={this.handleChange}
                            error={this.errors.has('description')} helperText={this.errors.get('description')} />
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip title="You won't be able to change this once you create your publication." arrow>
                            <TextField name="slug" label="Unique URL" fullWidth value={this.form.slug || ''} onChange={this.handleChange}
                                InputProps={{
                                    startAdornment: <InputAdornment disablePointerEvents className={classes.inputAdornment} position="start">https://storylect.com/</InputAdornment>
                                }}
                                error={this.errors.has('slug')} helperText={this.errors.get('slug')} />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="authorName" label="Author's Name" placeholder="Your name or pseudonym" fullWidth value={this.form.authorName || ''} onChange={this.handleChange}
                            error={this.errors.has('authorName')} helperText={this.errors.get('authorName')} />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete multiple freeSolo value={this.form.categories || []} onChange={this.handleCategoriesChange}
                            options={constants.categories}
                            renderInput={params =>
                                <TextField {...params}
                                    label="Relevant Categories/Topics (up to 3)"
                                    placeholder="Choose or add your own"
                                    error={this.errors.has('categories')}
                                    helperText={this.errors.has('categories') ? this.errors.get('categories') : "Categories help with publication discoverability"}
                                />
                            }
                            // renderTags={(values, props) => values.map((v, index) => <Chip label={v} color="primary" {...props({ index })} />)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={this.create} disabled={this.submitting}>
                            Creat{this.submitting ? 'ing' : 'e'}
                            {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                        </Button>
                        {this.errors.has('api') && <Typography color="error" variant="body2" component="span" className={classes.error}>{this.errors.get('api')}</Typography>}
                        {(this.errors.size > 0 && !this.errors.has('api')) && <Typography color="error" variant="caption" style={{ marginLeft: 8 }}>See errors above</Typography>}
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }

    private onMeResolved = () => {
        const { userStore, router } = this.props;

        console.log(toJS(userStore.me.publications));

        if (userStore.me.publications.length > 0) {
            router.replace('/dashboard');
        }

        // if user already has a name, set it here
        this.form.authorName = userStore.me.name;
    }

    private handleCategoriesChange = (e: React.ChangeEvent<{}>, values: string[], reason: AutocompleteChangeReason) => {
        // [DN] allow up to 3 categories for now, each with 2 characters min
        if (this.form.categories && this.form.categories.length > 2 && (reason === 'create-option' || reason === 'select-option')) {
            this.errors.set('categories', 'Up to 3 categories only' );
        }
        else if (values.length > 0 && values.slice(-1)[0].length < 2) {
            this.errors.set('categories', 'At least 2 characters are required' );
        }
        else {
            this.errors.delete('categories');
            this.form.categories = values;
        }
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        let value = e.target.value;

        if (name === 'slug') {
            value = value.toLowerCase();
        }

        // as user starts typing, we probably want to remove validation error
        this.errors.delete(name);

        (this.form as any)[name] = value;
    }

    private create = async () => {
        if (!this.isValid())
            return;

        // passed validation, let's save this shit
        try {
            this.submitting = true;
            let response = await this.saveMe();
            if (response.status === 200) {
                // save to user store as well
                this.props.userStore.me.name = this.form.authorName;

                response = await this.savePublication();

                if (response.status === 200) {
                    // add to user store
                    this.props.userStore.addPublication(await response.json());

                    this.props.router.push('/dashboard');
                }
                else {
                    const apiError = await response.text();
                    // TODO: this is a hack, let's think of a better way to do it, maybe have API return JSON in case of an error
                    if (apiError.toLowerCase().includes('url')) {
                        this.errors.set('slug', apiError);
                    }
                    else {
                        this.errors.set('api', apiError);
                    }
                }
            }
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }
        finally {
            this.submitting = false;
        }
    }

    // [TODO] create something better!!!!???
    private isValid = () => {
        this.errors.clear();

        if (!this.form.name)
            this.errors.set('name', 'Name is required');
        if (!this.form.description)
            this.errors.set('description', 'Description is required');
        if (!this.form.slug)
            this.errors.set('slug', 'URL is required');
        else if (!validation.isValidSlug(this.form.slug))
            this.errors.set('slug', 'Only letters, numbers and dashes are allowed');
        if (!this.form.authorName)
            this.errors.set('authorName', 'Your name/pseudonym is required');
        if (!this.form.categories || this.form.categories.length === 0)
            this.errors.set('categories', 'At least 1 category is required');

        return this.errors.size === 0;
    }

    private saveMe = () => auth.fetch('/api/me', { method: 'PUT', body: JSON.stringify({ name: this.form.authorName }) });

    private savePublication = () => auth.fetch('/api/publications', {
        method: 'POST',
        body: JSON.stringify(this.form)
    });
}

export default withStyles(styles)(NewPublication);
