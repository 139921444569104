import ReactGA from 'react-ga';
import { History, Location } from 'history';
import { isDev } from './isDev';

export const GA = {
    // make sure to record all GA functions for PROD only
    init: (id: string, history: History) => {
        if (!isDev()) {
            // init
            ReactGA.initialize(id, { titleCase: false });
            // track intial load
            GA.trackPage(history.location);
            // listen to history and track page changes
            history.listen(GA.trackPage);
        }
    },

    trackPage: (location: Location) => {
        if (!isDev()) {
            const page = location.pathname + location.search;
            ReactGA.set({ page }); // Update the user's current page
            ReactGA.pageview(page); // Record a pageview for the given page
        }
    },

    event: (category: string, action: string, label?: string, value?: number) => {
        if (!isDev()) {
            ReactGA.event({ category, action, label, value });
        }
        else {
            console.log(`Category: ${category}; Action: ${action}; Label: ${label}; Value: ${value}`);
        }
    }
};