export const validation = {

    isValidSlug: (text: string) => /^[a-z0-9](-?[a-z0-9])*$/.test(text.toLowerCase()),

    isValidEmail: (email: string) => {
        const regEx = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        return regEx.test(email);
    },
        
    // at least one letter, at least one number, minimum 6 characters
    isValidPassword: (password: string) => /^(?=.*\d)(?=.*[A-Za-z]).{6,}$/.test(password)
};