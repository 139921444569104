export const tawkTo = {
    init: () => {
        (window as any).Tawk_API = (window as any).Tawk_API || {};
        var Tawk_LoadStart = new Date();

        const script = document.createElement("script");
        script.async = true;
        script.src = 'https://embed.tawk.to/60a0078a185beb22b30d8ae0/1f5ohqtg7';
        script.setAttribute('crossorigin', '*');

        const firstScriptTag = document.getElementsByTagName("script")[0];
        if (firstScriptTag && firstScriptTag.parentNode)
            firstScriptTag.parentNode.insertBefore(script, firstScriptTag);

        // their API is terrible, adding a hack here for checking whether chat widget is loaded before calling any of their apis
        (window as any).Tawk_API.loaded = new Promise(resolve => {
            (window as any).Tawk_API.onLoad = () => resolve(true);
        });
    },

    /** name is user name and user id, or just user id if user hasn't set their name yet */
    setUser: async (name: string) => {
        const tawkApi = (window as any).Tawk_API;

        if (await tawkApi.loaded) {
            tawkApi.setAttributes({ name });
        }
    },

    event: async (name: string, data: {}) => {
        const tawkApi = (window as any).Tawk_API;

        if (await tawkApi.loaded) {
            tawkApi.addEvent(name, data);
        }
    }
};