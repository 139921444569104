import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Chip, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { ApiMessage } from '../../models';
import { auth } from '../../services/auth/Auth';
import CheckIcon from '@material-ui/icons/Check';

const styles = (theme: Theme) => createStyles({
    button: {
        textTransform: 'none'
    },
    submitting: {
        marginLeft: theme.spacing(1)
    },
    chip: {
        backgroundColor: theme.palette.success.main
    }
});

interface VerifyEmailProps extends WithStyles<typeof styles> {
    isVerified?: boolean;
}

@observer
class VerifyEmail extends React.Component<VerifyEmailProps> {
    @observable private submitting: boolean = false;
    @observable private message: ApiMessage = { body: '', isError: false };

    public render() {
        const { classes, isVerified } = this.props;

        if (isVerified) {
            return <Chip icon={<CheckIcon />} label="Verified" color="primary" size="small" className={classes.chip} />;
        }

        return !!this.message.body ?
            <Typography variant="caption" color={this.message.isError ? 'error' : 'textPrimary'} style={{ whiteSpace: 'nowrap' }}>{this.message.body}</Typography> :
            <Tooltip title="Verify your email address so we know it's really you and so you can take full advantage of Storylect features, like publishing your work and more" arrow>
                <Button size="small" variant="outlined" color="primary" className={classes.button} onClick={this.verify} disabled={this.submitting}>
                    Verify Email
                    {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                </Button>
            </Tooltip>;
    }

    private verify = async () => {
        this.message = { body: '', isError: false };
        this.submitting = true;

        try {
            var response = await auth.fetch('/api/me/verify', { method: 'POST' });

            if (response.status === 200) {
                this.message = { body: 'Email sent', isError: false };
            }
            else {
                this.message = { body: 'Error, try later', isError: true };
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.message = { body: 'Error, try later', isError: true };
        }
        finally {
            this.submitting = false;
            window.setTimeout(() => this.message = { body: '', isError: false }, 5000)
        }
    }
}

export default withStyles(styles)(VerifyEmail);