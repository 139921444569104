import { Publication, User, Reactions, Metrics } from ".";

export interface Post {
    id: number;
    publicationId: number;
    title?: string;
    subtitle?: string;
    body?: object;
    status: PostStatus;
    slug: string;
    tags?: string[];
    images: {
        banner: string
    };
    createdOn: string; // [DN] should probably make it a moment
    updatedOn: string; // should probably make it a moment
    public: boolean;
    publication: Publication;
    authors: User[];
    reactions: Reactions;
    metrics: Metrics;
}

export enum PostStatus {
    Draft, // initial state
    Published, // live, public
    Unpublished, // same as Draft but was Published before - so maybe don't allow to change url slug?
    Republished // same as Published but was either Published again (maybe with new changes) or Unpublished before - this is so we don't send an email to subscribers more than once
}