import { History } from 'history';
import { UserStore } from './UserStore';
import { RouterStore } from './RouterStore';
import { EditorStore } from './EditorStore';

export class RootStore
{
    public routerStore: RouterStore;
    public userStore: UserStore;
    public editorStore: EditorStore;

    constructor(history: History) {
        this.routerStore = new RouterStore(history);
        this.userStore = new UserStore();
        this.editorStore = new EditorStore();
    }
}