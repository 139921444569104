import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Avatar, Box, Button, CircularProgress, IconButton, Link, Paper, TextField, Typography } from '@material-ui/core';
import { auth } from '../../services/auth/Auth';
import { User } from '../../models';
import { image } from '../../services/image/Image';
import VerifyEmail from './VerifyEmail';
import { Alert, AlertTitle } from '@material-ui/lab';

const styles = (theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2, 3, 3)
    },
    submitting: {
        marginLeft: theme.spacing(1)
    },
    avatar: {
        width: 120,
        height: 120,
        fontSize: '2em'
    },
    error: {
        marginLeft: theme.spacing(2)
    }
});

interface ProfileSettingsProps extends WithStyles<typeof styles> {
    user: User;
    onSave?: (user: User) => void;
    onImageUpload?: (url: string) => void;
}

@observer
class ProfileSettings extends React.Component<ProfileSettingsProps> {
    @observable private editableUser: User = {} as User;
    @observable private errors: Map<string, string> = new Map(); // key is field name, value is error message
    @observable private submitting: boolean = false;
    @observable private previewAvatarUrl: string = '';

    @observable private deactivateFormOpen: boolean = false;
    @observable private deactivating: boolean = false;

    constructor(props: ProfileSettingsProps) {
		super(props);

        this.editableUser = {
            name: props.user.name,
            about: props.user.about
        } as User;
	}

    public render() {
        const { classes, user } = this.props;

        return <Paper className={classes.root}>
            <Typography variant="h6">My profile</Typography>
            <Box>
                <input accept="image/*" id="pic" type="file" style={{ display: 'none' }} onChange={this.handleUpload} />
                <label htmlFor="pic">
                    <IconButton component="span">
                        <Avatar alt={this.editableUser.name} src={this.previewAvatarUrl || user.avatarUrl} className={classes.avatar}>{user.avatarAlt}</Avatar>
                    </IconButton>
                </label>
            </Box>
            <Box display="flex" alignItems="baseline">
                <TextField name="email" label="Email" fullWidth value={user.email} margin="normal" disabled />
                <Box ml={2}>
                    <VerifyEmail isVerified={user.emailVerified} />
                </Box>
            </Box>

            <TextField name="name" label="Name/pseudonym" fullWidth value={this.editableUser.name || ''} onChange={this.handleChange}
                error={this.errors.has('name')} helperText={this.errors.get('name')} margin="normal" />
            <TextField name="about" label="Short bio" fullWidth value={this.editableUser.about || ''} onChange={this.handleChange}
                error={this.errors.has('about')} helperText={this.errors.get('about')} margin="normal" multiline />
            <Box my={2}>
                <Button variant="contained" color="primary" onClick={this.save} disabled={this.submitting}>
                    Sav{this.submitting ? 'ing' : 'e'}
                    {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                </Button>
                {this.errors.has('api') && <Typography color="error" variant="body2" component="span" className={classes.error}>{this.errors.get('api')}</Typography>}
            </Box>
            {this.deactivateFormOpen ? 
                <Alert severity="error">
                    <AlertTitle>Danger Zone</AlertTitle>
                    Deactivating your account will remove all your information from the platform, including your publications and subscriptions. Are you sure you want to deactivate it?
                    {this.errors.has('alert-api') && <Typography color="error" variant="body2" style={{ marginTop: 8 }}>Unable to deactivate: {this.errors.get('alert-api')}. Please contact us</Typography>}
                    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={1.5}>
                        <Button size="small" variant="outlined" onClick={this.toggleDeactivateForm} style={{ marginRight: 8 }}>Cancel</Button>
                        <Button size="small" variant="contained" color="secondary" onClick={this.deactivate} disabled={this.deactivating}>
                            Deactivat{this.deactivating ? 'ing' : 'e'}
                            {this.deactivating && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                        </Button>
                    </Box>
                </Alert> :
                <Typography variant="caption" color="textSecondary">
                    If you want to deactivate your account, please click <Link underline="always" variant="caption" color="textSecondary" component="button" onClick={this.toggleDeactivateForm} style={{ verticalAlign: 'baseline' }}>here</Link>.
                </Typography>
            }
        </Paper>;
    }

    private toggleDeactivateForm = () => {
        this.deactivateFormOpen = !this.deactivateFormOpen;
        this.errors.delete('alert-api');
    }

    private deactivate = async () => {
        this.errors.clear();
        this.deactivating = true;

        try {
            var response = await auth.fetch('/api/me', { method: 'DELETE' });

            if (response.status === 200) {
                auth.logout();
                window.location.reload();
            }
            else {
                this.errors.set('alert-api', await response.text());
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.errors.set('alert-api', 'Error deactivating user. Try again later');
        }
        finally {
            this.deactivating = false;
        }
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        // as user starts typing, we probably want to remove validation error
        this.errors.delete(name);

        (this.editableUser as any)[name] = value;
    }

    private save = async () => {
        if (!this.isValid())
            return;

        // passed validation, let's save this shit
        try {
            this.submitting = true;
            let response = await auth.fetch('/api/me', { method: 'PUT', body: JSON.stringify(this.editableUser) });
            if (response.status === 200) {
                const updatedUser = await response.json() as User;

                if (this.props.onSave)
                    this.props.onSave(updatedUser);
            }
        }
        catch(error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
        }
        finally {
            this.submitting = false;
        }
    }

    // [TODO] create something better!!!!???
    private isValid = () => {
        this.errors.clear();

        if (!this.editableUser.name)
            this.errors.set('name', 'Name is required');

        return this.errors.size === 0;
    }

    private handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0];

        if (!file || !file.type.startsWith('image/')) {
            console.log('Invalid file type'); // do we need to tell user they are uploading invalid image???
            return;
        }

        // get temp avatar URL to show it to the user while the actual image is uploading
        this.previewAvatarUrl = image.getPreviewUrl(file);
        // console.log(this.previewAvatarUrl);

        try {
            const formData = new FormData();
            // [DN] when uploading files, the name always needs to be 'file' in order for API to handle it properly
            formData.append('file', file);

            const response = await auth.fetchWithFile('/api/me/upload', {
                method: 'POST',
                body: formData
            });

            if (response.status === 200) {
                const updatedUser = await response.json() as User;

                if (this.props.onImageUpload)
                    this.props.onImageUpload(updatedUser.avatarUrl);
            }
            // [TODO] show user some kind of message (Success vs Error)
        }
        catch (error) {
            // handle error!
            console.log(error);
        }
        finally {
            image.releasePreviewUrl(this.previewAvatarUrl);
            this.previewAvatarUrl = '';
        }
    }
}

export default withStyles(styles)(ProfileSettings);