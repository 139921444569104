import { Publication, Post, NotificationType, Subscription } from ".";

export interface User {
    id: number;
    email: string;
    hasPassword?: boolean;
    emailVerified?: boolean;
    stripeStatus: StripeStatus;
    name: string;
    about: string;
    avatarUrl: string;
    avatarAlt: string;
    publications: Publication[];
    subscriptions: Subscription[];
    posts: Post[];
    unsubscribes: NotificationType[];
    types: UserType[];
    cardLast4: string;
}

export enum UserType {
    Writer = 1,
    Reader
}

export enum StripeStatus {
    New, // stripe onboarding haven't started yet
    Initiated, // stripe onboarding initiated, but have not completed
    Ready // all done, ready to accept payments
}