import { Theme, createStyles } from "@material-ui/core";

export const postStyles = (theme: Theme) => createStyles({
    post: {
        '& img': {
            maxWidth: '100%'
        },
        '& a:link': {
            color: theme.palette.primary.main,
            fontWeight: 500,
            textDecoration: 'none'
        },
        '& a:visited': {
            color: '#63ADD8',
            fontWeight: 500,
            textDecoration: 'none'
        },
        '& a:hover': {
            color: '#63ADD8',
            fontWeight: 500,
            textDecoration: 'none'
        },
        '& blockquote': {
            borderLeft: `2px solid ${theme.palette.grey[400]}`,
            marginLeft: theme.spacing(3),
            paddingLeft: theme.spacing(2),
            color: theme.palette.text.secondary,
            fontSize: theme.typography.body2.fontSize,
            '& p': {
                marginBottom: 0
            }
        },
        '& code': {
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: 'rgba(0, 0, 0, .03)',
            padding: theme.spacing(0.12, 0.5),
        },
        '& pre': {
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: 'rgba(0, 0, 0, .03)',
            padding: theme.spacing(1, 2),
            '& code': {
                border: 'none',
                backgroundColor: 'transparent',
                padding: 0
            }
        },
        '& table': {
            borderCollapse: 'collapse',
            '& th': {
                backgroundColor: 'rgba(0, 0, 0, .03)'
            },
            '& th, td': {
                border: `1px solid ${theme.palette.grey[400]}`,
                padding: theme.spacing(1, 2),
                '& *': {
                    margin: 0
                }
            }
        },
        '& .sl--align-left': {
            textAlign: 'left',
        },
        '& .sl--align-right': {
            textAlign: 'right',
        },
        '& .sl--align-center': {
            textAlign: 'center',
        },
        '& .sl--align-justify': {
            textAlign: 'justify',
        }
    }
});
