import * as React from 'react';
import { Container, Box, Typography, Button, Grid, Avatar, Chip, IconButton, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
import { RouterStore } from '../../stores/RouterStore';
import { Post, Metrics, Publication, Subscriber } from '../../models';
import { when, toJS, observable } from 'mobx';
import { PageLoader } from '../../shared/PageLoader';
import DashboardTableItem from './DashboardTableItem';
import { UserStore } from '../../stores/UserStore';
import { auth } from '../../services/auth/Auth';
import _ from 'lodash';
import { constants } from '../../helpers/constants';
import { image } from '../../services/image/Image';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';

const styles = (theme: Theme) => createStyles({
	root: {
		paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
	},
	avatar: {
		width: 120,
		height: 120
	},
	marginRight: {
		marginRight: theme.spacing(1)
	},
	category: {
		margin: theme.spacing(0.5, 1, 0.5, 0)
	},
	info: {
		color: theme.palette.info.main
	}
});

interface DashboardProps extends WithStyles<typeof styles> {
	router: RouterStore;
	userStore: UserStore;
}

@inject((stores: RootStore) => ({
	router: stores.routerStore,
	userStore: stores.userStore
}))
@observer
class Dashboard extends React.Component<DashboardProps> {
	@observable private form: Publication = {} as Publication;
	@observable private subscribers: Subscriber[] = [];
	@observable private postsWithMetrics: Post[] = [];
	@observable private previewAvatarUrl: string = '';
	@observable private editables: Set<string> = new Set();

	constructor(props: DashboardProps) {
		super(props);

		//once authenticated user resolves, check if we need to redirect
		when(() => props.userStore.meResolved, this.onMeResolved);
	}

	public render() {
		const { userStore } = this.props;

		if (!userStore.meResolved)
			return <PageLoader />;

		const { classes } = this.props;

		const publication = userStore.me.publications[0];
		if (!publication)
			return null;

		return <Container className={classes.root} maxWidth="xl">
			<Box display="flex" alignItems="center" mb={5} flexDirection={{ xs: 'column', md: 'row' }}>
				<Box mb={{ xs: 2, md: 0 }} mr={{ md: 3 }}>
					<input accept="image/*" id="pic" type="file" style={{ display: 'none' }} onChange={this.handleUpload} />
					<label htmlFor="pic">
						<IconButton component="span">
							<Avatar alt={publication.name} src={this.getAvatarUrl()} className={classes.avatar} />
						</IconButton>
					</label>
					{this.subscribers.filter(s => s.subscriberPlan).length ? 
						<Tooltip title={<>{`${this.subscribers.filter(s => s.subscriberPlan).length} paying subscribers`}</>} arrow>
							<Typography align="center" variant="subtitle2" className={classes.info}>{this.subscribers.length || 0} subscribers</Typography>
						</Tooltip> :
						<Typography align="center" variant="subtitle2" className={classes.info}>{this.subscribers.length || 0} subscribers</Typography>
					}
				</Box>
				<Box flexGrow="1" width="100%" mb={{ xs: 2, md: 0 }}>
					<Box mb={1}>
						{this.editables.has('name') ?
							<TextField name="name" fullWidth value={this.form.name} onChange={this.handleChange}
								InputProps={{
									endAdornment: <InputAdornment position="end">
										<IconButton color="primary" size="small" onClick={this.editOk('name')}><CheckCircleIcon /></IconButton>
										<IconButton size="small" onClick={this.editCancel('name')}><CancelIcon /></IconButton>
									</InputAdornment>
								}}
							/> :
							<Box display="flex">
								<Typography variant="h5" component="h1" className={classes.marginRight}>{this.form.name}</Typography>
								<IconButton color="primary" size="small" onClick={this.edit('name')}><EditIcon /></IconButton>
							</Box>
						}
					</Box>
					<Box mb={1}>
						{this.editables.has('description') ?
							<TextField name="description" fullWidth value={this.form.description} onChange={this.handleChange}
								InputProps={{
									endAdornment: <InputAdornment position="end">
										<IconButton color="primary" size="small" onClick={this.editOk('description')}><CheckCircleIcon /></IconButton>
										<IconButton size="small" onClick={this.editCancel('description')}><CancelIcon /></IconButton>
									</InputAdornment>
								}}
							/> :
							<Box display="flex">
								<Typography variant="subtitle1" className={classes.marginRight}>{this.form.description}</Typography>
								<IconButton color="primary" size="small" onClick={this.edit('description')}><EditIcon /></IconButton>
							</Box>
						}
					</Box>
					<Box>
						{this.editables.has('categories') ?
							<Autocomplete multiple freeSolo disableClearable value={this.form.categories} onChange={this.handleCategoriesChange}
								options={constants.categories}
								renderInput={params => <TextField {...params}
									InputProps={{
										...params.InputProps,
										endAdornment: <>
											<InputAdornment position="end">
												<IconButton color="primary" size="small" onClick={this.editOk('categories')}><CheckCircleIcon /></IconButton>
												<IconButton size="small" onClick={this.editCancel('categories')}><CancelIcon /></IconButton>
											</InputAdornment>
										</>
									}}
									placeholder="Choose or type your own (up to 3)"
								/>}
							/> :
							<Box display="flex" alignItems="center" flexWrap="wrap">
								{this.form.categories.map(c => <Chip key={c} label={c} size="small" variant="outlined" className={classes.category} />)}
								<IconButton color="primary" size="small" onClick={this.edit('categories')}><EditIcon /></IconButton>
							</Box>
						}
					</Box>
                </Box>
				{userStore.me.posts.length > 0 && 
					<Box ml={{ md: 3 }}>
						<Button variant="contained" color="primary" onClick={this.navigateToNewPost}>New Post</Button>
					</Box>
				}
			</Box>

			{this.renderContent()}
		</Container>;
	}

	public async componentDidMount() {
		this.loadMetrics();
	}

	private edit = (fieldName: string) => () => this.editables.add(fieldName);
	private editOk = (fieldName: string) => () => {
		this.editables.delete(fieldName);
		this.updatePublication();
	}
	private editCancel = (fieldName: string) => () => {
		this.editables.delete(fieldName);
		(this.form as any)[fieldName] = (this.props.userStore.me.publications[0] as any)[fieldName];
	}

	private updatePublication = async () => {
		// [TODO] add validation?
		try {
			let response = await auth.fetch(`/api/publications/${this.props.userStore.me.publications[0].id}`, { method: 'PUT', body: JSON.stringify(this.form) });
			if (response.status === 200) {
				this.props.userStore.me.publications[0] = Object.assign(this.props.userStore.me.publications[0], this.form);
			}
			else {
				this.initiateForm();
			}
		}
		catch (error) {
			// [DN] [TODO] handle error here
			// logger.exception(error);
			console.log(error);
			this.initiateForm();
		}
	}

	private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name;
		const value = e.target.value;

		// as user starts typing, we probably want to remove validation error
		//this.errors.delete(name);

		(this.form as any)[name] = value;
	}

	private handleCategoriesChange = (e: React.ChangeEvent<{}>, values: string[], reason: AutocompleteChangeReason) => {
        // [DN] allow up to 3 categories for now, each with 2 characters min
        if (this.form.categories && this.form.categories.length > 2 && (reason === 'create-option' || reason === 'select-option')) {
			//this.errors.set('categories', 'Up to 3 categories only' );
			return;
        }
        else if (values.length > 0 && values.slice(-1)[0].length < 2) {
			//this.errors.set('categories', 'At least 2 characters are required' );
			return;
        }
        else {
            //this.errors.delete('categories');
            this.form.categories = values;
        }
    }

	private getAvatarUrl = () => this.previewAvatarUrl || this.props.userStore.me.publications[0].images?.avatar || constants.defaultPublicationAvatarUrl;

	private handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { userStore } = this.props;

		const file = e.target.files![0];

		if (!file || !file.type.startsWith('image/')) {
			console.log('Invalid file type'); // do we need to tell user they are uploading invalid image???
			return;
		}

		// get temp avatar URL to show it to the user while the actual image is uploading
		this.previewAvatarUrl = image.getPreviewUrl(file);
		// console.log(this.previewAvatarUrl);

		try {
			const formData = new FormData();
			// [DN] when uploading files, the name always needs to be 'file' in order for API to handle it properly
			formData.append('file', file);

			const response = await auth.fetchWithFile(`/api/publications/${userStore.me.publications[0].id}/upload`, {
				method: 'POST',
				body: formData
			});

			if (response.status === 200) {
				userStore.me.publications[0].images = (await response.json() as Publication).images;
			}
			// [TODO] show user some kind of message (Success vs Error)
		}
		catch (error) {
			// handle error!
			console.log(error);
		}
		finally {
			image.releasePreviewUrl(this.previewAvatarUrl);
			this.previewAvatarUrl = '';
		}
	}

	private renderContent = () => {
		const { userStore } = this.props;

		return userStore.me.posts.length > 0 ?
			<Grid container spacing={4}>
				{_.orderBy(userStore.me.posts, p => p.updatedOn, 'desc').map(post => {
					const metrics = this.postsWithMetrics.find(p => p.id === post.id)?.metrics || {} as Metrics;
					return <DashboardTableItem publication={userStore.me.publications[0]} post={post} metrics={metrics} onDelete={this.handleRemoveOfItem} key={post.id} />
				})}
			</Grid> :
			this.renderEmpty();
	}

	private handleRemoveOfItem = (post: Post) => () => {
		console.log('Remove');
	}

	private onMeResolved = () => {
		const { userStore, router } = this.props;

		console.log(toJS(userStore.me.publications));

		// redirect to new publication page if user doesn't have one yet
		if (userStore.me.publications.length === 0) {
			router.replace('/new-publication');
		}
		else {
			this.initiateForm();

			// get all publication subscribers (doesn't have to be awaitable I guess)
			this.getSubscribers();
		}
	}

	private initiateForm = () => this.form = {
		name: this.props.userStore.me.publications[0].name,
		description: this.props.userStore.me.publications[0].description,
		categories: this.props.userStore.me.publications[0].categories
	} as Publication;

	private renderEmpty = () => {
		return <Box textAlign="center">
			<Typography variant="h4" paragraph>Congratulations!!!</Typography>
			<Typography variant="h6" paragraph>You've created your first publication</Typography>
			<Typography variant="subtitle1" paragraph>Now it’s time to publish your first post</Typography>
			<Button variant="contained" color="secondary" onClick={this.navigateToNewPost}>Create a new post</Button>
		</Box>;
	}

	private navigateToNewPost = () => this.props.router.push(`/new-post`);

	// dashboard uses userStore.me, we want to get metrics separately because they may change while me.posts are cached in store
	private loadMetrics = async () => {
		try {
			const response = await auth.fetch('/api/posts/metrics');
			// [DN] TODO? maybe clone userStore.me.posts and update them with metrics?
			this.postsWithMetrics = await response.json();
		}
		catch (error) {
			// handle error!
			console.log(error);
		}
	}

	private getSubscribers = async () => {
		const { userStore } = this.props;

        try {
            let response = await auth.fetch(`/api/publications/${userStore.me.publications[0].id}/subscribers`);
            if (response.status === 200) {
                this.subscribers = await response.json() as Subscriber[];
            }
        }
        catch (error) {
            // handle error!
            console.log(error);
        }
    }
}

export default withStyles(styles)(Dashboard);
