import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { observer, inject } from 'mobx-react';
import * as React from 'react';
import { observable } from 'mobx';
import { CircularProgress, Container, Link, TextField } from '@material-ui/core';
import { RootStore } from '../../stores/RootStore';
import { RouterStore } from '../../stores/RouterStore';
import { auth } from '../../services/auth/Auth';
import { UserStore } from '../../stores/UserStore';
import { validation } from '../../helpers/validation';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    paper: {
        marginTop: '7vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 3, 3),
        '& p': {
            width: '100%'
        }
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.error.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    submitting: {
        marginLeft: theme.spacing(1)
    }
});

interface RegisterProps extends WithStyles<typeof styles> {
    router: RouterStore;
    userStore: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class Register extends React.Component<RegisterProps> {
    @observable private email: string = '';
    @observable private error: string = '';
    @observable private submitting: boolean = false;

    public render() {
        const { classes } = this.props;

        return <Container className={classes.root} maxWidth="sm">
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}><ExitToAppIcon /></Avatar>
                <Typography variant="h6" gutterBottom>Let's Get Started</Typography>
                <Typography gutterBottom><strong>Writers</strong>, publish your articles for free.</Typography>
                <Typography gutterBottom><strong>Readers</strong>, subscribe to the content you're interested in.</Typography>
                <form className={classes.form} onSubmit={this.register}>
                    <TextField error={!!this.error} variant="outlined" label="Email" fullWidth value={this.email} helperText={this.error} onChange={this.handleChange} />
                    <Typography variant="caption">By registering, you agree to our <Link href="/privacy" target="_blank">Privacy Policy</Link> and <Link href="/terms" target="_blank">Terms</Link></Typography>
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={this.submitting}>
                        Register{this.submitting ? 'ing' : ''}
                        {this.submitting && <CircularProgress size={18} thickness={7} className={classes.submitting} />}
                    </Button>
                </form>
            </Paper>
        </Container>;
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.email = e.target.value;

        if (this.email.trim()) {
            this.error = '';
        }
    }

    private register = async (e: React.FormEvent<HTMLFormElement>) => {
        const { router, userStore } = this.props;

        e.preventDefault();

        this.email = this.email.trim();
        if (!validation.isValidEmail(this.email)) {
            this.error = 'Email is invalid';
            return;
        }

        this.submitting = true;
        
        try {
            const response = await auth.fetch('/api/users', {
                method: 'POST',
                body: JSON.stringify({ email: this.email })
            });
    
            // use auth.isSuccessfulStatus instead?
            if (response.status === 200) {
                const { token } = await response.json();
    
                // authenticating and saving token
                await userStore.authenticate(token);
    
                //redirect to where the user came from; if direct hit, redirect home
                const redirect = (router.location.state as any)?.from.pathname || '/';
                router.replace(redirect);
            }
            else {
                this.error = await response.text();
            }
        }
        catch (error) {
            // [DN] [TODO] handle error here
            // logger.exception(error);
            console.log(error);
            this.error = 'Error registering. Try again later';
        }
        finally {
            this.submitting = false;
        }
    }
}

export default withStyles(styles)(Register);
