import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Typography, Grid, Container, Box, Button, withWidth, WithWidth, isWidthUp } from '@material-ui/core';
import { RouterStore } from '../../stores/RouterStore';
import { RootStore } from '../../stores/RootStore';
import { constants } from '../../helpers/constants';
import Navigation from '../../Navigation';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import NavigationRoundedIcon from '@material-ui/icons/NavigationRounded';
import { Link as RouterLink } from 'react-router-dom';
import { UserStore } from '../../stores/UserStore';

// [DN] some of these styles are no longer relevant after home page redesign by greatest designer of all time Yuriy
// still, want to keep them for now in case we need them later so we don't reinvent the wheel
const styles = (theme: Theme) => createStyles({
    root: {

    },
    title: {
        margin: theme.spacing(7, 0),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(3, 0)
        }
    },
    points: {
        margin: theme.spacing(7, 0),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(3, 0)
        }
    },
    signup: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4)
        }
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        marginTop: theme.spacing(1)
    },
    emailus: {
        marginTop: theme.spacing(1)
    },

    hero: {
        height: '100vh',
        backgroundImage: `url(${constants.imagePath}/hero-1920x1280.jpg)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        // color: theme.palette.common.white,
    },
    paperText: {
        fontFamily: '"Special Elite", cursive',
        width: '80%',
        textAlign: 'center',
        transform: 'rotate(-22deg)',
        paddingTop: '15vh'
    },
    paperText1: {
        fontFamily: 'inherit',
        fontSize: '3vh',
        //fontWeight: 600
    },
    paperText2: {
        fontFamily: 'inherit',
        fontSize: '2vh'
    },
    paperText3: {
        fontFamily: 'inherit',
        fontWeight: 600,
        fontSize: '2.5vh'
    },
    paperText4: {
        fontFamily: 'inherit',
        fontSize: '1.5vh'
    },
    img: {
        objectFit: 'none',
        objectPosition: 'right bottom',
        width: 520,
        height: 270,
        [theme.breakpoints.down('xs')]: {
            objectPosition: '80px bottom',
        }
    }
});

interface HomeProps extends WithStyles<typeof styles, true>, WithWidth {
    router: RouterStore;
    userStore: UserStore;
}

@inject((stores: RootStore) => ({
    router: stores.routerStore,
    userStore: stores.userStore
}))
@observer
class Home extends React.Component<HomeProps> {
    public render() {
        const { classes, theme, userStore, width } = this.props;
        //console.log(theme);

        return <Container maxWidth={false} disableGutters className={classes.root}>
            <Box className={classes.hero}>
                <Navigation transparent />
                <Box className={classes.paperText} color="text.hint">
                    <Typography className={classes.paperText1} gutterBottom>GREAT WRITING STARTS HERE</Typography>
                    <Typography className={classes.paperText2} paragraph>Free publishing platform for all sorts of writing and journalism</Typography>
                    <Typography className={classes.paperText3}>{moment().year()}</Typography>
                </Box>
                <Box color="white" textAlign="center" position="absolute" top="50%" right={{ xs: '5%', lg: '20%' }} left={{ xs: '5%', lg: '20%' }} style={{ transform: 'translateY(-30%)' }}>
                    <Box px={1} py={{ xs: 4, md: 7 }} bgcolor="rgba(0,0,0,.85)" border={4} borderColor="white" borderRadius={10} boxShadow={3}>
                        <Typography variant={isWidthUp('md', width) ? 'h3' : 'h4'} paragraph style={{ fontWeight: 600 }}>PLATFORM FOR WRITERS</Typography>
                        <Typography variant={isWidthUp('md', width) ? 'h4' : 'h5'} paragraph>Publish. Promote. Monetize.</Typography>
                        <Typography variant={isWidthUp('md', width) ? 'subtitle1' : 'subtitle2'}>Free to publish. SEO friendly. Monetize via subscription plans.</Typography>
                    </Box>
                    <Box mt={{ xs: 4, md: 8 }}>
                        <Typography variant="h5" style={{ fontWeight: 500 }} paragraph>Create your publication now</Typography>
                        <Button component={RouterLink} to={userStore.isAuthenticated ? '/new-publication' : { pathname: '/join', state: { from: { pathname: '/new-publication' }}}} variant="contained" color="secondary" size="large">Start Writing</Button>
                    </Box>
                </Box>
            </Box>
            <Container maxWidth="xl">
                <Box mt={7}>
                    <Grid container spacing={6} style={{ margin: 0, width: '100%' }}>
                        <Grid item xs={12}>
                            <Typography align="center" variant={isWidthUp('md', width) ? 'h3' : 'h4'} style={{ fontWeight: 600 }}>WHY STORYLECT</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box textAlign="center" mb={2}>
                                <img alt="free to publish" src={`${constants.imagePath}/free-to-publish.svg`} height={80} width={125} />
                            </Box>
                            <Typography align="center" variant="h5" gutterBottom>FREE TO PUBLISH</Typography>
                            <Box px={2}>
                                <Typography align="center"> The platform is open to everyone. Create your publication and start writing in our easy-to-use publishing tool. You are in charge of what to write about.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box textAlign="center" mb={2}>
                                <img alt="easy to use" src={`${constants.imagePath}/easy-to-use.svg`} height={80} width={131} />
                            </Box>
                            <Typography align="center" variant="h5" gutterBottom>EASY TO USE</Typography>
                            <Box px={2}>
                                <Typography align="center">We make it easy to create, publish and share your content. When you have the content it just takes few minutes to set up subscription plans for it.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box textAlign="center" mb={2}>
                                <img alt="direct subscriptions" src={`${constants.imagePath}/direct-subscription.svg`} height={80} width={223} />
                            </Box>
                            <Typography align="center" variant="h5" gutterBottom>SUBSCRIPTION PLANS</Typography>
                            <Box px={2}>
                                <Typography align="center">Add subscription plans to your publication whenever you're ready to monetize your work. Your readers subscribe and pay you directly.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Box mt={10} py={6} bgcolor="#2d9cdb" color="white">
                <Container maxWidth="xl">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography align="center" variant={isWidthUp('md', width) ? 'h3' : 'h4'} style={{ fontWeight: 600 }} gutterBottom>HOW IT WORKS</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box borderRadius="borderRadius" height="100%" display="flex" flexDirection="column" boxShadow={5}>
                                <Box py={4} px={1} flexGrow="1">
                                    <Typography align="center" variant="h5">1. PUBLISH YOUR WRITING</Typography>
                                    <Typography align="center">It's easy and free with our simple writing tool</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-evenly" alignItems="center" py={3} px={2} style={{ borderBottomLeftRadius: theme.shape.borderRadius,  borderBottomRightRadius: theme.shape.borderRadius}}>
                                    <img alt="author" src={`${constants.imagePath}/author_icon.svg`} height={80} width={80} />
                                    <EditIcon fontSize="large" color="secondary" stroke="white" />
                                    <img alt="article" src={`${constants.imagePath}/blog_post.svg`} height={80} width={80} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box borderRadius="borderRadius" height="100%" display="flex" flexDirection="column" boxShadow={5}>
                                <Box py={4} px={1} flexGrow="1">
                                    <Typography align="center" variant="h5">2. BUILD YOUR AUDIENCE</Typography>
                                    <Typography align="center">Share and promote via social media and email</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-evenly" alignItems="center" py={3} px={2} style={{ borderBottomLeftRadius: theme.shape.borderRadius,  borderBottomRightRadius: theme.shape.borderRadius}}>
                                    <img alt="article" src={`${constants.imagePath}/blog_post.svg`} height={80} width={80} />
                                    <ShareIcon fontSize="large" color="secondary" stroke="white" />
                                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" width={80}>
                                        <img alt="share" src={`${constants.imagePath}/share_social.svg`} height={80} width={80} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box borderRadius="borderRadius" height="100%" display="flex" flexDirection="column" boxShadow={5}>
                                <Box py={4} px={1} flexGrow="1">
                                    <Typography align="center" variant="h5">3. CHARGE SUBSCRIPTION</Typography>
                                    <Typography align="center">Have your audience support your work</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-evenly" alignItems="center" py={3} px={2} style={{ borderBottomLeftRadius: theme.shape.borderRadius,  borderBottomRightRadius: theme.shape.borderRadius}}>
                                    <img alt="users" src={`${constants.imagePath}/users.svg`} height={80} width={80} />
                                    <FavoriteIcon fontSize="large" color="secondary" stroke="white" />
                                    <img alt="article" src={`${constants.imagePath}/blog_post.svg`} height={80} width={80} />
                                </Box>
                            </Box>
                        </Grid>
                        <Box display={{ xs: 'none', md: 'flex' }} width="70%" mx="auto" pb={4}>
                            <Box height={43} width={20} borderLeft={6} borderBottom={6} color="white" style={{ borderBottomLeftRadius: 10 }} position="relative">
                                <Box position="absolute" left={-23} top={-10}>
                                    <NavigationRoundedIcon  style={{ fontSize: 40 }}  />
                                </Box>
                            </Box>
                            <Box width="100%" flexGrow="1" borderBottom={6} color="white">
                                <Box position="absolute" left="50%" bgcolor="#2d9cdb" style={{ transform: 'translateX(-50%)' }}>
                                    <MonetizationOnOutlinedIcon style={{ color: theme.palette.success.light, fontSize: 80 }} />
                                </Box>
                            </Box>
                            <Box height={43} width={20} borderRight={6} borderBottom={6} color="white" style={{ borderBottomRightRadius: 10 }}></Box>
                        </Box>
                        <Box display={{ xs: 'none', md: 'block' }} flexBasis="100%" p={1.5}>
                            <Typography align="center" variant="h5" gutterBottom>4. GET PAID DIRECTLY</Typography>
                        </Box>
                        <Grid item xs={12}>
                            <Box borderRadius="borderRadius" textAlign="center" flexGrow="1" width="100%" py={3} px={2} mt={2} boxShadow={5}>
                                <Typography variant="h5" style={{ fontWeight: 500 }} paragraph>OPEN NEW INCOME AVENUE WITH STORYLECT</Typography>
                                <Button component={RouterLink} to={userStore.isAuthenticated ? '/new-publication' : { pathname: '/join', state: { from: { pathname: '/new-publication' }}}} variant="contained" color="secondary" size="large">Get Started</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Container>;
    }
}

export default withWidth()(withStyles(styles, { withTheme: true })(Home));
