import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Container, Link } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    }
});

interface AboutUsProps extends WithStyles<typeof styles> {
}

class AboutUs extends React.Component<AboutUsProps> {
    public render() {

        const { classes } = this.props;

        return <Container className={classes.root}>
            <Typography variant="h5" gutterBottom>
            Hi there, aspiring writers!
            </Typography>

            <Typography variant="body1" paragraph>
                We started Storylect with a simple purpose in mind - allow writers, journalists and bloggers to make living by independent writing.
            </Typography>
            <Typography variant="body1" paragraph>
                We want to enable more literary content creators, bloggers and journalists to monetize their work in a better way. We believe that truly valuable content should not be monetized via ads, backlinks, or affiliate programs. We believe that great content should have loyal readers who are willing to support authors they read and follow. We believe this is the direction independent professional publishing is going towards. Bring true value to readers and readers will support your passion.
            </Typography>
            <Typography variant="body1" paragraph>
                With this in mind we started building a publishing and subscription platform for content distribution and monetization through a direct subscription model. This model allows content creators to connect with their audience directly and monetize their work by charging subscription fees for exclusive access to their content.
            </Typography>
            <Typography variant="body1" paragraph>
                And we continue our Storylect journey as we connect with and listen to writers, and help them with their vision of subscription-based writing.
            </Typography>
            <Typography variant="body1" paragraph>
                Join the community and start writing independently!
            </Typography>

            <Typography variant="h5" gutterBottom>
                Contact
            </Typography>
            <Typography variant="subtitle1" paragraph>
            Say "hi" via Chat or email <Link href="mailto:hello@storylect.com" target="_blank">hello@storylect.com</Link>, we are happy to connect.
            </Typography>
        </Container>;
    }
}

export default withStyles(styles)(AboutUs);
